import * as React from 'react';
import {PureComponent} from 'react';
import Modal from "../../_commonComponents/ModalForm";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import renderSelect from "../../_commonComponents/form/Select";
import {required} from "../../../utils/formValidation";
import {PHONE_MASK} from "../../../config/constants";
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import {getObjectData} from "../../../utils/getObjectData";

class AddNewUserForm extends PureComponent {
    render() {
        const {
            toggle,
            isOpen,
            form,
            onSubmit,
            data
        } = this.props;

        return (
            <Modal
                toggle={toggle}
                isOpen={isOpen}
                title={'Новый пользователь'}
                form={form}
                header
                color={"primary"}
                onSubmit={onSubmit}
            >
                <Field
                    component={renderInput}
                    name={'name'}
                    type="text"
                    label={'Имя пользователя из ИГС'}
                />
                <Field
                    component={renderInput}
                    name={'title'}
                    type="text"
                    label={'ФИО'}
                    validate={[required]}
                />
                <Field
                    component={renderInput}
                    name={'phone'}
                    type="text"
                    label={'Номер телефона'}
                    mask={PHONE_MASK}
                    validate={[required]}
                />
                <Field
                    component={renderSelect}
                    name={'center'}
                    options={data}
                    type="text"
                    label={'Центр'}
                    validate={[required]}
                />
            </Modal>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.cls.data.data.map(item => ({value: item.name, label: item.name + ' ' + item.title})))
});

export default connect(mapStateToProps)(AddNewUserForm);
