import {PURPLE, BLUE} from "../../actions/settings/appSettingsAction";

const initialState = {
    title: '',
    theme: 'blue',
    pageTitle: '',
    favicon: null,
    formTitle: ''
};

export function appSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case BLUE:
            return {
                ...state,
                title: '407-001',
                theme: 'blue',
                pageTitle: 'ЭДП 407-001',
                favicon: '/40701/favicon.ico',
                formTitle: 'Симптомы ОРВИ',
                symptFormTitle: 'Тяжесть симптомов ОРВИ'
            };
        case PURPLE:
            return {
                ...state,
                title: '407-002',
                theme: 'purple',
                pageTitle: 'ЭДП 407-002',
                favicon: '/40702/favicon_purple.ico',
                formTitle: 'Симптомы гриппа',
                symptFormTitle: 'Тяжесть симптомов гриппа'
            };
        default:
            return state
    }
}
