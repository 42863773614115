export const BLUE = 'BLUE'; //настройки темы
export const PURPLE = 'PURPLE'; //настройки темы
export const SUB002 = 'SUB002'; //настройки дневника пациента ММХ-407-002

export function isBlueTheme() {
    return {
        type: BLUE
    }
}

export function isPurpleTheme() {
    return {
        type: PURPLE
    }
}
