import * as React from 'react';
import {PureComponent} from 'react';
import propTypes from 'prop-types';
import {Card, CardBody} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//components
import SubmitForm from './components/SubmitForm';
import ChatCard from './components/ChatCard';
import IconButton from "../buttons/IconButton";

/**
 * Chat общий компонент чата поддержки пациентов
 */
class Chat extends PureComponent {
    static propTypes = {
        data: propTypes.array,
        history: propTypes.object.isRequired,
        onSubmit: propTypes.func.isRequired,
        toggleVisibility: propTypes.func,
        isBackIconVisible: propTypes.bool,
    };

    static defaultProps = {
        isBackIconVisible: false
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    /**
     * scrollToBottom
     */
    scrollToBottom() {
        this.el.scrollTop = this.el.scrollHeight;
    }

    render() {
        const {
            history,
            data,
            onSubmit,
            toggleVisibility,
            isBackIconVisible
        } = this.props;

        return (
            <Card className={"h-100"}>
                <CardBody className={"chat__wrapper"}>
                    <div className="chat__header">
                        <h2 className={"chat__title"}>ПОДДЕРЖКА ПОЛЬЗОВАТЕЛЕЙ</h2>
                        <div className={"d-flex align-items-center"}>
                            {isBackIconVisible && <IconButton
                                className="chat_icon chat__icon_back mr-3"
                                icon={"chevron-left"}
                                color={"secondary"}
                                onClick={toggleVisibility}
                            />}
                            <div className={"chat__icon"} onClick={() => history.push('/')}>
                                <FontAwesomeIcon icon={"home"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"chat__scroll"} ref={el => {
                        this.el = el
                    }}>
                        <div className={"chat__container"}>
                            {
                                data && data.map(item =>
                                    <ChatCard
                                        key={item.id}
                                        status={item.direction}>
                                        {item.msg}
                                    </ChatCard>
                                )
                            }
                        </div>
                    </div>
                    <div className={"chat__footer"}>
                        <SubmitForm
                            onSubmit={onSubmit}
                        />
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Chat;
