import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {SUBJECT_ANTIPYRETIC_OBJECT, GET} from '../../../config/constants';
import {getObjectData} from "../../../utils/getObjectData";
import ShowNotification from "../../../utils/showNotification";

export const SUBJECT_ANTIPYRETIC_L = 'SUBJECT_ANTIPYRETIC_L'; //loading
export const SUBJECT_ANTIPYRETIC_F = 'SUBJECT_ANTIPYRETIC_F'; //error
export const SUBJECT_ANTIPYRETIC_S = 'SUBJECT_ANTIPYRETIC_S'; //success
export const SUBJECT_ANTIPYRETIC_E = 'SUBJECT_ANTIPYRETIC_E'; //edited

function dataIsLoading() {
    return {
        type: SUBJECT_ANTIPYRETIC_L
    }
}

function dataIsFailed() {
    return {
        type: SUBJECT_ANTIPYRETIC_F
    }
}

function dataAntipyreticIsSuccess(data) {
    return {
        type: SUBJECT_ANTIPYRETIC_S,
        payload: data
    }
}

function dataAntipyreticIsEdited(data) {
    return {
        type: SUBJECT_ANTIPYRETIC_E,
        payload: data
    }
}

export function fetchAntipyreticData(action = GET, data, filter = [], isInitial = true, object = SUBJECT_ANTIPYRETIC_OBJECT) {
    const notification = Object.create(ShowNotification);
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(action, object, data, filter, initialKey);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                notification.show(`${data.msg}`);
                return;
            }
            isInitial
                ? dispatch(dataAntipyreticIsSuccess(data))
                : dispatch(dataAntipyreticIsEdited(getObjectData(() => data.data)))
        }).catch(e => console.error(e))
    }
}
