import packageJson from '../../package.json';

export const HOMEPAGE = packageJson.homepage === '/40701'
    ? 'https://407001.scithis.com/40701/'
    :'https://407002.scithis.com/40702/';

export const MSG_ERR = 'Указаны некорректные данные для входа';

export const PHONE_MASK = '+7(999) 999-99-99';
export const DATE_MASK = '99.99.9999';
export const TIME_MASK = '99:99';
export const TEMP_MASK = '99.9';

export const LOGIN = '/login';
export const ADMIN = 'ADMIN';


export const GET = 'get';
export const ADD = 'add';
export const SET = 'set';
export const DEL = 'del';

export const LIMIT = 10;
export const MOBILE = 992;
export const INTERVAL = 60000;

export const SCROLL_LIMIT = 300;

//Объекты
//Profile
export const LOGIN_OBJECT ='usr.login';
export const PROFILE_OBJECT = 'usr.profile';
export const RESET_PASS_OBJECT = 'usr.pass_reset';

//Список пользователей (Администраторов)
export const USERS_OBJECT = 'usr.users'; //Добавить нового пользователя

//Панель управления пользователями с ролью "Исследовтель"
export const USERS_INV_OBJECT = 'usr.users_inv';

//Форма редактирования иерархического справочника.
export const CLS_OBJECT = 'cls.cls';

//Пациент
export const SUB_EVENTS_OBJECT= 'sub.sub_events_statuses'; //Расписание заполнения дневника
export const SUB_SYMP_OBJECT ='sub.sub_symp'; //добавление симптомов пациент ОРВИ/Гриппа
export const SUBJECT_ANTIPYRETIC_OBJECT = 'sub.sub_antipyretic'; //Прием жаропонижающего препарата
export const SUBJECT_WORSERING_OBJECT = 'sub.sub_worsening'; //Ухудшение состояния
export const CHAT_OBJECT = 'sub.sub_chat'; //чтение/отправкa сообщения

//Панель администратора для доступа к данным пациентов
export const SUBJECT_OBJECT = 'sub.subjects'; //Список пациентов
export const EVENTS_STATUS_OBJECT = 'sub.events_statuses'; //Статус заполнения дневника
export const SUBJECT_SYMP_OBJECT = 'sub.symp'; //Симптомы ОРВИ/Гриппа
export const SYMP_PRINT_OBJECT = 'sub.symp_print'; //получение данных для pdf, только admin
export const ANTIPYRETIC_OBJECT = 'sub.antipyretic'; //Прием жаропонижающего препарата
export const WORSENING_OBJECT = 'sub.worsening'; //Ухудшение состояния
export const ADMIN_CHAT_OBJECT_GET = 'sub.subs_chat'; //Список пациентов
export const ADMIN_CHAT_OBJECT_ADD = 'sub.chat_ans'; //Ответ на сообщение пациента
export const ADMIN_CHAT_GET_MSG_OBJECT = 'sub.chat'; //Сообщения выбранного пациента
export const ADMIN_SUB_SYNC = 'sub.subs_sync';

//Отметить данные пациента как "верифицировано специалистом по КИ"
export const SUB_VERIFY_OBJECT =  'sub.verify';

//Отметить данные как проверенные исследователем
export const SUB_CHECK_OBJECT =  'sub.check';

//path
//admin
export const USERS_ADMIN_PATH = '/admin/users_admin'; //Управление пользователями с ролью "Администратор
export const CLS_ADMIN_PATH ='/admin/cls'; //Иерархический системный справочник
export const USERS_MON_PATH = '/admin/users_mon'; //Управления пользователями с ролью "Специалист по КИ"
export const USERS_INV_PAT = '/admin/users_inv'; //Панель управления пользователями с ролью "Исследовтель"
export const CENTERS_PATH = '/admin/centers'; //Редактор раздела классификатора - Центры
export const SUBJECT_ADMIN_PATH = '/admin/users-sub'; //Пациенты
export const ADMIN_CHAT_PATH = '/admin/help_chat'; //Чат поддержки пациентов (Админ)

//subject
export const SUBJECT_HOME_PATH = '/subject/home'; //Расписание заполнения дневника
export const SUBJECT_SYMP_PATH = '/subject/symp'; //Страница - симптомы ОРВИ/Гриппа
