import * as React from 'react';
import UsersConstructor from "../_commonComponents/pageConstructors/admin/AdminUsesrConstructor";
import AddNewUserForm from './components/AddNewUserForm';
import EditUserForm from './components/EditUserForm';

const ROLE = "MON";

/**
 * MonitorUsers - Специалисты по КИ '/admin/users_mon'
 * @constructor
 */
const MonitorUsers = ({history}) => (
    <UsersConstructor
        initFilter={[{name: "role_name", value: "MON"}]}
        title={"Специалисты по КИ"}
        titleIcon={"user-astronaut"}
        roles={ROLE}
        history={history}
        AddForm={AddNewUserForm}
        EditForm={EditUserForm}
    />
);

export default MonitorUsers;
