import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {SUB_SYMP_OBJECT, ADD} from '../../../config/constants';

export const SUBJECT_SYMP_DATA_IS_LOADING = 'SUBJECT_SYMP_DATA_IS_LOADING';
export const SUBJECT_SYMP_DATA_IS_FAILED = 'SUBJECT_SYMP_DATA_IS_FAILED';
export const SUBJECT_SYMP_DATA_IS_SUCCESS = 'SUBJECT_SYMP_DATA_IS_SUCCESS';

function dataIsLoading() {
    return {
        type: SUBJECT_SYMP_DATA_IS_LOADING
    }
}

function dataIsSuccess(data) {
    return {
        type: SUBJECT_SYMP_DATA_IS_SUCCESS,
        payload: data
    }
}

function dataIsFailed() {
    return {
        type: SUBJECT_SYMP_DATA_IS_FAILED
    }
}

export function fetchSubjectSympData(data, action = ADD, filter = []) {
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(action, SUB_SYMP_OBJECT, data, filter, initialKey, null, null, null);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if(response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                return;
            }

            dispatch(dataIsSuccess(data))
        })
    }
}
