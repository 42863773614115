import React from 'react';
import {PureComponent} from 'react';
import {BrowserRouter} from 'react-router-dom'
import Router from './Router';
import packageJson from '../../../package.json';
import ShowNotification from "../../utils/showNotification";

//настройки тем: blue - ММХ-407-001, purple: ММХ-407-001 purple - ММХ-407-002
/**
 * App
 */
class App extends PureComponent {
    notification = Object.create(ShowNotification);

    render() {
        return (
            <BrowserRouter basename={packageJson.homepage}>
                <div  className="wrapper">
                    <Router theme={packageJson.homepage === '/40701' ? 'blue' : 'purple'} />
                </div>
            </BrowserRouter>
        )
    }
}

export default App;
