import {
    CLS_ADMIN_L,
    CLS_ADMIN_E,
    CLS_ADMIN_F,
    CLS_ADMIN_S,
} from '../../actions/cls/clsAction';

const initialState = {
    isLoading: false,
    data: null,
    formData: null,
    isError: false,
    message: ''
};

export function clsReduce(state=initialState, action) {
    switch (action.type) {
        case CLS_ADMIN_L:
            return {...state, isLoading: true, isError: false};
        case CLS_ADMIN_F:
            return {...state, isLoading: false, isError: true, message: action.payload};
        case CLS_ADMIN_S:
            return {...state, isLoading: false, isError: false, data: action.payload};
        case CLS_ADMIN_E:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
