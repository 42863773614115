import React from 'react';
import NotificationSystem from 'rc-notification';
import Notification from '../containers/_commonComponents/Notification';

/**
 * NotificationComponent объект для уведомлений о результате ассинхронных запросов
 */
const  ShowNotification = {
    notification: null,

    init () {
        NotificationSystem.newInstance({}, n => this.notification = n);
    },

    destroy () {
        setTimeout(()=> {
            this.notification && this.notification.destroy();
        }, 15000);
    },

    /**
     * showNotification
     * @param msg {String}
     * @param title {String}
     * @param color {String}
     */
    createNotification (msg='', title = "Ошибка!", color = "danger") {
        this.notification.notice({
            content: <Notification title={title} message={msg} color={color} />,
            duration: 5,
            closable: true,
            style: { top: 0, left: 'calc(100vw - 100%)' },
            className: 'right-up',
        });
    },

    show(msg, title, color) {
        this.init();
        this.createNotification(msg, title, color);
        this.destroy();
    }
};

export default ShowNotification;
