import  * as React from 'react';
import { CardBody, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propTypes from 'prop-types';

/**
 * AdminCard
 * @param icon {String}
 * @param title {String}
 * @param onClick {Function}
 * @param path {String}
 * @constructor
 */
const AdminCard = ({icon, title, onClick, path}) => {
    const onHandleClick = () => onClick(path);

    return (
    <Col xs={12} md={6} lg={4} xl={3}>
        <Card style={{cursor: 'pointer'}} onClick={onHandleClick}>
            <CardBody className="text-center">
                <div className="card__icon">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                <h5 className="card__title">{title}</h5>
            </CardBody>
        </Card>
    </Col>
)
};

AdminCard.defaultProps = {
    path: propTypes.string.isRequired,
    icon: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    onClick: propTypes.func.isRequired,
};

AdminCard.defaultProps = {
    path: '/',
    title: ''
};

export default AdminCard;
