import * as React from 'react';
import { PureComponent } from 'react';
import {Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {reduxForm, reset} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';

/**
 * ModalForm
 */
class ModalForm extends PureComponent {
    static propTypes  = {
        isOpen: propTypes.bool.isRequired,
        toggle: propTypes.func.isRequired,
        title: propTypes.string.isRequired,
        submitText: propTypes.string,
        closeText: propTypes.string,
        handleSubmit: propTypes.func.isRequired,
        color: propTypes.string,
        header: propTypes.bool,
        initialValues: propTypes.object,
        form: propTypes.string.isRequired
    };

    static defaultProps = {
        title: '',
        submitText: 'Сохранить',
        closeText: 'Отмена',
        color: 'success',
    };

    onSubmit = async (values) => {
        await this.props.onSubmit(values);
        this.props.toggle();
        this.props.reset(this.props.form)
    };

    get modalClass() {
        const {
            colored,
            header
        } = this.props;

        return classnames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });
    }

    toggle = () => {
        this.props.toggle();
        this.props.reset(this.props.form)
    };

    render() {
        const {
            isOpen,
            toggle,
            title,
            submitText,
            closeText,
            children,
            handleSubmit,
            color,
            theme,
            header
        } = this.props;

        return (
            <Modal color={color} isOpen={isOpen} toggle={toggle} className={classnames(`theme-${theme}`, header ? 'modal-dialog--maxWidth' : '')}>
                <div className={classnames(this.modalClass, 'modal__content')}>
                    <div className={classnames(`modal-dialog--${color}`)}>
                        <div className="modal__header">
                            <button className="modal__close-btn" onClick={toggle} >
                                <FontAwesomeIcon icon="times" />
                            </button>
                            <div className="bold-text  modal__title">{title}</div>
                        </div>
                        <form onSubmit={handleSubmit(this.onSubmit)} className="form">
                            <ModalBody>
                                {children}
                            </ModalBody>
                            <ModalFooter>
                                <Button type={"button"}  color="secondary" onClick={toggle}>{closeText}</Button>{' '}
                                <Button type={"submit"} color={color}>{submitText}</Button>
                            </ModalFooter>
                        </form>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (store, props) => ({
    form: props.form,
    initialValues: props.initialValues,
    theme: store.settings.theme
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false
})(ModalForm));
