import {SUBJECT_OBJECT, GET} from '../../../config/constants';
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import ShowNotification from "../../../utils/showNotification";

export const SUBJECT_ADMIN_L = 'SUBJECT_ADMIN_L';
export const SUBJECT_ADMIN_F = 'SUBJECT_ADMIN_F';
export const SUBJECT_ADMIN_S = 'SUBJECT_ADMIN_S';
export const SUBJECT_ADMIN_EVENT = 'SUBJECT_ADMIN_EVENT';
export const SUBJECT_ADMIN_SYMP = 'SUBJECT_ADMIN_SYMP';

function dataIsLoading() {
    return {
        type: SUBJECT_ADMIN_L
    }
}

function dataIsFailed(msg) {
    return {
        type: SUBJECT_ADMIN_F,
        payload: msg
    }
}

function dataIsSuccess(data) {
    return {
        type: SUBJECT_ADMIN_S,
        payload: data
    }
}

function getEventData(data) {
    return {
        type: SUBJECT_ADMIN_EVENT,
        payload: data
    }
}

function getSympData(data) {
    return {
        type: SUBJECT_ADMIN_SYMP,
        payload: data
    }
}

export function fetchAdminSubjectData(action = GET,
                                   object = SUBJECT_OBJECT,
                                   filter = [],
                                   type = null,
                                   order = '{daynum}',
                                   offset=null
                                   ) {
    return (dispatch) => {
        const notification = Object.create(ShowNotification);
        const URL = UrlCreator();
        const  requestData = formDataCreator(action, object, {}, filter, localStorage.token, 20, offset, order);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed(data.msg));
                notification.show(`${data.msg}`);
                return;
            }

            switch(type) {
                case 'events':
                    dispatch(getEventData(data));
                break;
                case 'sympt':
                    dispatch(getSympData(data));
                    break;
                default:
                    dispatch(dataIsSuccess(data))
            }
        }).catch(e => console.error(e))
    }
}
