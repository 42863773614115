import * as React from 'react';
import {PureComponent} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import renderInput from '../../_commonComponents/form/InputWithValidation';
import {DATE_MASK, TIME_MASK, TEMP_MASK} from "../../../config/constants";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import IconButton from '../../_commonComponents/buttons/IconButton';
import Modal from '../../_commonComponents/Modal';

//redux
import {reduxForm, Field} from 'redux-form';

const MOBILE = 992;

const validate = (values) => {
    const errors = {};

    if (values.cmtemp < 34 || values.cmtemp > 44) {
        errors.cmtemp = 'Введите корректную температуру'
    } else if (values.cmtemp && values.cmtemp.length < 4) {
        errors.cmtemp = 'Введите корректную температуру'
    }

    if (values.cmtime && !/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(values.cmtime)) {
        errors.cmtime = 'Укажите время в 24-ом формате';
    } else if (values.time && values.time.length < 5) {
        errors.cmtime = 'Укажите время в 24-ом формате';
    }

    return errors;
};

/**
 * SubAntipyreticForm Прием жаропонижающего препарата
 */
class SubAntipyreticForm extends PureComponent {
    state = {
        isPopupOpen: false,
        isMobile: false
    };

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;

        this.setState({
            isMobile
        })
    };

    dataValidation = (value) => {
        const {
            start,
            end,
            startText,
            endText
        } = this.props;

        if (!/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g.test(value)) {
            return 'Введите корректную дату';
        }

        const arr = value && value.split('.');
        value = arr && `${arr[2]}-${arr[1]}-${arr[0]}`;

        return (+new Date(value) < +new Date(start) || +new Date(value) > +new Date(end)) ? `Дата не может быть меньше ${startText} и больше ${endText}` : undefined
    };

    togglePopup = () => {
        const {
            pristine,
            redirect
        } = this.props;

        if (pristine) {
            redirect()
        } else {
            this.setState(prebState => ({
                isPopupOpen: !prebState.isPopupOpen
            }))
        }
    };

    timeValidation = (values) => {
        if (values && !/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(values)) {
            return 'Укажите время в 24-ом формате';
        } else if (values && values.length < 5) {
            return 'Укажите время в 24-ом формате';
        }
    };

    render() {
        const {
            handleSubmit,
            onSubmit,
            redirect,
            pristine,
            submitting
        } = this.props;

        const {
            isPopupOpen,
            isMobile
        } = this.state;

        return (
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className={"d-flex flex-column flex-lg-row"}>
                    <div className="d-flex flex-shrink-0 justify-content-between">
                        <Field
                            name={"cmdate"}
                            component={renderInput}
                            mask={DATE_MASK}
                            label={"Дата"}
                            validate={[this.dataValidation]}
                            placeholder={"дд.мм.гггг"}
                            style={{maxWidth: isMobile ? '50%' : 97}}
                            className={"mr-4"}
                            type="tel"
                        />
                        <Field
                            name={"cmtime"}
                            component={renderInput}
                            mask={TIME_MASK}
                            label={"Время"}
                            validate={[this.timeValidation]}
                            right
                            placeholder={"__ __:__ __"}
                            icon={<ClockOutlineIcon/>}
                            style={{maxWidth: isMobile ? '50%' : 130}}
                            className={"mr-lg-4"}
                            type="tel"
                        />
                    </div>
                    <Field
                        name={"cmtemp"}
                        component={renderInput}
                        mask={TEMP_MASK}
                        label={"Температура"}
                        placeholder={"__ __.__"}
                        type="tel"
                        right
                        icon={"°С"}
                        style={{maxWidth: 102, minWidth: 102}}
                        className={"mr-4"}
                    />
                    <Field
                        name={"cmname"}
                        component={renderInput}
                        label={"Торговое наименование"}
                        className={"mr-lg-4"}
                    />
                    <Field
                        name={"cmdose"}
                        component={renderInput}
                        label={"Принятое количество"}
                    />
                </div>
                <ButtonToolbar className="d-flex justify-content-end pb-4">
                    <IconButton icon="home" color={"secondary"} onClick={this.togglePopup} className={"mr-3"}/>
                    <Button type={"submit"} color={'primary'} className={"mb-0"} disabled={pristine || submitting}>Подтвердить</Button>
                </ButtonToolbar>
                <Modal
                    color={"danger"}
                    isOpen={isPopupOpen}
                    toggle={this.togglePopup}
                    header
                    onSubmit={redirect}
                    submitText={"Подтвердить"}
                    title={"Вы уверены, что хотите закрыть форму?"}
                    message={"Все несохраненные данные будут потеряны"}
                />
            </form>
        )
    }
}

export default reduxForm(
    {
        form: 'sub_antipyretic_form',
        enableReinitialize: true,
        touchOnChange: true,
        validate
    })(SubAntipyreticForm);
