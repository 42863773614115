import * as React from 'react';
import {PureComponent} from 'react';

//components
import LoginForm from './components/LoginForm';
import RestorePassForm from './components/RestorePassForm';

//redux
import {connect} from 'react-redux';

class Login extends PureComponent {
    state = {
        isLoginFormShow: true
    };

    onToggle = () => {
      this.setState(prevState =>({
          isLoginFormShow: !prevState.isLoginFormShow
      }))
    };

    render() {
        const {
            isLoginFormShow
        } = this.state;

        const {
            history,
            title
        } = this.props;

        return (
            <div className="account">
                <div className="account__wrapper">
                    <div className="account__card">
                        <div className="account__head">
                            <h3 className="account__title">Дневник пациента <b className="bold-text">ММH-<span>{title}</span></b></h3>
                            <h4 className="account__subhead subhead">(Электронная версия)</h4>
                        </div>
                        {isLoginFormShow && <LoginForm history={history} onToggle={this.onToggle}/>}
                        {!isLoginFormShow && <RestorePassForm onToggle={this.onToggle} />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    title: store.settings.title
});

export default connect(mapStateToProps)(Login);
