import {    SUBJECT_ADMIN_EVENT, //event
    SUBJECT_ADMIN_SYMP, SUBJECT_ADMIN_S, SUBJECT_ADMIN_F, SUBJECT_ADMIN_L} from "../../actions/admin/adminSubjectAction";

const initialState = {
    isLoading: false,
    data: null,
    eventData: null,
    sympData: null,
    isError: false,
    msg: ''
};

export function adminSubjectReducer(state = initialState, action) {
    switch (action.type) {
        case SUBJECT_ADMIN_L:
            return {...state, isLoading: true, isError: false};
        case SUBJECT_ADMIN_F:
            return {...state, isLoading: false, isError: true, msg: action.payload};
        case SUBJECT_ADMIN_S:
            return {...state, isError: false, isLoading: false, data: action.payload};
        case SUBJECT_ADMIN_EVENT:
            return {...state, isError: false, isLoading: false, eventData: action.payload};
        case SUBJECT_ADMIN_SYMP:
            return {...state, isError: false, isLoading: false, sympData: action.payload};
        default:
            return state
    }
}
