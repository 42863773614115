import {
    SUBJECT_SYMP_DATA_IS_SUCCESS,
    SUBJECT_SYMP_DATA_IS_LOADING,
    SUBJECT_SYMP_DATA_IS_FAILED
} from "../../actions/subject/subjectSympAction";

const initialState = {
    data: null,
    isLoading: false,
    isError: false
};

export function subjectSympReducer(state = initialState, action) {
    switch (action.type) {
        case SUBJECT_SYMP_DATA_IS_LOADING:
            return {isLoading: true, isError: false};
        case SUBJECT_SYMP_DATA_IS_FAILED:
            return {isLoading: false, isError: true};
        case SUBJECT_SYMP_DATA_IS_SUCCESS:
            return {isLoading: false, isError: false, data: action.payload};
        default:
            return state;
    }
}
