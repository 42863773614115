import 'cross-fetch/polyfill';

import {LOGIN_OBJECT, PROFILE_OBJECT} from "../../../config/constants";
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {getObjectData} from "../../../utils/getObjectData";

export const IS_USER_LOGIN_S = 'IS_USER_LOGIN_S';
export const IS_USER_LOGIN_L = 'IS_USER_LOGIN_L';
export const IS_USER_LOGIN_E = 'IS_USER_LOGIN_E';

function dataIsLoading(){
    return {
        type: IS_USER_LOGIN_L
    }
}

function dataIsFailed(){
    return {
        type: IS_USER_LOGIN_E
    }
}

function dataIsSuccess(data, key) {
    return {
        type: IS_USER_LOGIN_S,
        payload: data,
        key
    }
}


export function fetchLoginData(data = {}, isLogin = false) {
    return (dispatch) => {
        let initialKey = localStorage.token;
        const object = isLogin ? LOGIN_OBJECT: PROFILE_OBJECT;
        const URL = UrlCreator();
        const requestData = formDataCreator(null, object, data, [], initialKey);
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
                if (response.status !== 200) {
                    dispatch(dataIsFailed());
                    throw Error(response.statusText);
                }
                dispatch(dataIsLoading());
                return response.json();
            }
        ).then((data) => {
            // eslint-disable-next-line
            if (data.status != 0) {
                dispatch(dataIsFailed());
                throw Error(data.msg);
            }

            const skey = getObjectData(() => data.skey);
            localStorage.token = skey;
            dispatch(dataIsSuccess(data, skey))
        })
    }
}
