import * as React from 'react';
import {PureComponent} from 'react';

//utils
import {getObjectData} from "../../utils/getObjectData";

//constants
import {GET, MOBILE} from "../../config/constants";

//components
import AdminPageConstructor from "../_commonComponents/pageConstructors/admin/adminPageConstructor";
import AddNewUserForm from "./components/AddNewUserForm";
import EditUserForm from "./components/EditUserForm";
import renderSelect from '../_commonComponents/form/MultiSelect';

//redux
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fetchUsersInvAdminData} from "../../redux/actions/admin/usersInvAdminAction";
import {fetchClsData} from "../../redux/actions/cls/clsAction";

const DESKTOP = 1200;

/**
 * InvestigatorUsers - Исследователи '/admin/users_inv'
 * @constructor
 */
class InvestigatorUsers extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isDesktop: false,
            options: null,
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
        this.getInitialData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.options && prevProps.options.length !== this.props.options && this.props.options.length) {
        }
    }

    getInitialData = async () => {
        const {
            fetchClsData,
        } = this.props;

        await fetchClsData(GET, [{name:"path", value:"CENTERS"}], {}, true, null, null, null);
        const options = this.props.options && this.props.options.map(item => ({value: item.name, label: item.name + ' ' + item.title}));
        this.setState({
            options
        })
    };

    /**
     * isMobile
     */
    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;
        const isDesktop = window.innerWidth < DESKTOP;

        this.setState({
            isMobile,
            isDesktop
        })
    };

    onFilter = (values) => {
        let filter = [];

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === 'center') {
                    const compare = 'IN';
                    if (values[key] && values[key].length > 0) {
                        filter = filter.concat({name: key, value: `{${values[key]}}`, compare});
                    }
                } else {
                    filter.push({name: key, value: `%${values[key]}`, compare: 'LIKE'});
                }
            }
        }

        return filter;
    };

    /**
     * @param renderCell {Function}
     * return Array
     */
    setColumns = (renderCell) => {
        const {
            isMobile,
            isDesktop,
            options
        } = this.state;

        return [
            {
                Header: '',
                accessor: 'name',
                id: 'name',
                placeholder: 'Имя пользователя',
                filterable: false,
                sortable: false,
                width: isDesktop ? '100%' : 180,
                maxWidth: isDesktop ? '100%' : 180,
                minWidth: isDesktop ? 0 : 180
            },
            {
                ...!isDesktop && {
                    Header: '',
                    accessor: 'title',
                    id: 'title',
                    placeholder: 'ФИО',
                    filterable: false,
                    sortable: false,
                }
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'phone_view',
                    id: 'phone_search',
                    placeholder: 'Телефон',
                    filterable: false,
                    sortable: false,
                    style: {minWidth: 332},
                    width: 332,
                    maxWidth: 332,
                    minWidth: 332
                }
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'center_name',
                    id: 'center',
                    placeholder: 'Центр',
                    filterable: false,
                    sortable: false,
                    style:{ whiteSpace: 'unset', minWidth: isDesktop ? 0 : 332},
                    component: renderSelect,
                    options: options,
                    width: 332,
                    maxWidth: 332,
                    minWidth: 332
                }
            },
            {
                Header: '',
                accessor: 'action',
                filterable: false,
                sortable: false,
                Cell: renderCell,
                width: 80,
                minWidth: 80
            }
        ]
    };

    /**
     * return {Node} AdminPageConstructor
     */
    render() {
        const {
            data,
            total,
            formData,
            fetchUsersInvAdminData,
            history
        } = this.props;

        const {
            isMobile,
            options,
            isDesktop
        } = this.state;

        return (
            options && <AdminPageConstructor
                data={data}
                isMobile={isMobile}
                isDesktop={isDesktop}
                total={total}
                formData={formData}
                fetchData={fetchUsersInvAdminData}
                AddForm={AddNewUserForm}
                EditForm={EditUserForm}
                initFilter={[]}
                renderColumns={this.setColumns}
                title={'Исследователи'}
                titleIcon={'user-md'}
                history={history}
                onFilter={this.onFilter}
            />
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.invUsers.data.data),
    total: getObjectData(() => store.invUsers.data.total),
    formData: getObjectData(() => store.invUsers.formData),
    options: getObjectData(() => store.cls.data.data)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsersInvAdminData,
    fetchClsData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvestigatorUsers);
