import {DATA_SYNC_F, DATA_SYNC_S, DATA_SYNC_L} from "../actions/syncDataAction";

const initialState = {
    isLoading: false,
    data: null
};

export function syncDataReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_SYNC_L:
            return {...state, isLoading: true};
        case DATA_SYNC_F:
            return {...state, isLoading: false};
        case DATA_SYNC_S:
            return {...state, isLoading: false, data: action.payload};
        default:
            return state;
    }
}
