/* jshint expr: true */

import * as React from 'react';
import {PureComponent} from 'react';
import {Container} from 'reactstrap';
import Form from './component/SubWorseningForm';
import Table from './component/SubWorseningTable';
import {ADD, GET, SUBJECT_HOME_PATH, SUBJECT_WORSERING_OBJECT} from "../../config/constants";
import {getObjectData} from "../../utils/getObjectData";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reset} from 'redux-form';
import {fetchWorseningData} from "../../redux/actions/subjectSymp/subjectWorseningAction";

//components
import Panel from "../_commonComponents/Panel";

/**
 * SubWorsering Ухудшение состояния '/subject/worsening'
 */
class SubWorsering extends PureComponent{
    state = {
        data: null
    };

    componentDidMount() {
        this.getInitialData();
    }

    redirect = () => {
        this.props.history.push(SUBJECT_HOME_PATH)
    };

    getInitialData = async () => {
        const {
            fetchWorseningData
        } = this.props;

        await fetchWorseningData(GET, {}, [], true, SUBJECT_WORSERING_OBJECT);

        this.setState({
            data: this.props.data
        })
    };

    /**
     * onSubmit
     * @param values {Object}
     */
    onSubmit = async (values) => {
        const {
            fetchWorseningData,
            reset
        } = this.props;

        await fetchWorseningData(ADD, values, [], false, SUBJECT_WORSERING_OBJECT);
        this.setState({
            data: [...this.state.data, this.props.formData]
        }, () => {
            reset('subject_worsening_form')
        })
    };

    render() {
        const {
            data,
        } = this.state;

        const {
            start,
            end,
            startText,
            endText
        } = this.props;

        return (
            <Container style={{maxWidth: 1000}} className="pt-2 pb-2">
                <Panel title={"Ухудшение состояния"} header>
                    <Form
                        redirect={this.redirect}
                        onSubmit={this.onSubmit}
                        start={start}
                        end={end}
                        startText={startText}
                        endText={endText}
                    />
                    {(data && data.length !== 0) && <Table
                        data={data}
                    />}
                </Panel>
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.worseningData.data.data) || [],
    formData: getObjectData(() => store.worseningData.formData),
    start: getObjectData(() => store.profile.data.period.date_begin),
    end: getObjectData(() => store.profile.data.period.date_end),
    startText: getObjectData(() => store.profile.data.period.date_begin_text),
    endText: getObjectData(() => store.profile.data.period.date_end_text),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchWorseningData,
    reset
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SubWorsering);
