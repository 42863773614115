import * as React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

/**
 * ChatCard - карточка сообщения
 * @param children {Node}
 * @param status {number}
 * @return {*}
 * @constructor
 */
const ChatCard = ({children, status}) => (
    <div className={classnames("chat__cardInner", status === 'receive' ? "chat__cardInner_right" : "")}>
        <div className={classnames("chat__card", status === 'receive' ? "chat__card_right" : "")}>
            {children}
        </div>
    </div>
);

ChatCard.propTypes = {
    children: propTypes.string.isRequired,
    status: propTypes.string.isRequired
};

export default ChatCard;
