import * as React from 'react';
import {useState, useEffect} from 'react';
import {Select, Input, Checkbox, ListItemText, MenuItem, FormControl} from '@material-ui/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getObjectData} from "../../../utils/getObjectData";

const MultiSelect = ({options, onChange, name, placeholder, isSortable, isSorted, sortedName, onSort, style, value}) => {
    const [optionName, setOption] = useState(value ? value : []);
    const [checked, isChecked] = useState(false);

    const arr = options && options.map(item => item.value);

    useEffect(() => {
        try {
            const defaultFilter = getObjectData(() => JSON.parse(localStorage.getItem('defaultCenterFilter')));
            if (Array.isArray(defaultFilter) && (defaultFilter[0] !== Object(defaultFilter[0]))) {
                handleChange(defaultFilter);
            } else {
                handleChange([])
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    function handleChange(value) {
        if (value[value.length - 1] !== 'all') {
            setOption(value);
            onChange(value);
        } else {
            if (!checked) {
                setOption(arr);
                onChange([]);
            } else {
                setOption([]);
            }
            isChecked(!checked);
        }
    }

    function resetFilter() {
        setOption([]);
        isChecked(false);
        onChange([]);
    }

    return (
        <FormControl style={style} className="form__form-group-select">
            <Select
                multiple
                displayEmpty
                value={optionName}
                onChange={(e) => handleChange(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        return;
                    }
                    handleChange(e.target.value)
                }}
                input={<Input  id="select-multiple-placeholder"/>}

                renderValue={selected => {
                    if (selected && selected.length === 0) {
                        return <span className={"form__form-group-select_placeholder"}>{placeholder}</span>;
                    } else if (selected && selected.length === arr.length) {
                        return <span>Все центры</span>
                    } else {
                        return selected.join(', ');
                    }
                }}
            >
                <MenuItem value="all">
                    <Checkbox color={"primary"} checked={checked}/>
                    <ListItemText primary={"Все центры"} className="form__form-group-select-all"/>
                </MenuItem>
                {options && options.map(option => (
                    <MenuItem color="primary" key={option.label} value={option.value}>
                        <Checkbox color="primary" checked={optionName.indexOf(option.value) > -1}/>
                        <ListItemText primary={option.label}/>
                    </MenuItem>
                ))}
            </Select>
            <div className="form__form-group-select-icon">
                {optionName.length > 0 && <div className={"form__form-group-select-close"} onClick={resetFilter}>
                    <FontAwesomeIcon className={"form__form-group-select-filter"} color={"#dddddd"} icon={"times"}/>
                </div>}
                {isSortable && <div key={isSorted}>
                    {!sortedName &&
                    <div onClick={() => onSort(name)}>
                        <FontAwesomeIcon color={'#E5E5E5'} icon={"sort"}/>
                    </div>
                    }
                    {sortedName &&
                    <div onClick={onSort}>
                        <FontAwesomeIcon color={'black'} icon={isSorted ? "sort-down" : "sort-up"}/>
                    </div>}
                </div>}
            </div>
        </FormControl>
    )
};

const renderMultiSelect = (props) => {
 return <MultiSelect
     {...props.input}
     {...props}
 />
};


export default renderMultiSelect;
