import {STATUS_IS_FAILED, STATUS_IS_SUCCESS, STATUS_IS_LOADING} from "../../actions/subject/subjectStatusAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null
};

export function subjectStatusReducer(state = initialState, action) {
    switch (action.type) {
        case STATUS_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case STATUS_IS_FAILED:
            return {...state, isLoading: false, isError: true};
        case STATUS_IS_SUCCESS:
            return {...state, isLoading: false, isError: false, data: action.payload};
        default:
            return state
    }
}
