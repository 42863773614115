import {
    USER_ADMIN_DATA_E,
    USER_ADMIN_DATA_S,
    USER_ADMIN_DATA_F,
    USERS_ADMIN_DATA_L,
} from '../../actions/admin/usersAdminAction';

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    formData: null,
    message: "",
};

export function usersAdminReducer(state = initialState, action) {
    switch (action.type) {
        case USERS_ADMIN_DATA_L:
            return {isLoading: true,  isError: false};
        case USER_ADMIN_DATA_F:
            return {isLoading: false, isError: true, message: action.payload};
        case USER_ADMIN_DATA_S:
            return {isLoading: false, isError: false, data: action.payload};
        case USER_ADMIN_DATA_E:
            return {isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
