import React, {PureComponent} from 'react';
import {Container} from 'reactstrap';
import SubjectForm from './components/SubjectForm';
import {getObjectData} from "../../utils/getObjectData";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSubjectSympData} from "../../redux/actions/subject/subjectSympAction";

import {GET} from "../../config/constants";

/**
 *  Симптомы ОРВИ
 */
class SubjectSymp extends PureComponent {
    componentDidMount() {
        const {
            fetchSubjectSympData,
            match
        } = this.props;

        const id = getObjectData(() => match.params.id);
        const filter = [{name: 'id', value: id}];

        fetchSubjectSympData({}, GET, filter)
    }

    get daynum() {
        const {
            match
        } = this.props;

        return getObjectData(() => match.params.daynum);
    }
    get daypart() {
        const {
            match
        } = this.props;

        return getObjectData(() => match.params.daypart);
    }

    get readOnly() {
        const {
            match
        } = this.props;

        return getObjectData(() => match.params.id);
    }

    render() {
        const {history, initialValues, match} = this.props;

        return (
            <Container className="subjectSymp">
                <SubjectForm
                    daynum={this.daynum}
                    history={history}
                    daypart={this.daypart}
                    initialValues={initialValues}
                    readOnly={Boolean(+getObjectData(() => match.params.id))}
                />
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    initialValues: getObjectData(() => store.subSymptoms.data.data[0]),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubjectSympData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubjectSymp);
