import {
    SUBJECT_DATA_IS_FAILED,
    SUBJECT_DATA_IS_SUCCESS,
    SUBJECT_DATA_IS_LOADING,
} from "../../actions/subject/subjectAction";

const initialState = {
  data: null,
  isLoading: false,
  isError: false
};

export function subjectReducer(state = initialState, action) {
    switch (action.type) {
        case SUBJECT_DATA_IS_LOADING:
            return {isLoading: true, isError: false};
        case SUBJECT_DATA_IS_FAILED:
            return {isLoading: false, isError: true};
        case SUBJECT_DATA_IS_SUCCESS:
            return {isLoading: false, isError: false, data: action.payload};
        default:
            return state;
    }
}
