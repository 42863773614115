import * as React from 'react';
import {PureComponent} from 'react';
import PhoneIcon from "mdi-react/MobilePhoneAndroidIcon";
import {Button} from "reactstrap";
import propTypes from 'prop-types';

//utils
import {required} from "../../../utils/formValidation";

//components
import renderInput from "../../_commonComponents/form/InputWithValidation";

//redux
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchResetPassData} from "../../../redux/actions/profile/resetPassAction";

//constants
import {PHONE_MASK} from "../../../config/constants";

/**
 * RestorePassForm Страница восстановления пароля
 */
export class ResetPassForm extends PureComponent {
    static propTypes = {
        onToggle: propTypes.func.isRequired,
        handleSubmit: propTypes.func.isRequired
    };

    onSubmit = async (values) => {
        const {
            fetchResetPassData,
            onToggle
        } = this.props;

        await fetchResetPassData(values);
        !this.props.isError && onToggle();
    };

    render() {
        const {
            onToggle,
            handleSubmit
        } = this.props;

        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                    name="phone"
                    component={renderInput}
                    validate={[required]}
                    type="text"
                    mask={PHONE_MASK}
                    icon={<PhoneIcon/>}
                    label={'Номер мобильного телефона:'}
                />
                <Button type="submit" color="primary" className="w-100 m-0 mb-2 mt-2">Восстановить ПИН-код</Button>
                <Button type="button" color="secondary" className="w-100 m-0" onClick={onToggle}>Отмена</Button>
            </form>
        )
    }
}

ResetPassForm = reduxForm({
    form: 'restore_pass_form',
    enableReinitialize: true
})(ResetPassForm);

const mapStateToProps = (store) => ({
    isError: store.resetPass.isError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchResetPassData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassForm);
