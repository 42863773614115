import React, {PureComponent} from 'react';
import propTypes from 'prop-types';
import {Col, Container, Row} from "reactstrap";
import classnames from 'classnames';

//redux
import {connect} from 'react-redux';

//component
import SubjectTable from './components/SubjectTable';
import CardHeader from "../../../CardHeader";
import EventsTable from './components/EventsTable';
import SympTabs from './components/SympTabs';

//utils
import {getObjectData} from "../../../../../utils/getObjectData";

class SubjectPageConstructor extends PureComponent {
    static propTypes = {
        columns: propTypes.array.isRequired,
        isResetVisible: propTypes.bool,
        status: propTypes.bool,
        history: propTypes.object,
        isTablet: propTypes.bool,
        maxWidth: propTypes.string,
        className: propTypes.string,
        headerClassName: propTypes.string,
        headerTotal: propTypes.number
    };

    static defaultProps = {
        isReloadVisible: true,
        status: false,
        className: '',
        isTablet: false,
        headerClassName: '',
        maxWidth: '100%',
        isResetVisible: true,
        headerTotal: null
    };

    constructor(props) {
        super(props);

        this.state = {
            isSubjectTableVisible: true,
            isEventsTableVisible: false,
            isSympFormVisible: false,
            isAntipyreticSympVisible: false,
            isWorseningSympVisible: false,
            isRefreshTable: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isTablet !== this.props.isTablet) {
            this.setState({
                isSubjectTableVisible: true,
                isEventsTableVisible: false,
                isSympFormVisible: false,
                isAntipyreticSympVisible: false,
                isWorseningSympVisible: false,
            })
        }
    }

    openEventsTable = () => {
        const {
            isTablet,
        } = this.props;


        if (isTablet) {
            this.setState({
                isSubjectTableVisible: false,
                isEventsTableVisible: true
            });
        }
    };

    openSymptForm = () => {
        const {
            isTablet,
        } = this.props;

        if (isTablet) {
            this.setState({
                isSubjectTableVisible: false,
                isEventsTableVisible: false,
                isSympFormVisible: true,
            })
        }
            this.setState({
                isAntipyreticSympVisible: false,
                isWorseningSympVisible: false,
            })
    };

    openAntipyreticTable = () => {
        this.setState({
            isAntipyreticSympVisible: true,
            isWorseningSympVisible: false,
            isSympFormVisible: true,
            isEventsTableVisible: false,
        })
    };

    openWorseningTable = () => {
        this.setState({
            isWorseningSympVisible: true,
            isAntipyreticSympVisible: false,
            isSympFormVisible: true,
            isEventsTableVisible: false,
        })
    };

    levelUp = () => {
        const {
            isSympFormVisible,
            isEventsTableVisible,
            isAntipyreticSympVisible
        } = this.state;

        if (isSympFormVisible || isAntipyreticSympVisible) {
            this.setState({
                isSympFormVisible: false,
                isAntipyreticSympVisible: false,
                isWorseningSympVisible: false,
                isEventsTableVisible: true,
                isSubjectTableVisible: false
            })
        } else if (isEventsTableVisible) {
            this.setState({
                isSympFormVisible: false,
                isEventsTableVisible: false,
                isSubjectTableVisible: true
            })
        }
    };

    /**
     * resetTable
     */
    resetTable = () => {
        this.setState(prevProps => ({
            isSubjectTableVisible: true,
            isEventsTableVisible: false,
            isSympFormVisible: false,
            isAntipyreticSympVisible: false,
            isWorseningSympVisible: false,
            isRefreshTable: !prevProps.isRefreshTable
        }))
    };

    get isTableVisible() {
        const {
            isTablet
        } = this.props;

        const {
            isSubjectTableVisible
        } = this.state;

        return !isTablet || isSubjectTableVisible;
    }

    get isEventsVisible() {
        const {
            isTablet
        } = this.props;

        const {
            isEventsTableVisible
        } = this.state;

        return !isTablet || isEventsTableVisible;
    }

    get isSympVisible() {
        const {
            isTablet
        } = this.props;

        const {
            isSympFormVisible
        } = this.state;

        return !isTablet || isSympFormVisible;
    }

    get title() {
        const {
            isTablet,
            selectedRow
        } = this.props;

        const title = getObjectData(() => selectedRow.num);

        return isTablet ? (title ? title : 'Пациенты') : 'Пациенты';
    }

    get actionIcon() {
        const {
            isTablet,
        } = this.props;

        return isTablet ? (!this.isTableVisible ?  'chevron-left' : null) : null;
    }

    render() {
        const {
            columns,
            isResetVisible,
            isTablet,
            maxWidth,
            className,
            headerClassName,
            history,
            status,
            syncAction,
            syncIcon,
            headerTotal
        } = this.props;

        const {
            isRefreshTable,
            isAntipyreticSympVisible,
            isWorseningSympVisible
        } = this.state;

        return (
            <Container style={{maxWidth: maxWidth}} className={"mt-2"}>
                <Row>
                    <Col xs={12}>
                        <CardHeader
                            title={this.title}
                            titleIcon={'users'}
                            actionIcon={this.actionIcon}
                            reset={this.resetTable}
                            action={this.levelUp}
                            isResetVisible={isResetVisible}
                            className={headerClassName}
                            history={history}
                            syncAction={syncAction}
                            syncIcon={syncIcon}
                            headerTotal={headerTotal}
                        />
                    </Col>
                </Row>
                <Row className={classnames("subAdminTable", className)}>
                    <SubjectTable
                        key={isRefreshTable}
                        isTablet={isTablet}
                        isTableVisible={this.isTableVisible}
                        openEventsTable={this.openEventsTable}
                        columns={columns}
                        status={status}
                    />
                    {(this.isEventsVisible || this.isSympVisible)
                    && <Col className="subAdminTable__inner">
                        <EventsTable
                            isRefreshTable={isRefreshTable}
                            isEventsVisible={this.isEventsVisible}
                            openSymptForm={this.openSymptForm}
                            openAntipyreticTable={this.openAntipyreticTable}
                            openWorseningTable={this.openWorseningTable}
                            isTablet={isTablet}
                        />
                        <SympTabs
                            isSympVisible={this.isSympVisible}
                            isAntipyreticSympVisible={isAntipyreticSympVisible}
                            isWorseningSympVisible={isWorseningSympVisible}
                        />
                    </Col>}
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    selectedRow: store.subTableSelectedRow.selectedRow
});

export default connect(mapStateToProps)(SubjectPageConstructor);
