import * as React from 'react';
import {PureComponent, Fragment} from 'react';
import Entry from './Entry';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchClsData} from "../../../redux/actions/cls/clsAction";
import {getObjectData} from "../../../utils/getObjectData";
import {GET, ADD, SET, DEL} from "../../../config/constants";

class Group extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            currentParent: null,
        }
    }

    componentDidMount() {
        this.getInitialData(this.props.parent);
    }

    getInitialData = async (parent) => {
        const {
            fetchClsData
        } = this.props;

        const filter = [{name: "parent", value: parent}];
        await fetchClsData(GET, filter);

        this.setState({
            data: this.props.data
        })
    };

    onClick = async (parent, collapsed) => {
        const {
            fetchClsData
        } = this.props;

        let updateData = [...this.state.data];

        await fetchClsData(SET, [], {id: parent, collapsed: !collapsed}, false);
        const index = this.state.data.findIndex(({id}) => id === this.props.formData.id);
        updateData.splice(index, 1, this.props.formData);
        this.setState({
            data: updateData
        })
    };

    setCurrentParent = async (currentParent) => {
        this.setState({
            currentParent
        })
    };

    addUserSubmit = async (values) => {
        const {
            fetchClsData
        } = this.props;

        const data = {
            ...{parent: this.state.currentParent},
            ...values
        };

        await fetchClsData(ADD, [], data, false);
        this.props.toggleKey();
    };

    editUserSubmit = async (values) => {
        const {
            fetchClsData
        } = this.props;

        const data = {
                ...values,
            ...{parent: values.parent},
        };

        let updateData = [...this.state.data];

        await fetchClsData(SET, [], data, false);
        const index = updateData.findIndex(({id}) => id === getObjectData(() => this.props.formData.id));
        index !== -1 && updateData.splice(index, 1, this.props.formData);
        this.setState({
            data: updateData
        })
    };

    onDelete = async (id) => {
        const {
            fetchClsData
        } = this.props;

        let updateData = [...this.state.data];
        await fetchClsData(DEL, [], {id}, false);

        const index = updateData.findIndex(({id}) => id === this.props.formData.id);
        updateData.splice(index, 1);
        this.setState({
            data: updateData
        })
    };

    render() {
        const {
            data,
        } = this.state;

        const {
            toggleKey
        } = this.props;

        return (
            <Fragment>
                    {data && data.map(item =>
                        <Entry
                            {...item}
                            key={item.id}
                            addUserSubmit={this.addUserSubmit}
                            setCurrentParent={this.setCurrentParent}
                            editUserSubmit={this.editUserSubmit}
                            onDelete={this.onDelete}
                            onClick={this.onClick}
                            toggleKey={toggleKey}
                        />
                    )
                    }
            </Fragment>
        )
    }
}

const mapStateToProps = store => ({
    data: getObjectData(() => store.cls.data.data),
    formData: getObjectData(() => store.cls.formData)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchClsData
}, dispatch);

const GroupWrapper =  connect(mapStateToProps, mapDispatchToProps)(Group);

export default GroupWrapper;
