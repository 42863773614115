import React, {PureComponent, Fragment} from 'react';

//utils
import {returnBootstrapColor} from "../../utils/returnBootstrapColor";
import {getObjectData} from "../../utils/getObjectData";

//components
import Modal from "../_commonComponents/Modal";
import SubjectPageConstructor from "../_commonComponents/pageConstructors/subject/subjectPageConstructor";
import renderSelectWithRadio from "../_commonComponents/form/SelectWithRadion";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSubStatusData} from '../../redux/actions/subject/subjectStatusAction';
import CheckIcon from "mdi-react/CheckIcon";

//constants
import {SUB_CHECK_OBJECT} from '../../config/constants';
const TABLET = 1200;

const Checkbox = ({onChange, checked, id}) => (
    <div style={{width: '100%'}}>
        <label
            htmlFor={`status_${id}`}
            className={"checkbox-btn  checkbox-btn--center"}
        >
            <input
                className="checkbox-btn__checkbox "
                type="checkbox"
                id={`status_${id}`}
                name={'status'}
                onChange={onChange}
                checked={checked}
            />
            <span
                className="checkbox-btn__checkbox-custom"
            >
          <CheckIcon />
        </span>
        </label>
    </div>
);

class InvestigatorPage extends PureComponent {
    state = {
        isTablet: false,
        isPopupOpen: false,
        checked: false,
        id: null,
        num: ''
    };

    componentDidMount() {
        localStorage.removeItem('defaultCenterFilter');
        this.isTablet();
        window.addEventListener('resize', this.isTablet);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isTablet);
    }

    onTogglePopup = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }))
    };

    onOpenPopup = (id, checked, num) => {
        this.setState({
            id,
            checked,
            num
        }, () => {
            this.onTogglePopup();
        })
    };

    onSubmit = async () => {
        const {
            fetchSubStatusData
        } = this.props;

        const data = {
            id: this.state.id,
            checked: !this.state.checked
        };

        await fetchSubStatusData(data, SUB_CHECK_OBJECT);

        this.setState(prevState => ({
            checked: !prevState.checked,
        }), () => {
            this.onTogglePopup();
        })
    };

    isTablet = () => {
        const isTablet = window.innerWidth < TABLET;

        this.setState({
            isTablet,
        })
    };

    renderCell = (info) => {
        const checked = getObjectData(() => info.original.checked);
        const id = getObjectData(() => info.original.id);
        const num = getObjectData(() => info.original.num);
        return (
            <div className="d-flex justify-content-end">
                        <Checkbox
                            name={"status"}
                            color={returnBootstrapColor('success')}
                            onChange={() => this.onOpenPopup(id, checked, num)}
                            checked={checked}
                            id={num}
                        />
            </div>
        )
    };

    get columns() {
        const {
            isTablet
        } = this.state;

        const {
            theme
        } = this.props;

        return [
            {
                Header: '',
                accessor: 'num',
                id: 'num',
                placeholder: 'Пациент',
                filterable: false,
                sortable: false,
            },
            {
                Header: '',
                accessor: 'action',
                id: 'status',
                filterable: false,
                sortable: false,
                Cell: this.renderCell,
                placeholder: 'Статус',
                style: {minWidth: isTablet ? 74 : 100},
                component: renderSelectWithRadio,
                options: [
                    {label: 'Все', title: 'Все', value: '', id: '1'},
                    {label: <span style={{color: returnBootstrapColor('secondary', theme)}}>B</span>, value: [{name: 'checked', value: false}], id: '2'},
                    {label: <span style={{color: returnBootstrapColor('success', theme)}}>B</span>, value: {name: 'checked', value: true}, id: '5'},
                ],
                width: isTablet ? 74 : 100,
                minWidth: 80,
                maxWidth: isTablet ? 74 : 100,
            }
        ];
    }

    render() {
        const {
            isTablet,
            checked,
            isPopupOpen,
            num
        } = this.state;

        return (
            <Fragment>
                <SubjectPageConstructor
                    isResetVisible={false}
                    columns={this.columns.filter(item => Object.keys(item).length !== 0)}
                    status={checked}
                    isTablet={isTablet}
                    maxWidth={"1300px"}
                    className={"subAdminTable_width"}
                    headerClassName={"d-flex d-xl-none"}
                />
                <Modal
                    onSubmit={this.onSubmit}
                    submitText={"Подтверждаю"}
                    title={'Проверка данных дневника пациента'}
                    message={!checked ? `Пожалуйста, подтвердите, что данные дневника пациента №${num} проверены` : `Пожалуйста, подтвердите, что статус пациента №${num} необходимо отменить`}
                    toggle={this.onTogglePopup}
                    isOpen={isPopupOpen}
                    color={"primary"}
                    colored={false}
                    header
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (store) => ({
    checked: getObjectData(() => store.status.data.data.checked),
    checkedRow: getObjectData(() => store.status.data.data),
    theme: store.settings.theme
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubStatusData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvestigatorPage);
