import {HOMEPAGE} from '../config/constants';

export function UrlCreator() {
    const URL = `${HOMEPAGE}/service/`;
    return URL;
}


export function formDataCreator(
    action,
    object = null,
    data = {},
    filter = [],
    skey = null,
    limit = null,
    offset = null,
    order = null,
)
{
    const requestData = {
        ...{
            object,
        },
        ...action && {
            action
        },
        ...{
            filter,
            data,
            limit,
            offset,
            order
        },
        ...skey && {
            skey
        },
    };

    const func = action ? `${object}_${action}` : object;
    let formData = new FormData();
    formData.append('data', JSON.stringify(requestData));
    formData.append('function', func);
    return formData;
}
