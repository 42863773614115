import React, {Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UncontrolledTooltip} from 'reactstrap';
import {NavLink, Link} from 'react-router-dom';
import {HOMEPAGE} from "../../../config/constants";

const HeaderPage = (props) => (
    <div className="topbar">
        <div className="topbar__wrapper">
            <div className="topbar__left">
                <Link to={props.defaultPath}>
                    <h1>Дневник пациента</h1>
                </Link>
            </div>
            <div className="topbar__right">
                <div className="topbar__menu">
                    {
                        props.menu && props.menu.map(item => {
                                if (item.type === 0) {
                                    return (
                                                <NavLink
                                                    className="topbar__navLink"
                                                    key={`${item.id}`}
                                                    to={item.script}
                                                >
                                                    <FontAwesomeIcon icon={item.label}/>
                                                </NavLink>
                                    )
                                } else {
                                    return (
                                        <Fragment key={`${item.id}`}>
                                        <a
                                            className="topbar__navLink"
                                            target={item.type === 2 ? '_blank' : '_self'}
                                            href={`${HOMEPAGE}${item.script}?skey=${props.skey}`}
                                            id={item.name}
                                        >
                                            <FontAwesomeIcon icon={item.label}/>
                                        </a>
                                                <UncontrolledTooltip placement="top" target={item.name}>
                                                    {item.title}
                                                </UncontrolledTooltip>
                                        </Fragment>
                                    )
                                }
                            }
                        )
                    }
                </div>
            </div>
        </div>
    </div>
);

HeaderPage.defaultProps = {
    defaultPath: '/'
};

export default HeaderPage;
