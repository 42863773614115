import * as React from 'react';
import {Card, CardBody,} from 'reactstrap';
import classnames from 'classnames';
import propTypes from 'prop-types';

/**
 * Panel
 * @param color {string}
 * @param title {string}
 * @param subhead {string}
 * @param children
 * @param className {string}
 * @param header {boolean}
 * @param padding {boolean}
 * @return {*}
 * @constructor
 */
const Panel = ({color, title, subhead, children, className, header, padding}) => (
    <Card
        className={classnames('panel', color ? `panel--${color}` : '', className, header ? 'panel_header' : '')}
    >
        <CardBody className="panel__body">
            <div className="panel__title d-flex justify-content-between">
                <h5 className="bold-text">
                    {title}
                </h5>
                <h5 className="subhead">{subhead}</h5>
            </div>
                <div className={classnames("panel__content", padding ? 'panel__content_paddingNone': '')}>
                    {children}
                </div>
        </CardBody>
    </Card>
);

Panel.propTypes = {
    color: propTypes.string,
    title: propTypes.string.isRequired,
    subhead: propTypes.string,
    className: propTypes.string,
    header: propTypes.bool,
    padding: propTypes.bool
};

Panel.defaultProps = {
    color: "primary",
    title: "",
    subhead: "",
    padding: false,
    className: ''
};

export default Panel;
