import * as React from 'react';
import {PureComponent} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import propTypes from 'prop-types';

//redux
import {reduxForm, Field, reset} from 'redux-form';

/**
 * SubmitForm Форма отправки сообщений чата поддержки
 */
class SubmitForm extends PureComponent {
    static propTypes = {
        onSubmit: propTypes.func.isRequired
    };

    /**
     * onSubmit
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            onSubmit
        } = this.props;

        await onSubmit(values);
        this.props.dispatch(reset('chat_application_form'));
    };

    render() {
        const {
            handleSubmit,
        } = this.props;

        return (
            <form className="chat__form" onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                    name={"msg"}
                    component="input"
                    className={"chat__input"}
                    type={"text"}
                    autoFocus={true}
                />
                <button type={"submit"} className={"chat__submitButton"}>
                    <FontAwesomeIcon icon={"play"}/>
                </button>
            </form>
        )
    }
}

export default reduxForm({form: 'chat_application_form', enableReinitialize: true})(SubmitForm);
