import { combineReducers } from 'redux';
import {loginReducer} from "./profile/loginReducer";
import {usersAdminReducer} from './admin/usersAdminReducer';
import {resetPassReducer} from './profile/resetPassReducer';
import {clsReduce} from './cls/clsReduce';
import {subjectReducer} from './subject/subjectReducer';
import {subjectSympReducer} from './subject/subjectSympReducer';
import {adminSubjectReducer} from './admin/adminSubjectReducer';
import {subjectWorseningReduce} from "./subjectSymp/subjectWorseningReduce";
import {usersAdminInvReducer} from './admin/usersInvAdminReducer';
import {subjectStatusReducer} from './subject/subjectStatusReducer';
import {chatReducer} from "./chat/chatReducer";
import {symptPrintReducer} from './pdf/symptPrintReducer';
import {appSettingsReducer} from "./settings/appSettingsReducer";
import {subjectActionRowReducer} from "./subject/subjectActionRowReducer";
import {subjectAntipyreticReducer} from "./subjectSymp/subjectAntipyreticReduce";
import {syncDataReducer} from "./syncDataReducer";
import { reducer as reduxFormReducer } from 'redux-form';

export const rootReducer = combineReducers({
    form: reduxFormReducer,
    profile: loginReducer,
    resetPass: resetPassReducer,
    usersAdmin: usersAdminReducer,
    cls: clsReduce,
    subEvents: subjectReducer,
    subSymptoms: subjectSympReducer,
    adminSubject: adminSubjectReducer,
    worseningData: subjectWorseningReduce,
    invUsers: usersAdminInvReducer,
    status: subjectStatusReducer,
    chat: chatReducer,
    pdf: symptPrintReducer,
    settings: appSettingsReducer,
    subTableSelectedRow: subjectActionRowReducer,
    antipyreticData: subjectAntipyreticReducer,
    syncData: syncDataReducer,
});
