import 'cross-fetch/polyfill';

import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {GET, SYMP_PRINT_OBJECT} from '../../../config/constants';

export const DATA_IS_LOADING = 'DATA_IS_LOADING';
export const DATA_IS_FAILED = 'DATA_IS_FAILED';
export const DATA_IS_SUCCESS = 'DATA_IS_SUCCESS';

function dataIsLoading() {
    return {
        type: DATA_IS_LOADING
    }
}

function dataIsFailed() {
    return {
        type: DATA_IS_FAILED
    }
}

function dataIsSuccess(data) {
    return {
        type: DATA_IS_SUCCESS,
        payload: data
    }
}

export function fetchSymptPrintData(filter = []) {
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(GET, SYMP_PRINT_OBJECT, {}, filter, initialKey);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                throw Error(data.msg)
            }

            dispatch(dataIsSuccess(data))
        }).catch(e => console.error(e))
    }
}
