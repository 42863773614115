import {isNumeric} from './isNumeric';

export const required = value => (value ? undefined : 'Поле не может быть пустым');

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Некорректный email'
    : undefined;

export const phone = (value) =>
   value && value.match(/\d/g).length < 11
    ? 'Некорректный номер телефона'
    : undefined;

export const number = (value) => value && isNumeric(value) ? undefined : 'Значение должно быть числом';

export const minValue = value =>
 value && value < 0 ? 'Значение не может быть отрицательным' : undefined;

export const notZero = value => parseFloat(value) === 0 ? 'Значение не может быть равно 0' : undefined;

export const allowedTemp = value => {
    return (value < 34 || value > 44) ? 'Введите корректную температуру' : undefined
};

