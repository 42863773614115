import * as React from 'react';
import {Fragment} from 'react';
import ModalForm from "../../../_commonComponents/ModalForm";
import {Field} from "redux-form";
import renderInput from "../../../_commonComponents/form/InputWithValidation";
import renderPickerField from "../../../_commonComponents/form/ColorPicker";

const EditForm = ({isPopupOpen, togglePopup, initialValues, onSubmit, form}) => {
    return <ModalForm
        isOpen={isPopupOpen}
        toggle={togglePopup}
        onSubmit={onSubmit}
        form={form}
        initialValues={initialValues}
        title={"Изменить раздел"}
        header
        color={"primary"}
    >
        <Fragment>
            <div className={"d-flex"}>
                <div className="pr-2 flex-grow-1">
                    <Field
                        name="id"
                        component={renderInput}
                        label="Sys Id"
                        readOnly
                        disabled
                    />
                </div>
                <div className="flex-grow-1">
                    <Field
                        name="vers"
                        component={renderInput}
                        label="Vers"
                        readOnly
                        disabled
                    />
                </div>
            </div>
            <Field
                name="name"
                component={renderInput}
                label="Код"
            />
            <Field
                name="title"
                component={renderInput}
                label="Название"
            />
            <Field
                name="dsc"
                component={renderInput}
                label="Описание"
            />
            <Field
                name="color"
                component={renderPickerField}
                color={initialValues && initialValues.color}
            />
        </Fragment>
    </ModalForm>

}
;

export default EditForm;
