import * as React from 'react';
import AdminUsersConstructor from '../_commonComponents/pageConstructors/admin/AdminUsesrConstructor';
import propTypes from 'prop-types';

const ROLE = "ADMIN";
const FILTER = [{name: "role_name", value: ROLE}];

/**
 * AdminUsers - Администраторы '/admin/users_admin'
 * @constructor
 */
const AdminUsers = ({history}) => (
    <AdminUsersConstructor
        initFilter={FILTER}
        title={"Администраторы"}
        titleIcon={"user-cog"}
        roles={ROLE}
        history={history}
    />
);

AdminUsers.propTypes = {
  history: propTypes.object.isRequired
};

export default AdminUsers;
