import * as React from 'react';
import {PureComponent} from 'react';

//redux
import {connect} from 'react-redux';
import {PHONE_MASK} from "../../../config/constants";
import Modal from "../../_commonComponents/ModalForm";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import {required} from "../../../utils/formValidation";
import renderSelect from "../../_commonComponents/form/Select";
import {Field} from 'redux-form';
import {getObjectData} from "../../../utils/getObjectData";

class EditUserForm extends PureComponent {
    state = {
        options: []
    };

    render() {
        const {
            toggle,
            isOpen,
            form,
            onSubmit,
            initialValues,
            data
        } = this.props;

        return (
            <Modal
                toggle={toggle}
                isOpen={isOpen}
                title={'Новый пользователь'}
                form={form}
                header
                color={"primary"}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                <div className="form__from-fields-wrapper">
                    <div className="pr-2 flex-grow-1">
                        <Field
                            component={renderInput}
                            name={'id'}
                            type="text"
                            label={'Sys Id'}
                            readOnly
                            disabled
                        />
                    </div>
                    <div className={"flex-grow-1"}>
                        <Field
                            component={renderInput}
                            name={'vers'}
                            type="text"
                            label={'Vers'}
                            readOnly
                            disabled
                        />
                    </div>
                </div>
                <Field
                    component={renderInput}
                    name={'name'}
                    type="text"
                    label={'Имя пользователя из ИГС'}
                />
                <Field
                    component={renderInput}
                    name={'title'}
                    type="text"
                    label={'ФИО'}
                    validate={[required]}
                />
                <Field
                    component={renderInput}
                    name={'phone'}
                    type="text"
                    label={'Номер телефона'}
                    mask={PHONE_MASK}
                    validate={[required]}
                />
                <Field
                    component={renderSelect}
                    name={'center'}
                    options={data}
                    //optionLabel={initialValues && initialValues.center_title}
                    type="text"
                    label={'Центр'}
                    validate={[required]}
                />
            </Modal>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.cls.data.data.map(item => ({value: item.name, label: item.name + ' ' + item.title})))
});

export default connect(mapStateToProps)(EditUserForm);
