import * as React from 'react';
import classnames from 'classnames';
import Select from 'react-select';

const RenderSelect = ({name, options, isMulti = false, placeholder, value, onChange, label}) => {
    function onHandleChange(selectedVal) {
        onChange(selectedVal)
    }

    return (
        <div className={classnames("form__form-group")}>
            {label && <span className={classnames("form__form-group-label")}>{label}</span>}
            <div className={classnames("form__form-group-input-wrap")}>
                <div className={"form__select-border"}>
                    <Select
                        isMulti={isMulti}
                        name={name}
                        options={options}
                        onChange={onHandleChange}
                        value={value}
                        classNamePrefix="Select"
                        className="form__form-group-select"
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </div>
    )
};

RenderSelect.defaultProps = {
    isMulti: false,
    placeholder: ''
};

const renderSelect = (props) => (
    <RenderSelect
        {...props.input}
        options={props.options}
        optionLabel={props.optionLabel}
        placeholder={props.placeholder}
        label={props.label}
    />
);

export default renderSelect;
