import * as React from 'react';
import {PureComponent} from 'react';
import {Button} from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PhoneIcon from 'mdi-react/MobilePhoneAndroidIcon';

//utils
import {getObjectData} from "../../../utils/getObjectData";

//redux
import {bindActionCreators} from 'redux';
import {fetchLoginData} from "../../../redux/actions/profile/loginAction";
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';

//components
import renderInput from '../../_commonComponents/form/InputWithValidation';

//constants
import {PHONE_MASK, MSG_ERR} from '../../../config/constants';

class LoginForm extends PureComponent {
    state = {
        isError: false
    };

    onSubmit = async (values) => {
        const {
            fetchLoginData,
        } = this.props;

        if (!values.name || !values.pass) {
            throw new SubmissionError({
                name: !values.name ? 'Поле не может быть пустым' : '',
                pass: !values.pass ? 'Поле не может быть пустым' : ''
            })
        } else {
            return fetchLoginData(values, true).then(
                () => {
                    this.props.history.push('/');
                },
                () => {
                    throw new SubmissionError({
                        name: MSG_ERR,
                        pass: MSG_ERR
                    })
                }
            );
        }
    };

    render() {
        const {
            onToggle,
            handleSubmit,
            fieldMsg,
        } = this.props;

        return (
            <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                    name="name"
                    component={renderInput}
                    mask={PHONE_MASK}
                    fieldMsg={fieldMsg}
                    autoComplete={"username"}
                    placeholder=""
                    icon={<PhoneIcon/>}
                    label={'Номер мобильного телефона:'}
                    type="tel"
                />
                <Field
                    name="pass"
                    component={renderInput}
                    fieldMsg={fieldMsg}
                    type={'password'}
                    autoComplete={"current-password"}
                    icon={<KeyVariantIcon/>}
                    label={'ПИН-Код:'}
                />
                <Button type="submit" color="primary" className="w-100 m-0 mt-2">Вход</Button>
                <div className="account__forgot-password">
                    <div className="account__forgot-passLink" onClick={onToggle}>Восстановить ПИН-код</div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (store) => ({
    defaultPage: getObjectData(() => store.profile.data.data[0].default_view_path),
    isError: store.profile.isError,
    data: store.profile.data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLoginData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'login_form',
})(LoginForm));
