import * as React from 'react';
import {PureComponent} from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {isNumeric} from "../../../../utils/isNumeric";

const PAGE_SIZE = 10;

/**
 * Пагинация для таблицы
 */
export default class TablePagination extends PureComponent{
    /**
     * @param pagesCount {Number}
     * @param currentPage {Number}
     */
    static getPagesArray(pagesCount, currentPage) {
        if (!isNumeric(pagesCount)) {
            return [];
        }
        const completePagesArr = new Array(pagesCount).fill('').map((i, index) => index + 1);
        let currentPageIndex = completePagesArr.findIndex(page => page === currentPage);
        if (currentPageIndex === -1) {
            return [];
        }

        currentPageIndex = Math.max(0, currentPageIndex + 1);

        if (pagesCount <= PAGE_SIZE) {
            return completePagesArr;
        }

        let pages = [completePagesArr[currentPageIndex]];
        let repeat = true;
        let iterations = 1;
        while (repeat === true) {
            let leftPage = completePagesArr[currentPageIndex - iterations];
            let rightPage = completePagesArr[currentPageIndex + iterations];
            if (
                (!leftPage && !rightPage)
                || pages.length >= PAGE_SIZE
            ) {
                repeat = false
            }

            if (leftPage && pages.length < PAGE_SIZE) {
                pages.unshift(leftPage);
            }

            if (rightPage && pages.length < PAGE_SIZE) {
                pages.push(rightPage);
            }

            iterations += 1;
        }

        return pages;
    };

    static getDerivedStateFromProps(props, state) {
        return {
            pagesArray: TablePagination.getPagesArray(props.pages, Math.max(props.page, 1))
        };
    }

    state = {
        pagesArray: [],
    };

    /**
     * @param page {Number}
     */
    getSafePage(page) {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }
        return Math.min(Math.max(page, 0), this.props.pages);
    }

    /**
     * @param page {Number}
     */
    changePage(page) {
        page = this.getSafePage(page);
        if (this.props.page === page) return;

        this.props.onPageChange(page);
    }

    render() {
        const {
            page,
            pages,
            total,
            defaultPageSize,
            limit
        } = this.props;

        const {
            pagesArray
        } = this.state;

        const isFirst = page === 0;
        const isLast = page === pages - 1;

        return (
            <div className="d-flex flex-wrap align-items-center">
                <Pagination className="pagination pb-2">
                    <PaginationItem
                        className={classNames("pagination__item", isFirst ? 'disabled' : '')}
                        disabled={isFirst}
                    >
                        <div className="-previous">
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => {
                                    this.changePage(page - 1);
                                }}
                            >
                                <FontAwesomeIcon color={"#999999"} icon="chevron-left"/>
                            </PaginationLink>
                        </div>
                    </PaginationItem>
                    {pagesArray && pagesArray.length > 1 && pagesArray
                        .map((currentPage) =>
                            (<PaginationItem className="pagination__item" key={currentPage}
                                             active={(currentPage - 1) === page}>
                                <PaginationLink className="pagination__link" type="button"
                                                onClick={() => this.changePage(currentPage - 1)}>
                                    {currentPage}
                                </PaginationLink>
                            </PaginationItem>))}
                    <PaginationItem
                        className={classNames("pagination__item", isLast ? 'disabled' : '')}
                        disabled={isLast}
                    >
                        <div className="-next">
                            <PaginationLink
                                className="pagination__link pagination__link--arrow"
                                type="button"
                                onClick={() => {
                                    this.changePage(page + 1);
                                }}
                            >
                                <FontAwesomeIcon color={"#999999"} icon="chevron-right"/>
                            </PaginationLink>
                        </div>
                    </PaginationItem>
                </Pagination>
                {total > 0 && <div className="pagination__text">строки
                    с {page * limit + 1} по {page * limit + defaultPageSize} из {total}</div>}
            </div>
        );
    }
}
