import * as React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "./buttons/IconButton";
import classnames from 'classnames';
import propTypes from 'prop-types';

/**
 * CardHeader
 * @param title {string}
 * @param titleIcon {string}
 * @param actionIcon {string}
 * @param action {Function}
 * @param reset {Function}
 * @param history {Object}
 * @param isReloadVisible {boolean}
 * @constructor
 */
const CardHeader = ({title, titleIcon, actionIcon = null, syncIcon = null, syncAction = ()=>{}, action, reset, history, isResetVisible=true, className='', headerTotal = null}) => (
    <Card className={className}>
        <CardBody className="p-2">
            <Row>
                <Col xs={6} className="d-flex align-items-center card__header">
                    <FontAwesomeIcon icon={titleIcon}/>
                    <div className="d-flex align-items-center">
                        <h5>{title}</h5>
                        {!!headerTotal && <div className={"card__headerTotal"}>{headerTotal}</div>}
                    </div>
                </Col>
                <Col xs={6} className="d-flex align-items-center justify-content-end">
                    {syncIcon &&
                        <IconButton
                            className={"mr-3"}
                            icon={syncIcon}
                            color={"primary"}
                            onClick={syncAction}
                        />
                    }
                    {actionIcon && <IconButton
                        className={classnames(isResetVisible ? "mr-3": '')}
                        icon={actionIcon}
                        color={"primary"}
                        onClick={action}
                    />}
                    {isResetVisible && <IconButton
                        className="mr-3"
                        icon="sync-alt"
                        color={"primary"}
                        onClick={reset}
                    />}
                    {history && <IconButton
                        icon={"home"}
                        color={"secondary"}
                        onClick={() => history.push('/admin')}
                    />}
                </Col>
            </Row>
        </CardBody>
    </Card>
);

CardHeader.propTypes = {
    title: propTypes.string.isRequired,
    titleIcon: propTypes.string.isRequired,
    actionIcon: propTypes.string,
    action: propTypes.func,
    reset: propTypes.func,
    history: propTypes.object,
    isResetVisible: propTypes.bool,
    className: propTypes.string
};

export default CardHeader;
