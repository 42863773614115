import * as React from 'react';
import {PureComponent} from 'react';
import { Container, Row } from 'reactstrap';
import Card from './components/Card';

import {
    USERS_ADMIN_PATH,
    CLS_ADMIN_PATH,
    USERS_MON_PATH,
    USERS_INV_PAT,
    CENTERS_PATH,
    SUBJECT_ADMIN_PATH,
    ADMIN_CHAT_PATH
} from "../../config/constants";

/**
 * Admin '/admin' домашняя страница администратора
 */
class Admin extends PureComponent{
    /**
     * @param link {String}
     */
    onClick = (link = '/') => {
      this.props.history.push(link)
    };

    render() {
        return (
            <Container className="pt-5">
                <Row>
                    <Card icon={"user-cog"} title={"Администраторы"} onClick={this.onClick} path={USERS_ADMIN_PATH}/>
                    <Card icon={"user-md"} title={"Исследователи"} onClick={this.onClick} path={USERS_INV_PAT}/>
                    <Card icon={"user-astronaut"} title={"Специалисты по КИ"} onClick={this.onClick} path={USERS_MON_PATH}/>
                    <Card icon={"users"} title={"Пациенты"} onClick={this.onClick} path={SUBJECT_ADMIN_PATH} />
                    <Card icon={"envelope"} title={"Сообщения"} onClick={this.onClick} path={ADMIN_CHAT_PATH}/>
                    <Card icon={"book"} title={"Классификатор"} onClick={this.onClick} path={CLS_ADMIN_PATH}/>
                    <Card icon={"hospital"} title={"Центры"} onClick={this.onClick} path={CENTERS_PATH}/>
                </Row>
            </Container>
        )
    }
}

export default Admin;
