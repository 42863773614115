import * as React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';

const HorizontalInputValidationField = ({
                                                                      value,
                                                                      onChange,
                                                                      mask,
                                                                      placeholder,
                                                                      type,
                                                                      readOnly,
                                                                      label,
                                                                      meta: {touched, error},
                                                                      icon,
                                                                      disabled,
                                                                      isEmptyValue,
                                                                      style
                                                                  }) =>
    <div style={style} className={"form__form-group d-flex align-items-center"}>
        <span
            className={classnames("form__form-group-label mr-4", isEmptyValue ? (value ? "" : "form__form-group-label_red") : "")}>{label}</span>
        <div className="d-flex">
            <div className={classnames("form__form-group-input-wrap")}>
                <InputMask
                    mask={mask}
                    onChange={onChange}
                    maskChar={null}
                    value={value}
                    readOnly={readOnly}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {touched && error && <span className="form__form-group-error">{error}</span>}
            </div>
            {icon && <div className="form__form-group-icon">
                {icon}
            </div>}
        </div>
    </div>;

HorizontalInputValidationField.defaultProps = {
    placeholder: '',
    isEmptyValue: false,
    meta: null,
    type: 'text',
    readOnly: false,
    mask: null,
    disabled: false,
    icon: null
};

const renderHorizontalInput = (props) =>
    <HorizontalInputValidationField
        {...props.input}
        {...props}
    />;

export default renderHorizontalInput;
