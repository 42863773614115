import Login from '../containers/login/index';
import Admin from '../containers/admin/index';
import AdminUsers from '../containers/adminUsers/index';
import MonitorUsers from '../containers/monitorUsers/index';
import Cls from '../containers/cls/index';
import InvestigatorUsers from '../containers/investigatorUsers/index';
import Centers from '../containers/centers/index';
import Subject from '../containers/subject/index';
import SubjectSymp from '../containers/subjectSymp/index';
import AdminUsersSub from '../containers/adminSub/index';
import SubAntipyretic from '../containers/subAntipyretic/index';
import SubWorsering from '../containers/subWorsening/index';
import ChatSubject from '../containers/subjectChat/index';
import MonitorPage from '../containers/monitor/index';
import InvestigatorPage from '../containers/investigator/index';
import AdminChat from '../containers/adminChat/index';

export const COMPONENTS = {
    login: Login,
    admin: Admin,
    users_admin: AdminUsers,
    users_mon: MonitorUsers,
    users_inv: InvestigatorUsers,
    centers: Centers,
    cls: Cls,
    subject_home: Subject,
    subject_symp: SubjectSymp,
    users_sub: AdminUsersSub,
    subject_antipyretic: SubAntipyretic,
    subject_worsening: SubWorsering,
    subject_help_chat: ChatSubject,
    mon_home: MonitorPage,
    inv_home: InvestigatorPage,
    admin_help_chat: AdminChat,
};
