import "cross-fetch/polyfill";

import {getObjectData} from "../../../utils/getObjectData";
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {USERS_OBJECT, LIMIT} from "../../../config/constants";
import ShowNotification from "../../../utils/showNotification";

export const USERS_ADMIN_DATA_L = 'USERS_ADMIN_DATA_L'; //loading
export const USER_ADMIN_DATA_F = 'USER_ADMIN_DATA_F'; //error
export const USER_ADMIN_DATA_S = 'USER_ADMIN_DATA_S'; //success
export const USER_ADMIN_DATA_E = 'USER_ADMIN_DATA_E'; //edited;

function dataIsLoading() {
    return {
        type: USERS_ADMIN_DATA_L
    }
}

function dataIsFailed(message) {
    return {
        type: USER_ADMIN_DATA_F,
        payload: message
    }
}

function dataIsLoadedSuccess(data) {
    return {
        type: USER_ADMIN_DATA_S,
        payload: data
    }
}

function dataIsEdited(data) {
    return {
        type: USER_ADMIN_DATA_E,
        payload: data
    }
}

export function fetchUsersAdminData(
    action,
    filter,
    data,
    isInitial,
    order,
    offset
    )
{
    return (dispatch) => {
        let initialKey = localStorage.token;
        const notification = Object.create(ShowNotification);
        const URL = UrlCreator();
        const requestData = formDataCreator(action, USERS_OBJECT, data, filter, initialKey, LIMIT, offset, order);

        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then(response => {
            if(response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json()
        }).then(data => {
            // eslint-disable-next-line
            if(data.status != 0){
                dispatch(dataIsFailed(data.msg));
                notification.show(data.msg);
                return;
            }

            isInitial
                ? dispatch(dataIsLoadedSuccess(data))
                : dispatch(dataIsEdited(getObjectData(() => data.data)))
        }).catch(e => console.error(e))
    }
}
