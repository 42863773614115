import * as React from 'react';
import Modal from "../../../../../ModalForm";
import {Field} from "redux-form";
import renderInput from "../../../../../form/InputWithValidation";
import {PHONE_MASK} from "../../../../../../../config/constants";
import {required} from "../../../../../../../utils/formValidation";

const AddNewUserForm = ({isOpen, toggle, onSubmit, form}) => (
    <Modal
        toggle={toggle}
        isOpen={isOpen}
        title={'Новый пользователь'}
        form={form}
        header
        color={"primary"}
        onSubmit={onSubmit}
    >
        <Field
            component={renderInput}
            name={'name'}
            type="text"
            label={'Имя'}
        />
        <Field
            component={renderInput}
            name={'title'}
            type="text"
            label={'ФИО'}
            validate={[required]}
        />
        <Field
            component={renderInput}
            name={'phone'}
            type="text"
            label={'Номер телефона'}
            mask={PHONE_MASK}
            validate={[required]}
        />
    </Modal>
);

export default AddNewUserForm;
