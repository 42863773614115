import React from 'react';
import {Field} from "redux-form";
import renderRadio from "../../../_commonComponents/form/RadioGroup";
import propTypes from 'prop-types';

const RadioField = ({isValueEmpty, name, label, readOnly, style}) => {
    return(
        <Field
            name={name}
            component={renderRadio}
            label={label}
            horizontal
            isEmptyValue={isValueEmpty(name)}
            style={style}
            options={
                [
                    {
                        id: `${name}_1`,
                        value: 0,
                        label: 'Нет'
                    },
                    {
                        id: `${name}_2`,
                        value: 1,
                        label: 'Легкая'
                    },
                    {
                        id: `${name}_3`,
                        value: 2,
                        label: 'Средняя'
                    },
                    {
                        id: `${name}_4`,
                        value: 3,
                        label: 'Тяжелая'
                    }
                ]}
            readOnly={readOnly}
        />
    )
};

RadioField.propTypes = {
    name: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    isValueEmpty: propTypes.func.isRequired,
    readOnly: propTypes.bool.isRequired
};

export default RadioField;
