import * as React from 'react';
import {PureComponent} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container} from "reactstrap";
import propTypes from 'prop-types';

//constants
import {ADD, DEL, GET, SET, LIMIT} from "../../../../../config/constants";

//utils
import {returnBootstrapColor} from "../../../../../utils/returnBootstrapColor";
import {getObjectData} from "../../../../../utils/getObjectData";

//components
import Table from "./components/Table";
import Modal from "../../../Modal";

//redux
import {connect} from 'react-redux';

/**
 * AdminPageConstructor. Конструктор для создания страниц админки: Администраторы, Специалисты по КИ, Исследователи, Центры
 */
class AdminPageConstructor extends PureComponent {
    static propTypes = {
        data: propTypes.array,
        total: propTypes.number,
        formData: propTypes.object,
        isMobile: propTypes.bool.isRequired,
        isDesktop: propTypes.bool,
        fetchData: propTypes.func.isRequired,
        initFilter: propTypes.array.isRequired,
        renderColumns: propTypes.func.isRequired,
        title: propTypes.string.isRequired,
        titleIcon: propTypes.string.isRequired,
        parent: propTypes.number,
        actionIcon: propTypes.string,
        modalTitle: propTypes.string,
        limit: propTypes.number.isRequired,
        history: propTypes.object.isRequired,
        onFilter: propTypes.func.isRequired,
        roles: propTypes.string,
    };

    static defaultProps = {
        parent: null,
        roles: null,
        modalTitle: 'пользователя',
        actionIcon: 'user-plus',
        limit: LIMIT,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPopupOpen: false,
            isEditPopupOpen: false,
            data: [],
            selectedItem: null,
            isDeletePopupOpen: false,
            total: 10,
            order: null,
            offset: 0,
            columns: []
        }
    }

    componentDidMount() {
        this.getInitialData();

        try {
            this.renderColumns()
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * @param prevProps {Object}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.isMobile !== this.props.isMobile) {
            this.renderColumns()
        } else if (prevProps.isDesktop !== this.props.isDesktop) {
            this.renderColumns();
        }
    }

    /**
     * renderColumns
     */
    renderColumns = () => {
        const {
            renderColumns
        } = this.props;

        const columns = renderColumns(this.renderCell).filter(item => Object.keys(item).length > 0);

        this.setState({
            columns
        })
    };

    /**
     * getInitialData
     * @param filter {Array}
     * @param data {Object}
     * @param order {String}
     * @param offset {Number}
     */
    getInitialData = async (
        filter = this.props.initFilter,
        data = {},
        order = null,
        offset = null,
        ) => {
        const {
            fetchData
        } = this.props;

        await fetchData(GET, filter, data, true, order, offset);

        this.setState({
            data: this.props.data,
            total: this.props.total,
            filter,
            order,
            offset,
        })
    };

    /**
     * addNewUser
     * @param data {Object}
     */
    addNewUser = async (data) => {
        const {
            fetchData,
            roles,
        } = this.props;

        const center = data.center ? {center: data.center.value} : null;
        const role = roles ? {role: roles} : null;
        const parent = this.props.parent ? {parent: this.props.parent} : null;

        await fetchData(ADD, [], {...data, ...center, ...role, ...parent}, false, null, null);

        this.setState({
            selectedItem: this.props.formData ? this.props.formData : getObjectData(() => this.state.data[0])
        });

        this.getInitialData(this.props.initFilter, {}, this.state.order, this.state.offset);
    };

    /**
     * editUserForm
     * @param values {Object}
     */
    editUserForm = async (values) => {
        const {
            fetchData
        } = this.props;

        let updateData = [...this.state.data];

        const phone = values.phone ? {phone: values.phone} : null;
        const email = values.email ? {email: values.email} : null;
        const center = values.center ? {center: values.center.value} : null;
        const tag = values.tag ? {tag: values.tag} : null; //gmt

        const requestData = {
            id: values.id,
                vers: values.vers,
            name: values.name,
            title: values.title,
            ...phone,
            ...email,
            ...center,
            ...tag
        };

        await fetchData(SET, [], requestData, false);

        const {
            formData
        } = this.props;

        const formId = getObjectData(() => formData.id);
        const index = updateData.findIndex(({id}) => id === formId);
        index !== -1 && updateData.splice(index, 1, formData);

        this.setState({
            data: updateData,
        })
    };

    /**
     * togglePopup
     */
    togglePopup = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }))
    };

    /**
     * toggleEditPopup
     */
    toggleEditPopup = () => {
        this.setState(prevState => ({
            isEditPopupOpen: !prevState.isEditPopupOpen
        }))
    };

    /**
     * openEditPopup
     * @param selectedItem {Object}
     */
    openEditPopup = (selectedItem) => {
        const center = selectedItem.center ? {center: {value: selectedItem.center, label: selectedItem.center_title}} : null;
        this.setState({
            selectedItem: {...selectedItem, ...center}
        }, () => {
            this.toggleEditPopup();
        })
    };

    /**
     * openDeletePopup
     * @param selectedItem {Object}
     */
    openDeletePopup = (selectedItem) => {
        this.setState({
            selectedItem
        }, () => {
            this.toggleDeletePopup()
        })
    };

    /**
     * toggleDeletePopup
     */
    toggleDeletePopup = () => {
        this.setState(prevState => ({
            isDeletePopupOpen: !prevState.isDeletePopupOpen
        }))
    };

    /**
     * onDelete
     */
    onDelete = async () => {
        const {
            fetchData
        } = this.props;

        const {
            selectedItem,
        } = this.state;

        await fetchData(DEL, [], {id: selectedItem.id}, false);
        this.getInitialData(this.state.filter, {}, this.state.order, this.state.offset);
        this.toggleDeletePopup();
    };

    /**
     * @param e {event}
     * @param selectedItem {Object}
     */
    onRowClick = (e, selectedItem) => {
        const center = selectedItem.center ? {center: {value: selectedItem.center, label: selectedItem.center_title}} : null;

        this.setState({
            selectedItem: {...selectedItem, ...center}
        })
    };

    /**
     * @param info {Object}
     */
    renderCell = (info) => {
        const {
            theme
        } = this.props;

        return (
            <div className="d-flex justify-content-end">
                <div style={{cursor: 'pointer'}} className="mr-4"
                     onClick={() => this.openEditPopup(info.original)}>
                    <FontAwesomeIcon
                        color={returnBootstrapColor('primary', theme)}
                        icon={"pencil-alt"}
                    />
                </div>
                <div style={{cursor: 'pointer'}}
                     onClick={() => this.openDeletePopup(info.original)}>
                    <FontAwesomeIcon
                        color={returnBootstrapColor('danger', theme)}
                        icon={"trash"}
                    />
                </div>
            </div>
        )
    };

    resetOrder = () => {
      this.setState({
          order: null,
      })
    };

    render() {
        const {
            isPopupOpen,
            isEditPopupOpen,
            data,
            selectedItem,
            isDeletePopupOpen,
            total,
            filter,
            order,
            columns,
            offset
        } = this.state;

        const {
            AddForm,
            EditForm,
            title,
            titleIcon,
            roles,
            actionIcon,
            modalTitle,
            limit,
            history,
            onFilter,
        } = this.props;

        return (
            <Container style={{maxWidth: 1400}} className={"mt-2"}>
                <Table
                    togglePopup={this.togglePopup}
                    data={data}
                    columns={columns}
                    onRowClick={this.onRowClick}
                    selectedItem={selectedItem}
                    onFilter={onFilter}
                    total={total}
                    filter={filter}
                    order={order}
                    onFetchData={this.getInitialData}
                    title={title}
                    actionIcon={actionIcon}
                    titleIcon={titleIcon}
                    limit={limit}
                    history={history}
                    offset={offset}
                    resetOrder={this.resetOrder}
                />
                <AddForm
                    isOpen={isPopupOpen}
                    toggle={this.togglePopup}
                    onSubmit={this.addNewUser}
                    form={`add_new_user_to_${roles}_form`}
                />
                <EditForm
                    isOpen={isEditPopupOpen}
                    toggle={this.toggleEditPopup}
                    onSubmit={this.editUserForm}
                    form={`edit_new_user_to_${roles}_form`}
                    initialValues={selectedItem}
                />
                <Modal
                    onSubmit={this.onDelete}
                    title={`Удалить ${modalTitle}`}
                    message={`Вы уверенны, что хотите удалить ${modalTitle} ${selectedItem && selectedItem.name}?`}
                    toggle={this.toggleDeletePopup}
                    isOpen={isDeletePopupOpen}
                    color={"danger"}
                    colored={false}
                    header
                />
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    theme: store.settings.theme
});

export default connect(mapStateToProps)(AdminPageConstructor);
