import React, {PureComponent} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import CardHeader from "../../../../CardHeader";
import Table from '../../../../table/index';

export default class AdminTable extends PureComponent {
    static defaultProps = {
        actionIcon: 'user-plus',
        title: '',
        titleIcon: 'user',
    };

    state = {
        offset: 0,
        page: 0,
        isSorted: false,
        sortedName: null,
        isLoading: false,
        order: null,
        renderKey: false,
        filter: [],
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            onFetchData
        } = this.props;

        if (prevState.offset !== this.state.offset) {
            onFetchData(this.props.filter, {}, this.props.order, this.state.offset);
        }
    }

    onFilter = async (values) => {
        const {
            onFetchData,
            onFilter
        } = this.props;

        const filter = onFilter(values);

        onFetchData(filter);
    };

    onReset = async () => {
        const {
            resetOrder
        } = this.props;

        await this.onFilter([]);
        await resetOrder();

        this.setState(prevState => ({
            isSorted: false,
            sortedName: null,
            isLoading: false,
            renderKey: !prevState.renderKey,
            page: 0
        }))
    };

    /**
     * @param state
     */
    onFetchData = (state) => {
        const {
            limit
        } = this.props;

        const offset = state.page * limit;
        const page = state.page;

        this.setState({
            offset,
            page
        })
    };

    onSort = async (sortedName) => {
        const {
            onFetchData,
            filter,
        } = this.props;

        const isSorted = sortedName ? !this.state.isSorted : false;
        const order = isSorted ? `{${sortedName} DESC}` : `{${sortedName}}`;

        await onFetchData(filter, {}, order);
        this.setState({
            sortedName,
            isSorted,
            page: 0
        })
    };

    render() {
        const {
            data,
            title,
            titleIcon,
            actionIcon,
            limit,
            history,
            columns,
            selectedItem,
            onRowClick,
            total,
            order,
            togglePopup,
        } = this.props;

        const {
            renderKey,
            filter,
            page,
            isSorted,
            sortedName,
            isLoading
        } = this.state;

        return (
            <Row>
                <Col xs={12}>
                    <CardHeader
                        title={title}
                        titleIcon={titleIcon}
                        actionIcon={actionIcon}
                        action={togglePopup}
                        reset={this.onReset}
                        history={history}
                    />
                </Col>
                <Col xs={12} className={"tableColumn"}>
                    <Card className={"h-100"}>
                        <CardBody className={"d-flex flex-column"}>
                            <Table
                                renderKey={renderKey}
                                data={data}
                                columns={columns}
                                filter={filter}
                                selected={selectedItem}
                                onFetchData={this.onFetchData}
                                total={total}
                                order={order}
                                limit={limit}
                                onRowClick={onRowClick}
                                history={history}
                                onFilter={this.onFilter}
                                page={page}
                                onSort={this.onSort}
                                isSorted={isSorted}
                                sortedName={sortedName}
                                isLoading={isLoading}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
