import React, {PureComponent} from 'react';
import {Container, Row, Col} from 'reactstrap';

//components
import Chat from '../_commonComponents/chat/index';
import Table from './components/Table';

//utils
import {getObjectData} from "../../utils/getObjectData";

//constants
import {
    ADD,
    ADMIN_CHAT_OBJECT_ADD,
    GET,
    ADMIN_CHAT_GET_MSG_OBJECT,
    INTERVAL,
    MOBILE
} from "../../config/constants";

//redux
import {bindActionCreators} from "redux";
import {fetchChatData} from "../../redux/actions/chat/chatAction";
import {connect} from "react-redux";

/**
 * AdminChat Чат поддержки пациентов. Часть администратора
 */
class AdminChat extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            id: null,
            isMobile: false,
            isVisible: false,
            selected: null,
            isTableUpdate: false,
        };
    }

    componentDidMount() {
        this.isMobile();
        this.updateChat = setInterval(() => {this.updateData()}, INTERVAL);
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
        clearInterval(this.updateChat);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.id !== this.state.id) {
            this.getChatData(this.state.id)
        }
    }

    updateData = () => {
        this.getChatData(this.state.id);
        this.setState(prevState => ({
            isTableUpdate: !prevState.isTableUpdate
        }))
    };

    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;
        const height = window.innerHeight * 0.01;
        this.el.style.setProperty('--vh', `${height}px`);

        this.setState({
            isMobile,
        })
    };

    /**
     * onSubmit
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchChatData
        } = this.props;

        const {
            id
        } = this.state;

        const requestData = {
            ...values,
            ...{
                recipient: id
            }
        };

        await fetchChatData(ADD, requestData, {}, ADMIN_CHAT_OBJECT_ADD);

        this.setState({
            data: this.props.data
        });

        this.getChatData();
    };

    /**
     * getTableSelectedRow
     * @param selected {object}
     */
    getTableSelectedRow = (selected) => {
        this.setState({
            selected
        })
    };

    /**
     * getChatData
     * @return {Promise<void>}
     */
    getChatData = async () => {
        const {
            fetchChatData
        } = this.props;

        const {
            id
        } = this.state;

        const filter = [{name: "usr", value: id}];

        await fetchChatData(GET, {}, filter, ADMIN_CHAT_GET_MSG_OBJECT);

        this.setState({
            data: this.props.data ? this.props.data : []
        })
    };

    /**
     * selectTableId
     * @param id {number}
     */
    selectTableId = (id) => {
        this.setState({
            id,
        })
    };

    /**
     * toggleChatVisibility
     */
    toggleChatVisibility = () => {
        const {
            isMobile
        } = this.state;

        const isVisible = !isMobile ? true : !this.state.isVisible;

        this.setState({
            isVisible
        })
    };

    /**
     * getter isTableVisible
     * @return {boolean}
     */
    get isTableVisible() {
        const {
            isVisible,
            isMobile
        } = this.state;

        return isMobile ? !isVisible : true;
    }

    /**
     * getter isChatVisible
     * @return {boolean}
     */
    get isChatVisible() {
        const {
            isVisible,
            isMobile
        } = this.state;

        return isMobile ? isVisible : true;
    }

    render() {
        const {
            history
        } = this.props;

        const {
            data,
            selected,
            isTableUpdate,
        } = this.state;

        return (
            <Container>
                <div ref={el => {this.el = el}} className={"adminChat d-flex"}>
                    <Row className={"w-100"}>
                        {this.isTableVisible && <Col className={"adminChat__tableWrapper"}>
                            <Table
                                onRowClick={this.selectTableId}
                                toggleVisibility={this.toggleChatVisibility}
                                getTableSelectedRow={this.getTableSelectedRow}
                                selected={selected}
                                interval={INTERVAL}
                                isTableUpdate={isTableUpdate}
                            />
                        </Col>}
                        { this.isChatVisible && <Col className={"flex-grow-1"}>
                            {data && <Chat
                                data={data}
                                key={data.length}
                                history={history}
                                onSubmit={this.onSubmit}
                                toggleVisibility={this.toggleChatVisibility}
                                isBackIconVisible
                            />}
                        </Col>}
                    </Row>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.chat.data),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchChatData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminChat);
