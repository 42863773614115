import * as React from 'react';
import { MoonLoader} from 'react-spinners';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import {returnBootstrapColor} from "../../utils/returnBootstrapColor";

const Loading = ({theme}) => (
    <div className="load">
        <MoonLoader
            color={returnBootstrapColor('primary', theme)}
        />
    </div>
);

Loading.defaultProps = {
  theme: propTypes.string
};

const mapStateToProps = (store) => ({
    theme: store.settings.theme
});

export default connect(mapStateToProps)(Loading);
