import React, {PureComponent} from 'react';
import {Card, CardBody, Col} from "reactstrap";
import propTypes from 'prop-types';

//components
import Table from "../../../../table";
import Loading from '../../../../Loading';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchAdminSubjectData} from "../../../../../../redux/actions/admin/adminSubjectAction";
import {setSelectedRow} from "../../../../../../redux/actions/subject/subjectActionRowAction";

//utils
import {getObjectData} from "../../../../../../utils/getObjectData";

//constants
import {GET, SUBJECT_OBJECT} from "../../../../../../config/constants";

const LIMIT = 20;

class SubjectTable extends PureComponent {
    static propTypes = {
        isTablet: propTypes.bool.isRequired,
        isTableVisible: propTypes.bool.isRequired,
        openEventsTable: propTypes.func.isRequired,
        columns: propTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            page: 0,
            isSorted: false,
            sortedName: null,
            order: 'daynum',
            filter: [],
            initialValues: {},
        }
    }

    componentDidMount() {
        const {role} = this.props;
        if(role === 'INV') {
            this.getInitialData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            filter,
            order,
            offset
        } = this.state;

        const {
            fetchAdminSubjectData,
        } = this.props;

        if (prevState.offset !== this.state.offset) {
            this.getInitialData(filter, order, offset);
        }
        if (prevProps.status !== this.props.status) {
            return fetchAdminSubjectData(GET, SUBJECT_OBJECT, filter, null, order, offset)
        }
    }

    getInitialData = async (filter = [], order = 'daynum', offset = 0) => {
        const {
            fetchAdminSubjectData,
            setSelectedRow,
        } = this.props;

        await fetchAdminSubjectData(GET, SUBJECT_OBJECT, filter, null, order, offset);
        this.setState({
            filter,
            order,
            offset
        }, () => {
            setSelectedRow(getObjectData(() => this.props.data[0]))
        })
    };

    onRowClick = (e, selectedRow) => {
        const {
            openEventsTable,
            isTablet,
            setSelectedRow,
        } = this.props;

        if (isTablet && e.target.tagName === 'DIV') {
            openEventsTable();
        }

        setSelectedRow(selectedRow);
    };

    /**
     * onFetchData
     * @param state {Object}
     */
    onFetchData = (state) => {
        const offset = state.page * LIMIT;
        const page = state.page;

        this.setState({
            offset,
            page
        })
    };

    /**
     * onFilter
     * @param values {Object}
     * @return {Promise<void>}
     */
    onFilter = async (values) => {
        let filter = [];
        let defaultFilter = [];
        const initialValues = {};

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === 'status') {
                    filter = filter.concat(values[key].value ? values[key].value : []);
                    initialValues[key] = values[key];
                } else if (Array.isArray(values[key])) {
                    if (values[key] && values[key].length > 0) {
                        filter = filter.concat({name: key, value: `{${values[key]}}`, compare: 'IN'});
                        defaultFilter = defaultFilter.push(`${values[key]}`);
                        initialValues[key] = values[key];
                    }
                    if (key === 'center') {
                        localStorage.setItem('defaultCenterFilter', JSON.stringify(values[key]))
                    }
                } else {
                    filter.push({name: key, value: `%${values[key]}`, compare: 'LIKE'});
                    initialValues[key] = values[key];
                }
            }
        }

        await this.getInitialData(filter);

        this.setState({
            page: 0,
            initialValues
        })
    };

    /**
     * onSort
     * @param sortedName {string}
     * @return {Promise<void>}
     */
    onSort = async (sortedName) => {
        const isSorted = sortedName ? !this.state.isSorted : false;
        const order = isSorted ? `{${sortedName} DESC}` : `{${sortedName}}`;

        await this.getInitialData(this.state.filter, order, this.state.offset);
        this.setState({
            sortedName,
            isSorted,
            page: 0,
        })
    };

    render() {
        const {
            isTablet,
            isTableVisible,
            total,
            columns,
            selectedRow,
            data,
            isLoading
        } = this.props;

        const {
            filter,
            page,
            isSorted,
            sortedName,
            renderKey,
            initialValues,
        } = this.state;

        return (
            isTableVisible && <Col className={"subAdminTable__column"}>
                <Card className={"h-100"}>
                    <CardBody className={"d-flex flex-column"}>
                        {isLoading && <Loading/>}
                        <Table
                            data={data}
                            columns={columns}
                            onFetchData={this.onFetchData}
                            onFilter={this.onFilter}
                            selected={selectedRow}
                            total={total}
                            order={'daynum'}
                            onRowClick={this.onRowClick}
                            noDataText={"Запись не найдена"}
                            limit={LIMIT}
                            filter={filter}
                            page={page}
                            renderKey={renderKey}
                            onSort={this.onSort}
                            isSorted={isSorted}
                            sortedName={sortedName}
                            isLoading={isLoading}
                            initialValues={initialValues}
                            isTablet={isTablet}
                        />
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.adminSubject.data.data) || [],
    total: getObjectData(() => store.adminSubject.data.total),
    isLoading: getObjectData(() => store.adminSubject.isLoading),
    selectedRow: store.subTableSelectedRow.selectedRow,
    role: getObjectData(() => store.profile.data.data[0].role_name)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAdminSubjectData,
    setSelectedRow
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubjectTable);
