import {DATA_IS_LOADING, DATA_IS_SUCCESS, DATA_IS_FAILED} from "../../actions/pdf/sypmtPrintAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null
};

export function symptPrintReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case DATA_IS_FAILED:
            return {...state, isLoading: false, isError: true};
        case DATA_IS_SUCCESS:
            return {...state, isLoading: false, isError: false, data: action.payload};
        default:
            return state
    }
}
