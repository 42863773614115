import React from 'react';
import {Redirect} from 'react-router-dom';
import Idle from 'react-idle'
import HeaderPage from './components/header';
import {useSelector} from 'react-redux';
import {getObjectData} from "../../utils/getObjectData";
import {ADMIN} from "../../config/constants";

function Header(props)  {
    const role = useSelector(store => getObjectData(() => store.profile.data.data[0].role_name));

        return (
            role === ADMIN
                ? <HeaderPage {...props} />
                : <Idle
                    timeout={300000}
                        render={({idle}) => {
                            if (idle) {
                                localStorage.removeItem('token');
                                return <Redirect to={'/login'}/>
                            } else {
                                return <HeaderPage {...props} />
                            }
                        }
                    }
                />
        )
}

export default Header;
