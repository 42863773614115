import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {SUB_VERIFY_OBJECT} from '../../../config/constants';
export const STATUS_IS_LOADING = 'STATUS_IS_LOADING';
export const STATUS_IS_FAILED = 'STATUS_IS_FAILED';
export const STATUS_IS_SUCCESS = 'STATUS_IS_SUCCESS';

function dataIsLoading() {
    return {
        type: STATUS_IS_LOADING
    }
}

function dataIsFailed() {
    return {
        type: STATUS_IS_FAILED
    }
}

function dataIsSuccess(data) {
    return {
        type: STATUS_IS_SUCCESS,
        payload: data
    }
}

export function fetchSubStatusData(data, object = SUB_VERIFY_OBJECT) {
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(null, object, data,[], initialKey);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                return;
            }

            dispatch(dataIsSuccess(data))
        })
    }
}
