import * as React from 'react';
import {PureComponent} from 'react';
import {Route, withRouter, Switch, Redirect} from 'react-router-dom';

//utils
import {getObjectData} from "../../utils/getObjectData";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchLoginData} from "../../redux/actions/profile/loginAction";
import {isBlueTheme, isPurpleTheme} from "../../redux/actions/settings/appSettingsAction";

//components
import Header from '../header/index';
import Loading from '../_commonComponents/Loading';

//constants
import {COMPONENTS} from "../../config/components";

/**
 * Router
 */
class Router extends PureComponent {
    componentDidMount() {
        const {
            isBlueTheme,
            isPurpleTheme,
        } = this.props;

        this.getInitialData();
        this.props.theme === 'blue' ? isBlueTheme() : isPurpleTheme();
    }

    componentDidUpdate() {
        this.setThemeSettings();
    }

    setThemeSettings = () => {
        const {
            pageTitle,
            favicon
        } = this.props;

        document.title = pageTitle;
        const link = document.getElementById('favicon');
        link.href = favicon;
    };
    /**
     * getInitialData
     * return void
     */
    getInitialData = async () => {
        const {
            fetchLoginData
        } = this.props;

        fetchLoginData();
    };

    /**
     * getter topbarMenu
     * return {Array}
     */
    get topbarMenu() {
        let topbarMenu = this.props.menu ? [...this.props.menu] : [];
        topbarMenu = topbarMenu && topbarMenu.filter(({menu}) => menu !== 0);

        return topbarMenu
    };

    /**
     * wrapperRoutes
     * return {Node}
     */
    wrapperRoutes = () => {
        const {
            menu,
            defaultPage,
            defaultPath,
            skey,
            theme,
        } = this.props;

        const {
            pathname
        } = this.props.history.location;

        return (
            <main className={`theme-${theme}`}>
                {pathname !== '/login' && <Header
                    menu={this.topbarMenu}
                    skey={skey}
                    defaultPath={defaultPath}
                />}
                <div className="container__wrap">
                    <Switch>
                        {menu && menu.map(item =>
                            <Route
                                exact
                                key={item.id}
                                path={item.script}
                                component={COMPONENTS[item.name]}
                            />
                        )}
                        <Redirect to={defaultPath}/>
                        <Route component={COMPONENTS[defaultPage]}/>
                    </Switch>
                </div>
            </main>
        )
    };

    render() {
        const {
            isLoading
        } = this.props;

        return (
           isLoading ? <Loading/> : <Route component={this.wrapperRoutes}/>
        )
    }
}

const mapStateToProps = (store) => ({
    isLoading: store.profile.isLoading,
    menu: getObjectData(() => store.profile.data.menu),
    defaultPage: getObjectData(() => store.profile.data.data[0].default_view_name),
    defaultPath: getObjectData(() => store.profile.data.data[0].default_view_path),
    role_name: getObjectData(() => store.profile.data.data[0].role_name) ,
    skey: getObjectData(() => store.profile.key),
    pageTitle: store.settings.pageTitle,
    favicon: store.settings.favicon,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchLoginData,
    isBlueTheme,
    isPurpleTheme
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Router));
