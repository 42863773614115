import * as React from 'react';
import {PureComponent} from 'react';
import propTypes from 'prop-types';

//utils
import {getObjectData} from "../../../../utils/getObjectData";

//redux
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {fetchUsersAdminData} from "../../../../redux/actions/admin/usersAdminAction";

//constants
import {MOBILE} from "../../../../config/constants";

//components
import AddNewUserForm from './adminPageConstructor/components/forms/AddNewUserForm';
import EditUserForm from './adminPageConstructor/components/forms/EditUserForm';
import AdminPageConstructor from './adminPageConstructor/index';

/**
 * UsersConstructor. Единый компонент для создания страниц Администраторы, Спец. КИ
 */
class UsersConstructor extends PureComponent {
    static propTypes = {
        initFilter: propTypes.array.isRequired,
        title: propTypes.string.isRequired,
        titleIcon: propTypes.string.isRequired,
        roles: propTypes.string.isRequired,
        history: propTypes.object.isRequired,
    };

    static defaultProps = {
        AddForm: AddNewUserForm,
        EditForm: EditUserForm
    };

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        }
    }

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile)
    }

    /**
     * void isMobile
     */
    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;

        this.setState({
            isMobile
        })
    };

    /**
     * onFilter
     * @param values {Object}
     * @return {*[]} {Array}
     */
    onFilter = (values) => {
        let filter = [...this.props.initFilter];

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                filter.push({name: key, value: `%${values[key]}`, compare: 'LIKE'});
            }
        }

        return filter;
    };

    /**
     * @param renderCell {Function}
     * return Array
     */
    setColumns = (renderCell) => {
        const {
            isMobile
        } = this.state;

        return [
            {
                Header: '',
                accessor: 'name',
                id: 'name',
                placeholder: 'Имя пользователя',
                filterable: false,
                sortable: false,
                style: {minWidth: isMobile? 0 : 254},
                width: isMobile ? '100%' : 254,
                maxWidth: isMobile ? '100%' : 254,
                minWidth: isMobile ? 0 : 254
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'title',
                    id: 'title',
                    placeholder: 'ФИО',
                    filterable: false,
                    sortable: false,
                }
            },
            {
                ...!isMobile && {
                    Header: '',
                    accessor: 'phone_view',
                    id: 'phone_search',
                    placeholder: 'Телефон',
                    filterable: false,
                    sortable: false,
                    style: {minWidth: 332},
                    width: 332,
                    maxWidth: 332,
                    minWidth: 332
                }
            },
            {
                Header: '',
                accessor: 'action',
                filterable: false,
                sortable: false,
                Cell: renderCell,
                width: 80,
                minWidth: 80
            }
        ]
    };

    /**
     * return {Node} AdminPageConstructor
     */
    render() {
        const {
            data,
            total,
            formData,
            fetchUsersAdminData,
            initFilter,
            title,
            titleIcon,
            roles,
            history,
            AddForm,
            EditForm
        } = this.props;

        const {
            isMobile
        } = this.state;

        return (
            <AdminPageConstructor
                data={data}
                isMobile={isMobile}
                total={total}
                formData={formData}
                fetchData={fetchUsersAdminData}
                AddForm={AddForm}
                EditForm={EditForm}
                initFilter={initFilter}
                renderColumns={this.setColumns}
                title={title}
                titleIcon={titleIcon}
                roles={roles}
                history={history}
                onFilter={this.onFilter}
            />
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.usersAdmin.data.data),
    total: getObjectData(() => store.usersAdmin.data.total),
    formData: getObjectData(() => store.usersAdmin.formData)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsersAdminData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersConstructor);
