import React, {PureComponent} from 'react';
import {Card, CardBody} from 'reactstrap';
import propTypes from 'prop-types';

//components
import Table from '../../_commonComponents/table/index'

//constants
import {ADMIN_CHAT_OBJECT_GET, GET, LIMIT} from "../../../config/constants";

//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchChatData} from "../../../redux/actions/chat/chatAction";

//utils
import {getObjectData} from "../../../utils/getObjectData";

class ChatTable extends PureComponent {
    static propTypes = {
        onRowClick: propTypes.func.isRequired,
        toggleVisibility: propTypes.func.isRequired,
        getTableSelectedRow: propTypes.func.isRequired,
        selected: propTypes.object,
        interval: propTypes.number.isRequired,
        isTableUpdate: propTypes.bool.isRequired
    };

    state = {
        offset: 0,
        page: 0,
        isSorted: false,
        sortedName: null,
        isLoading: false,
        order: null,
        renderKey: false,
        filter: [],
        data: [],
        selected: null,
        total: 0,
    };

    componentDidMount() {
        this.getInitialData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.offset !== this.state.offset) {
            this.getTableData(this.state.filter, this.state.offset, this.state.order);
        } else if (prevProps.isTableUpdate !== this.props.isTableUpdate) {
            this.getTableData()
        }
    }
    /**
     * onFetchData
     * @param state {Object}
     */
    onFetchData = (state) => {
        const offset = state.page * LIMIT;
        const page = state.page;

        this.setState({
            offset,
            page
        })
    };

    /**
     * getInitialData
     * @return {Promise<void>}
     */
    getInitialData = async () => {
        const {
            onRowClick
        } = this.props;

        await this.getTableData();
        onRowClick(getObjectData(() => this.props.data[0].id))
    };

    /**
     * getTableData
     * @param filter {Array}
     * @param offset {number}
     * @param order {string}
     * @return {Promise<void>}
     */
    getTableData = async (filter = [], offset = 0, order = null) => {
        const {
            fetchChatData,
        }= this.props;

        await fetchChatData(GET, {}, filter, ADMIN_CHAT_OBJECT_GET, LIMIT, offset, order);
        this.setState({
            data: this.props.data,
            total: this.props.total,
            offset,
            order,
            filter
        })
    };

    /**
     * renderCell
     * @param info {Object}
     * @return {*}
     */
    renderCell = (info) => {
        return (
            <div className="d-flex justify-content-end">
                <span className={"badge"}>{info.original.new_msg_count}</span>
            </div>
        )
    };

    /**
     * onRowClick
     * @param e {Event}
     * @param selected {Object}
     * @return {Promise<void>}
     */
    onRowClick = async (e, selected) => {
        const {
            onRowClick,
            toggleVisibility,
            getTableSelectedRow
        } = this.props;

        await onRowClick(selected.id);
        getTableSelectedRow(selected);
        toggleVisibility();
    };

    /**
     * onFilter
     * @param values {Object}
     * @return {Promise<void>}
     */
    onFilter = async (values) => {
        let filter = [];

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                filter.push({name: key,value: `%${values[key]}`,compare: 'LIKE'});
            }
        }

        await this.getTableData(filter);

        this.setState({
            page: 0,
        })
    };

    /**
     * onSort
     * @param sortedName {string}
     * @return {Promise<void>}
     */
    onSort = async (sortedName) => {
        const isSorted = sortedName ? !this.state.isSorted : false;
        const order = isSorted ? `{${sortedName} DESC}` : `{${sortedName}}`;

        await this.getTableData(this.state.filter, 0, order);

        this.setState({
            sortedName,
            isSorted,
            data: this.props.data,
            page: 0,
            order
        })
    };

    /**
     * getter columns
     * @return {*[]}
     */
    get columns() {
        return         [
            {
                Header: '',
                accessor: 'center',
                id: 'center',
                placeholder: 'Центр',
                filterable: false,
                sortable: false,
            },
            {
                Header: '',
                accessor: 'num',
                id: 'num',
                placeholder: 'Пациент',
                style: {textAlign:  'center'},
                filterable: false,
                sortable: false,
            },
            {
                Header: '',
                accessor: 'action',
                Cell: this.renderCell,
                placeholder: '',
                filterable: false,
                sortable: false,
                width: 50,
                minWidth: 0,
                maxWidth: 50,
            },
        ]
    }

    /**
     * getter selectedRow
     * @return {ChatTable.props.selected}
     */
    get selectedRow() {
        const {
            selected
        } = this.props;

        return selected ? selected : getObjectData(() => this.state.data[0])
    }

    render() {
        const {
            data,
            isSorted,
            sortedName,
            page,
            total
        } = this.state;

        return (
            <Card className={"adminChat__table h-100"}>
                <CardBody className="d-flex flex-column">
                    <Table
                        data={data}
                        onFetchData={this.onFetchData}
                        columns={this.columns}
                        onRowClick={this.onRowClick}
                        selected={this.selectedRow}
                        total={total}
                        limit={LIMIT}
                        onFilter={this.onFilter}
                        onSort={this.onSort}
                        isSorted={isSorted}
                        sortedName={sortedName}
                        page={page}
                    />
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.chat.data),
    total: getObjectData(() => store.chat.total),
    formData: store.chat.formData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchChatData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatTable);
