import * as React from 'react';
import {PureComponent} from 'react';
import {Field, reduxForm} from 'redux-form';
import renderInput from '../../form/InputWithValidation';
import debounce from 'debounce';
import Loading from '../../Loading';
import {connect} from 'react-redux';

class FilterForm extends PureComponent {
    constructor(props) {
        super(props);

        FilterForm._onChange = debounce(FilterForm._onChange, 500);
        FilterForm._onSelectChange = debounce(FilterForm._onSelectChange, 0);
    }

    static _onChange(e, callback) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
        callback()
    }

    static _onSelectChange(e, callback) {
        if (e.key === 'Enter') {
            e.preventDefault();
            return;
        }

        callback()
    }

    onSubmit = (e) => FilterForm._onChange(e, this.props.handleSubmit(this.props.onFilter));
    onChange = (e) => FilterForm._onSelectChange(e, this.props.handleSubmit(this.props.onFilter));

    onEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    render() {
        const {
            columns,
            onSort,
            isSorted,
            sortedName,
            isLoading,
            isDefaultFilterRender
        } = this.props;

        return (
            <form className="form d-flex form_filter">
                        {
                            columns && columns.map(item => {
                                    if (item.id) {
                                        const onHandleSort = () => onSort(item.id);

                                        return <Field
                                            key={item.accessor}
                                            name={item.id}
                                            component={item.component ? item.component : renderInput}
                                            options={item.options}
                                            onChange={item.component ? this.onChange : this.onSubmit}
                                            onKeyPress={this.onEnter}
                                            onSort={onHandleSort}
                                            isSorted={isSorted}
                                            sortedName={sortedName === item.id ? sortedName : null}
                                            compare={item.compare}
                                            style={{maxWidth: item.width, minWidth: item.minWidth}}
                                            placeholder={item.placeholder}
                                            isDefaultFilterRender={item.isDefaultFilterRender}
                                            isSortable
                                        />
                                    } else {
                                        return <div
                                            key={item.accessor}
                                            style={{minWidth: item.width}}
                                        />
                                    }
                                }
                            )
                        }
                {isLoading && <Loading/>}
            </form>
        )
    }
}

FilterForm = reduxForm({
    form: 'table_filter_form',
    enableReinitialize: true
})(FilterForm);

const mapStateToProps = (store, props) => ({
    initialValues: props.initialValues,
});

export default connect(mapStateToProps)(FilterForm);
