import * as React from 'react';
import {PureComponent, Fragment} from 'react';
import ReactTable from 'react-table';
import Pagination from './components/Pagination';
import FilterComponent from './components/Filter';
import {getObjectData} from "../../../utils/getObjectData";
import {LIMIT} from "../../../config/constants";
import propTypes from 'prop-types';

/**
 * Компонент дя создания таблиц
 */
class Table extends PureComponent {
    static propTypes = {
        limit: propTypes.number,
        data: propTypes.array,
        columns: propTypes.array,
        selected: propTypes.object,
        total: propTypes.number,
        onRowClick: propTypes.func,
        noDataText: propTypes.string,
        verified: propTypes.bool,
        resetTable: propTypes.func,
        onFetchData: propTypes.func.isRequired,
        initialValues: propTypes.object,
        loadingText: propTypes.string,
        stopActionEvents: propTypes.bool
    };

    static defaultProps = {
        limit: LIMIT,
        resetTable: () => {
        },
        noDataText: '',
        verified: false,
        isLoading: false,
        initialValues: {},
        loadingText: '',
        stopActionEvents: false
    };

    render() {
        const {
            data,
            columns,
            selected,
            total,
            limit,
            onRowClick,
            noDataText,
            renderKey,
            page,
            onFetchData,
            onSort,
            onFilter,
            isLoading,
            isSorted,
            sortedName,
            initialValues,
            loadingText,
            isDefaultFilterRender
        } = this.props;

        return (
            <Fragment>
                <FilterComponent
                    key={renderKey}
                    columns={columns}
                    onSort={onSort}
                    onFilter={onFilter}
                    isSorted={isSorted}
                    sortedName={sortedName}
                    isLoading={isLoading}
                    initialValues={initialValues}
                    isDefaultFilterRender={isDefaultFilterRender}
                />
                <ReactTable
                    data={[...data]}
                    columns={columns}
                    className={"flex-grow-1 reactTable"}
                    manual
                    onFetchData={onFetchData}
                    noDataText={!isLoading && noDataText}
                    PaginationComponent={Pagination}
                    defaultPageSize={data && data.length}
                    loading={isLoading}
                    loadingText={loadingText}
                    total={total}
                    page={page}
                    limit={limit}
                    pages={Math.ceil(total / limit)}
                    getTrProps={(state, rowInfo) => {
                        return {
                            onClick: (e, t) => {
                                onRowClick(e, rowInfo.original)
                            },
                            className: rowInfo && rowInfo.original.id === getObjectData(() => selected.id) ? "-selected" : ""
                        }
                    }}
                />
            </Fragment>
        )
    }
}

export default Table;
