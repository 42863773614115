import * as React from 'react';
import {Label} from 'reactstrap';
import classnames from 'classnames';

/**
 * Компонент RadioGroupWithValidation
 * @param input
 * @param name
 * @param options
 * @param className
 * @param disabled
 * @param label
 * @param readOnly
 * @param horizontal
 * @constructor
 */
const RadioGroupWithValidation = ({
                                                               value,
                                                               onChange,
                                                               name,
                                                               options,
                                                               className = "",
                                                               disabled = false,
                                                               label,
                                                               readOnly = false,
                                                               horizontal = false,
                                                               isEmptyValue = false,
                                                                style,
                                                           }) => {
    const RadioButtonClass = classnames({
        'radio-btn': true,
        disabled,
    });

    return (
        <div
            className={classnames("form__form-group d-flex flex-column", horizontal ? "flex-column flex-md-row align-items-md-center" : "")}>
            {label && <Label
                style={horizontal && style ? style : {minWidth: 130}}
                className={classnames("radio-btn__label", horizontal ? "pb-2 m-0 pb-md-0 pl-0" : "", isEmptyValue ? (value !== '' ? "" : "radio-btn__label_red") : "")}
                for={name}>{label}</Label>}
            {options && options.map(item =>
                <label
                    key={item.id}
                    className={classnames(`${RadioButtonClass}${className ? ` radio-btn--${className}` : ''}`, horizontal ? "pb-2 pb-md-0" : "")}
                    htmlFor={item.name}
                >
                    <input
                        className="radio-btn__radio"
                        id={item.id}
                        onChange={onChange}
                        onKeyPress={onChange}
                        name={`${name}_${item.id}`}
                        type="radio"
                        value={item.value}
                        // eslint-disable-next-line
                        checked={`${value}` == `${item.value}`}
                        disabled={readOnly}
                    />
                    <span className="radio-btn__radio-custom"/>
                    <span className={classnames("radio-btn__label")}>{item.label}</span>
                </label>
            )}
        </div>
    )
};

const renderRadioGroup = (props) => {
    return <RadioGroupWithValidation
        {...props.input}
        {...props}
    />
};

export default renderRadioGroup
