import "cross-fetch/polyfill";

import {getObjectData} from "../../../utils/getObjectData";
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import ShowNotification from "../../../utils/showNotification";

import {CLS_OBJECT} from "../../../config/constants";

export const CLS_ADMIN_L = 'CLS_ADMIN_L'; //loading
export const CLS_ADMIN_F = 'CLS_ADMIN_F'; //failed
export const CLS_ADMIN_S = 'CLS_ADMIN_S'; //success
export const CLS_ADMIN_E = 'CLS_ADMIN_E'; //edited


const LIMIT = 20;

function dataIsLoading() {
    return {
        type: CLS_ADMIN_L
    }
}

function dataIsFailed(message) {
    return {
        type: CLS_ADMIN_F,
        payload: message
    }
}

function dataIsLoadedSuccess(data) {
    return {
        type: CLS_ADMIN_S,
        payload: data
    }
}

function dataIsEdited(data) {
    return {
        type: CLS_ADMIN_E,
        payload: data
    }
}

export function fetchClsData(action, filter = [], data ={}, isInitial = true, order, offset, limit = LIMIT){
    const notification  = Object.create(ShowNotification);
    let initialKey = localStorage.token;
    const URL = UrlCreator();
    const requestData = formDataCreator(action, CLS_OBJECT, data, filter, initialKey, limit, offset, order);
    return (dispatch) => {
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if(response.status !== 200){
                throw Error(response.statusText);
            }

            dispatch(dataIsLoading());
            return response.json()
        }).then((data) => {
            // eslint-disable-next-line
            if (data.status != 0) {
                dispatch(dataIsFailed(data.msg));
                notification.show(`${data.msg}`);
                return;
            }

            isInitial
                ? dispatch(dataIsLoadedSuccess(data))
                : dispatch(dataIsEdited(getObjectData(() => data.data)))
        }).catch(e => console.error(e))
    }
}
