if (!Number.isNaN) {
    Number.isNaN = function (n) {
        // eslint-disable-next-line
        return n !== n;
    }
}

export function isNumeric(val) {
    return !Number.isNaN(parseFloat(val)) && isFinite(val)
}
