import {SUB_ROW_IS_CHANGED, EVENT_ROW_IS_CHANGE} from "../../actions/subject/subjectActionRowAction";

const initialState = {
    selectedRow: null,
    eventSelectedRow: null
};

export function subjectActionRowReducer(state = initialState, action) {
    switch (action.type) {
        case SUB_ROW_IS_CHANGED:
            return {...state, selectedRow: action.payload};
        case EVENT_ROW_IS_CHANGE:
            return {...state, eventSelectedRow: action.payload};
        default:
            return state;
    }
}
