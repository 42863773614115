import * as React from 'react';
import {Button} from "reactstrap";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 * IconButton
 * @param className {string}
 * @param icon {string}
 * @param color {string}
 * @param onClick {Function}
 * @param type {string}
 * @constructor
 */
const IconButton = ({className, icon, color, onClick, type}) => (
    <Button onClick={onClick} type={type} color={color} className={classnames('p-2 m-0', className)}>
        <FontAwesomeIcon icon={icon} />
    </Button>
);

IconButton.defaultProps = {
    type: 'button',
    className: '',
    color: 'primary'
};

export default IconButton;
