import 'core-js/es';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './containers/_app/App';
import {Provider} from 'react-redux';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faSignOutAlt,
    faQuestion,
    faUserCog,
    faUserMd,
    faUserAstronaut,
    faUsers,
    faEnvelope,
    faUserPlus,
    faSyncAlt,
    faChevronLeft,
    faChevronRight,
    faBook,
    faFolderPlus,
    faFolder,
    faFile,
    faPencilAlt,
    faFolderOpen,
    faTrash,
    faTimes,
    faSortUp,
    faSortDown,
    faSort,
    faHospital,
    faTemperatureHigh,
    faHandsHelping,
    faHome,
    faSun,
    faMoon,
    faFilter,
    faPlay,
    faChevronUp,
    faCheckCircle,
    faUser,
    faQuestionCircle,
    faUserInjured,
    faFilePdf,
    faSmile,
    faDownload
} from '@fortawesome/free-solid-svg-icons';

import {
    faFileAlt as faFarFileAlt,
    faFile as faFarFile,
    faTimesCircle as faFarTimesCircle,
    faCheckCircle as faFarCheckCircle,
    faFrown as faFarFrown,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faSignOutAlt,
    faQuestion,
    faUserCog,
    faUserMd,
    faUserAstronaut,
    faUsers,
    faEnvelope,
    faUserPlus,
    faSyncAlt,
    faChevronLeft,
    faChevronRight,
    faBook,
    faFolderPlus,
    faFolder,
    faFile,
    faPencilAlt,
    faFolderOpen,
    faTrash,
    faFarFileAlt,
    faTimes,
    faSortUp,
    faSortDown,
    faSort,
    faHospital,
    faTemperatureHigh,
    faFarFrown,
    faHandsHelping,
    faFarTimesCircle,
    faFarCheckCircle,
    faHome,
    faFarFile,
    faSun,
    faMoon,
    faFilter,
    faPlay,
    faChevronUp,
    faCheckCircle,
    faUser,
    faQuestionCircle,
    faUserInjured,
    faFilePdf,
    faSmile,
    faDownload
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
