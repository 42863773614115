import {
    USERS_ADMIN_INV_L,
    USERS_ADMIN_INV_S,
    USERS_ADMIN_INV_F,
    USERS_ADMIN_INV_E
} from '../../actions/admin/usersInvAdminAction';

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    formData: null,
    message: "",
};

export function usersAdminInvReducer(state = initialState, action) {
    switch (action.type) {
        case USERS_ADMIN_INV_L:
            return {isLoading: true,  isError: false};
        case USERS_ADMIN_INV_F:
            return {isLoading: false, isError: true, message: action.payload};
        case USERS_ADMIN_INV_S:
            return {isLoading: false, isError: false, data: action.payload};
        case USERS_ADMIN_INV_E:
            return {isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
