import * as React from 'react';
import  { PureComponent} from 'react';
import {bindActionCreators} from "redux";
import {Container, Card, CardBody} from 'reactstrap';
import {fetchClsData} from "../../redux/actions/cls/clsAction";
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import CardHeader from '../_commonComponents/CardHeader';
import Group from './components/Group';
import AddNewUserForm from './components/forms/AddForm';
import {ADD, GET, SET} from "../../config/constants";
import {getObjectData} from "../../utils/getObjectData";

/**
 * Cls - Классификатор '/admin/cls'
 */
class Cls extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPopupOpen: false,
            rkey: false
        }
    }

    /**
     * togglePopup
     * return {void}
     */
    togglePopup = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }))
    };

    /**
     * onSubmit
     * @param values {Object}
     */
    onSubmit = async (values) => {
        const {
            fetchClsData
        } = this.props;

        const data = {
            ...values,
            ...{parent: null}
        };

        await fetchClsData(ADD, [], data, false);
        this.toggleKey();
    };

    /**
     * toggleKey
     * return void
     */
    toggleKey = () => {
        this.setState(prevState => ({
            rkey: !prevState.rkey
        }))
    };

    /**
     * onReset
     */
    onReset = async () => {
        const {
            fetchClsData
        } = this.props;

        await fetchClsData(GET, [{name: 'parent', value: null}]);
        let id = getObjectData(() => this.props.data.find(({collapsed}) => !collapsed).id);
        if (!id) return;
        await fetchClsData(SET, [], {id, collapsed: true});
        this.toggleKey();
    };

    render() {
        const {
            isPopupOpen,
            rkey
        } = this.state;

        const {
            history
        } = this.props;

        return (
            <Container className={"mt-2"}>
                <CardHeader
                    title={'Классификатор'}
                    titleIcon={'book'}
                    actionIcon={'folder-plus'}
                    action={this.togglePopup}
                    reset={this.onReset}
                    history={history}
                />
                <Card>
                    <CardBody key={rkey}>
                        <Group parent={null} toggleKey={this.toggleKey}/>
                    </CardBody>
                </Card>
                <AddNewUserForm
                    form={"add_new_parent_iotem_to_cls"}
                    isPopupOpen={isPopupOpen}
                    onSubmit={this.onSubmit}
                    togglePopup={this.togglePopup}
                />
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.cls.data.data)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchClsData,
    reset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cls);
