export const SUB_ROW_IS_CHANGED = 'SUB_ROW_IS_CHANGED';
export const EVENT_ROW_IS_CHANGE = 'EVENT_ROW_IS_CHANGE';

export function setSelectedRow(selectedRow) {
    return {
        type: SUB_ROW_IS_CHANGED,
        payload: selectedRow
    }
}

export function setSelectedEventRow(selectedRow) {
    return {
        type: EVENT_ROW_IS_CHANGE,
        payload: selectedRow
    }
}
