import {
    IS_USER_LOGIN_E,
    IS_USER_LOGIN_L,
    IS_USER_LOGIN_S
} from '../../actions/profile/loginAction';

const initialData = {
    isLoading: true,
    isError: false,
    data: null,
    key: null,
};

export function loginReducer(store = initialData, action) {
    switch (action.type) {
        case IS_USER_LOGIN_L:
            return {...store, isLoading: true, isError: false};
        case IS_USER_LOGIN_E:
            return {...store, isLoading: false, isError: true};
        case IS_USER_LOGIN_S:
            return {...store, isLoading: false, isError: false, data: action.payload, key: action.key};
        default:
            return store;
    }
}
