import * as React from 'react';
import {PureComponent} from 'react';
import Group from './Group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import AddForm from './forms/AddForm';
import EditForm from './forms/EditForm';
import {getObjectData} from "../../../utils/getObjectData";
import Modal from '../../_commonComponents/Modal';

class Entry extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isCollapse: true,
            isPopupOpen: false,
            isEditPopupOpen: false,
            initialVal: null,
            isDeletePopupOpen: false,
            deleteItem: {id: null, name: null}
        }
    }

    openPopup = (id) => {
        this.props.setCurrentParent(id);
        this.togglePopup();
    };

    togglePopup = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }))
    };

    toggleEditPopup = () => {
        this.setState(prevState => ({
            isEditPopupOpen: !prevState.isEditPopupOpen
        }))
    };

    openEditPopup = (data) => {
        this.setState({
            initialVal: data
        });

        this.toggleEditPopup();
    };

    toggleDeletePopup = () => {
        this.setState(prevState => ({
            isDeletePopupOpen: !prevState.isDeletePopupOpen
        }))
    };

    openDeletePopup = (deleteItem) => {
        this.setState({
            deleteItem
        }, () => this.toggleDeletePopup())
    };

    render() {
        const {
            id,
            dep,
            name,
            leaf,
            vers,
            title,
            dsc,
            parent,
            addUserSubmit,
            editUserSubmit,
            onDelete,
            collapsed,
            onClick,
            toggleKey,
            color
        } = this.props;

        const {
            isPopupOpen,
            isEditPopupOpen,
            initialVal,
            isDeletePopupOpen,
            deleteItem
        } = this.state;

        return (
            <div className="cls">
                <div
                    className={classnames("d-flex justify-content-between flex-wrap cls__inner", leaf ? 'cls__inner_empty' : '')}
                    tabIndex={0}>
                    <div className="cls__column">
                        <div className="cls__headerIcon" style={{paddingLeft: `${dep * 40}px`}}
                             onClick={() => onClick(id, collapsed)}>
                            {collapsed
                                ? !leaf
                                    ? <FontAwesomeIcon color={color} icon="folder"/>
                                    : <FontAwesomeIcon color={color} icon={['far', 'file']}/>
                                : <FontAwesomeIcon color={color} icon="folder-open"/>
                            }
                        </div>
                        <h5 className="text-uppercase">{name}</h5>
                    </div>
                    <div className="d-none d-md-block cls__column">{title}</div>
                    <div
                        className={classnames("flex-grow-0 flex-shrink-0 cls__column cls__column_action justify-content-end")}>
                        <div className="cls__iconInner">
                            <span
                                onClick={() => this.openPopup(id)}>
                                           <FontAwesomeIcon
                                               className="cls__icon cls__icon_green"
                                               icon="file"
                                           />
                            </span>
                            <span
                                onClick={() => this.openEditPopup({id, vers, name, title, parent, dsc, color})}
                            >
                                       <FontAwesomeIcon
                                           className="cls__icon"
                                           icon="pencil-alt"
                                       />
                            </span>
                            <span
                                onClick={() => this.openDeletePopup({id, name})}
                            >
                                    <FontAwesomeIcon
                                        className="cls__icon cls__icon_red"
                                        icon="trash"
                                    />
                            </span>
                        </div>
                    </div>
                </div>
                {!collapsed && <Group parent={id} toggleKey={toggleKey}/>}
                <AddForm
                    onSubmit={addUserSubmit}
                    togglePopup={this.togglePopup}
                    isPopupOpen={isPopupOpen}
                    form={"add_child_user_cls_form"}
                />
                <EditForm
                    onSubmit={editUserSubmit}
                    form={`edit_child_user_cls_form`}
                    isPopupOpen={isEditPopupOpen}
                    togglePopup={this.toggleEditPopup}
                    initialValues={initialVal}
                />
                <Modal
                    title={"Удалить раздел"}
                    color={"danger"}
                    colored={false}
                    header
                    isOpen={isDeletePopupOpen}
                    message={`Вы действительно хотите удалтить раздел ${ getObjectData(() => deleteItem.name)}?`}
                    onSubmit={() => onDelete(getObjectData(() => deleteItem.id))}
                    toggle={this.toggleDeletePopup}
                />
            </div>
        )
    }
}

export default Entry;
