import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {SUBJECT_ANTIPYRETIC_OBJECT, GET} from '../../../config/constants';
import {getObjectData} from "../../../utils/getObjectData";
import ShowNotification from "../../../utils/showNotification";

export const SUBJECT_WORSENING_L = 'SUBJECT_WORSENING_L'; //loading
export const SUBJECT_WORSENING_F = 'SUBJECT_WORSENING_F'; //error
export const SUBJECT_WORSENING_E = 'SUBJECT_WORSENING_E'; //edited
export const SUBJECT_WORSENING_S =  'SUBJECT_WORSENING_S'; //success

function dataIsLoading() {
    return {
        type: SUBJECT_WORSENING_L
    }
}

function dataIsFailed() {
    return {
        type: SUBJECT_WORSENING_F
    }
}

function dataIsSuccess(data) {
    return {
        type: SUBJECT_WORSENING_S,
        payload: data
    }
}

function dataIsEdited(data) {
    return {
        type: SUBJECT_WORSENING_E,
        payload: data
    }
}

export function fetchWorseningData(action = GET, data, filter = [], isInitial = true, object = SUBJECT_ANTIPYRETIC_OBJECT) {
    const notification = Object.create(ShowNotification);
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(action, object, data, filter, initialKey);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                notification.show(`${data.msg}`);
                return;
            }
            isInitial
                ? dispatch(dataIsSuccess(data))
                : dispatch(dataIsEdited(getObjectData(() => data.data)))
        }).catch(e => console.error(e))
    }
}
