import * as React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * Компонент InputValidationField
 * @param input
 * @param mask
 * @param placeholder
 * @param inputRef
 * @param type
 * @param readOnly
 * @param autoFocus
 * @param label
 * @param touched
 * @param error
 * @param autoComplete
 * @param icon
 * @param isSortable
 * @param isSorted
 * @param sortedName
 * @param onSort
 * @param disabled
 * @param right
 * @param className
 * @param style
 * @param isEmptyValue
 * @constructor
 */
const InputValidationField = ({
                                        mask,
                                        placeholder,
                                        inputRef,
                                        value,
                                        type,
                                        readOnly,
                                        autoFocus,
                                        label,
                                        meta: {touched, error},
                                        autoComplete,
                                        icon,
                                        isSortable,
                                        isSorted,
                                        sortedName,
                                        onSort,
                                        disabled,
                                        right,
                                        className,
                                        style,
                                        isEmptyValue,
                                        onChange,
                                        onKeyPress,
                                        name
                                    }) => {
        return (
            <div style={style} className={classnames("form__form-group", className)}>
                {label && <span className={classnames("form__form-group-label", isEmptyValue ? (value ? "" : "form__form-group-label_red") : "")}>{label}</span>}
                <div className="form__form-group-field">
                    {icon && !right && <div className="form__form-group-icon">
                        {icon}
                    </div>}
                    <div className={classnames("form__form-group-input-wrap")}>
                        <InputMask
                            mask={mask}
                            maskChar={null}
                            value={value}
                            onChange={onChange}
                            readOnly={readOnly}
                            type={type}
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                            inputRef={inputRef}
                            autoComplete={autoComplete}
                            disabled={disabled}
                            onKeyPress={onKeyPress}
                        />
                        {isSortable && <div key={isSorted} className="sortIcon">
                            {!sortedName &&
                            <span onClick={() => onSort(name)}>
                         <FontAwesomeIcon color={'#E5E5E5'} icon={"sort"}/>
                    </span>
                            }
                            {sortedName &&
                            <span onClick={onSort}>
                        <FontAwesomeIcon color={'black'} icon={isSorted ? "sort-down" : "sort-up"}/>
                    </span>}
                        </div>}
                        {touched && error && <span className="form__form-group-error">{error}</span>}
                    </div>
                    {icon && right &&<div className="form__form-group-icon">
                        {icon}
                    </div>}
                </div>
            </div>
        )
};

InputValidationField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    readOnly: false,
    mask: null,
    onSort: () => {},
    right: false,
    disabled: false,
    isSortable: false,
    autoFocus: false,
    isEmptyValue: false
};

const renderInputWithValidation = (props) =>
    <InputValidationField
        {...props.input}
        {...props}
    />;

export default renderInputWithValidation;
