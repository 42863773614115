import * as React from 'react';
import Modal from "../../_commonComponents/ModalForm";
import {Field} from "redux-form";
import propTypes from 'prop-types';
import renderInput from "../../_commonComponents/form/InputWithValidation";
import renderTextarea from '../../_commonComponents/form/TextAreaInputWitValidation';
import {required, number} from "../../../utils/formValidation";

/**
 * EditCenterForm
 * @param isOpen {boolean}
 * @param toggle {Function}
 * @param onSubmit {Function}
 * @param form {String}
 * @param initialValues {Array}
 * @constructor
 */
const EditCenterForm = ({isOpen, toggle, onSubmit, form, initialValues}) => (
    <Modal
        toggle={toggle}
        isOpen={isOpen}
        title={`Редактировать центр ${initialValues && initialValues['name']}`}
        form={form}
        header
        color={"primary"}
        onSubmit={onSubmit}
        initialValues={initialValues}
    >
        <div className="form__from-fields-wrapper">
            <div className="pr-2 flex-grow-1">
                <Field
                    component={renderInput}
                    name={'id'}
                    type="text"
                    label={'Sys Id'}
                    readOnly
                    disabled
                />
            </div>
            <div className={"flex-grow-1"}>
                <Field
                    component={renderInput}
                    name={'vers'}
                    type="text"
                    label={'Vers'}
                    readOnly
                    disabled
                />
            </div>
        </div>
            <Field
                component={renderInput}
                name={'name'}
                type="text"
                label={'Номер'}
                validate={[required, number]}
            />
            <Field
                component={renderTextarea}
                name={'title'}
                type="text"
                label={'Название центра'}
                validate={[required]}
            />
            <Field
                component={renderInput}
                name={'tag'}
                type="text"
                label={'GMT'}
                validate={[number]}
            />
    </Modal>
);

EditCenterForm.propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    form: propTypes.string.isRequired,
    initialValues: propTypes.object
};

export default EditCenterForm;
