import {DATA_IS_FAILED, DATA_IS_SUCCESS, DATA_IS_LOADING, DATA_IS_ADDED} from "../../actions/chat/chatAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    total: null,
    formData: null
};

export function chatReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case DATA_IS_FAILED:
            return {...state, isLoading: false, isError: true};
        case DATA_IS_SUCCESS:
            return {...state, isLoading: false, isError: false, data: action.payload.data, total: action.payload.total};
        case DATA_IS_ADDED:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
