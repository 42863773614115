import React, {PureComponent, Fragment} from 'react';
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import propTypes from 'prop-types';
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchAdminSubjectData} from "../../../../../../redux/actions/admin/adminSubjectAction";

//utils
import {returnBootstrapColor} from "../../../../../../utils/returnBootstrapColor";
import {getObjectData} from "../../../../../../utils/getObjectData";

//components
import SubjectSymp from "../../../../../subjectSymp/components/SubjectForm";
import SubAntipyreticTable from "../../../../../subAntipyretic/components/SubAntipyreticTable";
import Panel from "../../../../Panel";
import WorseningTable from "../../../../../subWorsening/component/SubWorseningTable";

//constants
import {GET, SUBJECT_SYMP_OBJECT} from "../../../../../../config/constants";
const TYPE = 'sympt';
const AWAITING = 'AWAITING';

class SympTabs extends PureComponent {
    static propTypes = {
        isSympVisible: propTypes.bool.isRequired,
        isAntipyreticSympVisible: propTypes.bool.isRequired,
        isWorseningSympVisible: propTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        }
    }

    componentDidMount() {
        this.getSymptFormData();
    }

    componentDidUpdate(prevProps) {
        if (getObjectData(() => prevProps.selectedRow.id) !== getObjectData(() => this.props.selectedRow.id)) {
            this.setState({
                activeTab: 1,
            }, () => {
                this.getSymptFormData();
            });
        }
    }

    getSymptFormData = (daypart = 1) => {
        const {
            fetchAdminSubjectData,
        } = this.props;

        if (!this.daynum) {
            return;
        }

        const filter = [
            {name: "sub", value: this.subject},
            {name: "daynum", value: this.daynum},
            {name: "daypart", value: daypart}
        ];

        fetchAdminSubjectData(GET, SUBJECT_SYMP_OBJECT, filter, TYPE)
    };

    onTabToggle = (activeTab) => {
        this.setState({
            activeTab
        }, () => {
            this.getSymptFormData(activeTab)
        })
    };

    get isMorningFormAvailable() {
        const {
            selectedRow
        } = this.props;

        return getObjectData(() => selectedRow.morning_status) === AWAITING;
    }

    get isEveningFormAvailable() {
        const {
            selectedRow
        } = this.props;

        return getObjectData(() => selectedRow.evening_status) === AWAITING;
    }

    get daynum() {
        const {
            selectedRow
        } = this.props;

        return getObjectData(() => selectedRow.daynum)
    }

    get subject() {
        const {
            selectedRow
        } = this.props;

        return getObjectData(() => selectedRow.sub)
    }

    openFirstTab = () => this.onTabToggle(1);
    openSecondTab = () => this.onTabToggle(2);

    render() {
        const {
            isSympVisible,
            theme,
            isAntipyreticSympVisible,
            isWorseningSympVisible,
            data,
            total,
            antipyreticData,
            worseningData
        } = this.props;

        const {
            activeTab,
        } = this.state;

        return (
            isSympVisible
            && <Card className={"subAdminTable__form"}>
                <CardBody>
                    <div className="tabs tabs--bordered-top">
                        <div className="tabs__wrap">
                            {(!isAntipyreticSympVisible && !isWorseningSympVisible) && (
                                <Fragment>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === 1})}
                                                onClick={this.openFirstTab}
                                            >
                                                <FontAwesomeIcon
                                                    className={"subAdmin__tabIcon"}
                                                    color={
                                                        activeTab === 1
                                                            ? returnBootstrapColor(theme === 'blue' ? "primary" : "warning", theme)
                                                            : returnBootstrapColor("secondary", theme)}
                                                    icon={"sun"}/>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === 2})}
                                                onClick={this.openSecondTab}
                                            >
                                                <FontAwesomeIcon
                                                    className={"subAdmin__tabIcon"}
                                                    color={activeTab === 2
                                                        ? returnBootstrapColor(theme === 'blue' ? "primary" : "warning", theme)
                                                        : returnBootstrapColor("secondary", theme)}
                                                    icon={"moon"}/>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>
                                            {total > 0
                                                ? <SubjectSymp
                                                    key={total}
                                                    daynum={`${this.daynum}`}
                                                    readOnly
                                                    initialValues={data}
                                                    daypart={'1'}
                                                    isEmptyValue
                                                />
                                                :
                                                <div>{this.isMorningFormAvailable ? 'Форма еще не доступна для заполнения' : 'Нет данных'}</div>
                                            }
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            {total > 0
                                                ? <SubjectSymp
                                                    daynum={`${this.daynum}`}
                                                    initialValues={data}
                                                    readOnly
                                                    daypart={'2'}
                                                    isEmptyValue
                                                />
                                                :
                                                <div>{this.isEveningFormAvailable ? 'Форма еще не доступна для заполнения' : 'Нет данных'}</div>
                                            }
                                        </TabPane>
                                    </TabContent>
                                </Fragment>)}
                            {
                                isAntipyreticSympVisible
                                && <Panel title={"Прием жаропонижающего препарата"} header padding>
                                    <SubAntipyreticTable
                                        data={antipyreticData}
                                    />
                                </Panel>
                            }
                            {
                                isWorseningSympVisible
                                && <Panel title={"Ухудшение состояния"} header
                                             padding>
                                    <WorseningTable
                                        data={worseningData}
                                    />
                                </Panel>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (store) => ({
    theme: store.settings.theme,
    data: getObjectData(() => store.adminSubject.sympData.data[0]),
    total: getObjectData(() => store.adminSubject.sympData.total),
    selectedRow: store.subTableSelectedRow.eventSelectedRow,
    antipyreticData: getObjectData(() => store.antipyreticData.data.data) || [],
    worseningData: getObjectData(() => store.worseningData.data.data) || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAdminSubjectData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SympTabs);
