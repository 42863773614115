import * as React from 'react';
import classnames from "classnames";

/**
 * SubAntipyreticTable
 * @param data {Object}
 * @constructor
 */
const SubAntipyreticTable = ({data}) => (
    <div className={"sympTable"}>
        <div className={"sympTable__row sympTable__row_header"}>
            <div className={"sympTable__column sympTable__column_first"}/>
            <div className={"sympTable__column sympTable__column_date sympTable__column_header"}>Дата</div>
            <div className={"sympTable__column sympTable__column_time sympTable__column_header"}>Время</div>
            <div className={"sympTable__column sympTable__column_temp sympTable__column_header"}>°Т</div>
            <div className={"sympTable__column sympTable__column_header"}>Торговое наименование</div>
            <div className={"sympTable__column sympTable__column_count sympTable__column_header"}>Принятое количество</div>
        </div>
        {data && data.map((item, index) =>
            <div key={item.id} className={classnames("sympTable__row", (index % 2 === 0) ? "sympTable__row_even" : "")}>
                <div className={"sympTable__column sympTable__column_first"}>{index + 1}</div>
                <div className={"sympTable__column sympTable__column_date"}><span>Дата</span>{item.cmdate}</div>
                <div className={"sympTable__column sympTable__column_time"}><span>Время</span>{item.cmtime}</div>
                <div className={"sympTable__column sympTable__column_temp"}><span>Температура</span>{item.cmtemp}</div>
                <div className={"sympTable__column"}><span>Торговое наименование</span>{item.cmname}</div>
                <div className={"sympTable__column sympTable__column_count"}><span>Принятое количество</span>{item.cmdose}</div>
            </div>
        )}
    </div>
);

export default SubAntipyreticTable;
