import * as React from 'react';
import {PureComponent} from 'react';
import {Container} from 'reactstrap';
import {getObjectData} from "../../utils/getObjectData";

//components
import Chat from '../_commonComponents/chat/index';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchChatData} from "../../redux/actions/chat/chatAction";
import {ADD, INTERVAL} from "../../config/constants";

/**
 * ChatSubject. Чат поддержки пациентов.Часть пациента.
 */
class ChatSubject extends PureComponent {
    state = {
        data: null
    };

    componentDidMount() {
        this.setHeightStyle();
        this.initialData();
        this.updateChat = setInterval(() => {
            this.initialData()
        }, INTERVAL);
        window.addEventListener('resize', this.setHeightStyle)
    }

    componentWillUnmount() {
        clearInterval(this.updateChat);
        window.removeEventListener('resize', this.setHeightStyle)
    }

    setHeightStyle = () => {
        const height = window.innerHeight * 0.01;
        this.el.style.setProperty('--vh', `${height}px`);
    };

    /**
     * initialData
     * @return {Promise<void>}
     */
    initialData = async () => {
        const {
            fetchChatData
        } = this.props;

        await fetchChatData();

        this.setState({
            data: this.props.data
        })
    };

    /**
     * onSubmit
     * @param values {Object}
     * @return {Promise<void>}
     */
    onSubmit = async (values) => {
        const {
            fetchChatData
        } = this.props;

        await fetchChatData(ADD, values);
        await this.initialData();

        this.setState({
            data: this.props.data
        })
    };

    render() {
        const {
            history,
        } = this.props;

        const {
            data
        } = this.state;

        return (
            <Container>
                <div ref={el => {this.el = el}} className={"adminChat"}>
                    {data && <Chat
                        data={data}
                        key={data.length}
                        history={history}
                        onSubmit={this.onSubmit}
                    />}
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.chat.data),
    formData: store.chat.formData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchChatData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatSubject);
