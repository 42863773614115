import * as React from 'react';
import Modal from "../../_commonComponents/ModalForm";
import {Field} from "redux-form";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import {required, number} from "../../../utils/formValidation";
import renderTextarea from "../../_commonComponents/form/TextAreaInputWitValidation";
import propTypes from 'prop-types';

/**
 * AddNewCenterForm
 * @param isOpen {boolean}
 * @param toggle {string}
 * @param onSubmit {function}
 * @param form {string}
 * @constructor
 */
const AddNewCenterForm = ({isOpen, toggle, onSubmit, form}) => (
    <Modal
        toggle={toggle}
        isOpen={isOpen}
        title={'Новый центр'}
        form={form}
        header
        color={"primary"}
        onSubmit={onSubmit}
    >
        <Field
            component={renderInput}
            name={'name'}
            type="text"
            label={'Номер'}
            validate={[required, number]}
        />
        <Field
            component={renderTextarea}
            name={'title'}
            validate={[required]}
            type="text"
            label={'Название центра'}
        />
        <Field
            component={renderInput}
            name={'tag'}
            type="text"
            label={'GMT'}
            validate={[number]}
        />
    </Modal>
);

AddNewCenterForm.propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggle: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    form: propTypes.string.isRequired
};

export default AddNewCenterForm;
