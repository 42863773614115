import * as React from 'react';
import propTypes from 'prop-types';

/**
 * Notification компонент уведомлений о результате ассинхронных запросов
 * @param color {String}
 * @param title {String}
 * @param message {String}
 * @return {*}
 * @constructor
 */
const Notification = ({color, title, message}) => (
    <div className={`notification notification--${color}`}>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message">{message}</p>
    </div>
);

Notification.propTypes = {
    color: propTypes.string,
    title: propTypes.string,
    message: propTypes.string.isRequired
};

Notification.defaultProps = {
    color: '',
    title: '',
};

export default Notification;
