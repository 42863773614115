import "cross-fetch/polyfill";
import {UrlCreator, formDataCreator} from "../../utils/requestCreator";
import {GET, ADMIN_SUB_SYNC} from "../../config/constants";

export const DATA_SYNC_L = 'DATA_SYNC_L';
export const DATA_SYNC_F = 'DATA_SYNC_F';
export const DATA_SYNC_S = 'DATA_SYNC_S';

function dataIsLoading() {
    return {
        type: DATA_SYNC_L
    }
}

function dataIsFailed() {
    return {
        type: DATA_SYNC_F
    }
}

function dataIsSuccess(data) {
    return {
        type: DATA_SYNC_S,
        payload: data
    }
}

export  function fetchSyncData() {
    const URL = UrlCreator();
    const initialKey = localStorage.token;
    const data = formDataCreator(null, ADMIN_SUB_SYNC, {}, [], initialKey);
    return (dispatch) => {
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: data
        }).then((response) => {
            if(response.status !== 200) {
                dispatch(dataIsFailed());
                throw Error(response.statusText)
            }

            return response.json();
        }).then((data) => {
            if(data.status !== '0') {
                dispatch(dataIsFailed());
                throw Error(data.msg)
            }
            dispatch(dataIsLoading());
            dispatch(dataIsSuccess(data));
        })
    }
}
