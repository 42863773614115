import React from 'react';
import {Select, MenuItem, Radio, ListItemText, FormControl} from '@material-ui/core';

const SelectWithRadio = ({name, options, onChange, value, placeholder, style}) => {
    const [values, setValues] = React.useState(value ? value : '');

    const handleChange = (event) => {
        setValues(event.target.value);
        onChange(event.target.value);
    };

    return (
        <FormControl style={style} className="form__form-group-select">
            <Select
                value={values}
                onChange={handleChange}
                displayEmpty
                name={name}
                renderValue={selected => {
                    if (selected.length === 0) {
                        return <span className={"form__form-group-select_placeholder"}>{placeholder}</span>
                    }
                    return <span>{selected.label}</span>
                }}
            >
                {options && options.map(item =>
                    <MenuItem key={item.id} value={item}>
                        <Radio checked={item.id === values.id}/>
                        <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
};

const renderSelectWithRadio = (props) =>
    <SelectWithRadio
        {...props.input}
        {...props}
    />;

export default renderSelectWithRadio;
