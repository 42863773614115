import * as React from 'react';
import {Fragment} from 'react';
import ModalForm from "../../../_commonComponents/ModalForm";
import {Field} from "redux-form";
import renderInput from "../../../_commonComponents/form/InputWithValidation";
import renderPickerField from '../../../_commonComponents/form/ColorPicker';

const AddForm= ({isPopupOpen, togglePopup, onSubmit, form}) => (
    <ModalForm
        isOpen={isPopupOpen}
        toggle={togglePopup}
        onSubmit={onSubmit}
        form={form}
        title={"Новый раздел"}
        header
        color={"primary"}
    >
        <Fragment>
            <Field
                name="name"
                component={renderInput}
                label="Код"
            />
            <Field
                name="title"
                component={renderInput}
                label="Название"
            />
            <Field
                name="dsc"
                component={renderInput}
                label="Описание"
            />
            <Field
                name="color"
                component={renderPickerField}
            />
        </Fragment>
    </ModalForm>
);

export default AddForm;
