export const returnBootstrapColor = (color, theme = 'blue') => {
  const isBlue = theme === 'blue';

  let bootstrapColor = '';
  if (color === 'success') {
    bootstrapColor = isBlue ? '#64d3a6' : '#3abf94'
  } else if (color === 'primary') {
    bootstrapColor = isBlue ? '#3ea3fc' : '#845cd3'
  } else if (color === 'warning') {
    bootstrapColor = isBlue ? '#f6da6e' : '#ffc247'
  } else if (color === 'danger') {
    bootstrapColor = isBlue ? '#ff1535' : '#f55d5d'
  } else if (color === 'secondary') {
    bootstrapColor = isBlue ? '#999999' : '#B7B9C7'
  } else {
    bootstrapColor = isBlue ? '#3ea3fc' : '#845cd3'
  }
  return bootstrapColor;
};
