import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ModalBody, ModalFooter, Modal} from "reactstrap";
import classnames from "classnames";
import {connect} from 'react-redux';
import propTypes from 'prop-types';

class ModalComponent extends PureComponent {
    static propTypes = {
        title: propTypes.string.isRequired,
        colored: propTypes.bool.isRequired,
        submitText: propTypes.string,
        closeText: propTypes.string,
        closePopup: propTypes.func,
        color: propTypes.string,
        header: propTypes.bool.isRequired,
        isOpen: propTypes.bool.isRequired,
        onSubmit: propTypes.func.isRequired,
        toggle: propTypes.func,
        message: propTypes.string.isRequired
    };

    static defaultProps = {
        title: '',
        colored: false,
        submitText: 'Удалить',
        closeText: 'Отменить',
        closePopup: null,
        color: 'primary',
        message: ''
    };

    get modalClass() {
        const {
            colored,
            header
        } = this.props;

        return classnames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });
    }

    render() {
        const {
            onSubmit,
            toggle,
            message,
            color,
            title,
            isOpen,
            header,
            submitText,
            closeText,
            closePopup,
            theme
        } = this.props;

        return (
            <Modal
                color={color}
                isOpen={isOpen}
                toggle={closePopup ? closePopup : toggle}
                className={classnames(`theme-${theme}`, header ? 'modal-dialog--maxWidth' : '')}>
                <div className={classnames(this.modalClass, 'modal__content')}>
                    <div className={classnames(`modal-dialog--${color}`)}>
                        <div className="modal__header">
                            <button className="modal__close-btn" onClick={closePopup ? closePopup : toggle}>
                                <FontAwesomeIcon icon="times"/>
                            </button>
                            <div className="bold-text  modal__title">{title}</div>
                        </div>
                        <ModalBody>
                            {message}
                        </ModalBody>
                        <ModalFooter>
                            <Button type={"button"} color="secondary" onClick={toggle}>{closeText}</Button>{' '}
                            <Button type={"submit"} color={color} onClick={onSubmit}>{submitText}</Button>
                        </ModalFooter>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (store) => ({
    theme: store.settings.theme
});

export default connect(mapStateToProps)(ModalComponent);
