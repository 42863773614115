import * as React from 'react';
import classnames from 'classnames';

const TextAreaWithValidation = ({
                                          height, input, style, placeholder, readOnly, type, meta: { touched, error },label
                                      }) => (
    <div style={style} className={"form__form-group"}>
        {label && <label className="form__form-group-label">{label}</label>}
        <div className="form__form-group-field">
            <div className={classnames("form__form-group-input-wrap")}>
                <textarea style={{'height': height ? height : 'auto'}} {...input} placeholder={placeholder}
                          readOnly={readOnly} rows="10" cols="40"/>
                {touched && error && <span className="form__form-group-error">{error}</span>}
            </div>
        </div>
    </div>
);

TextAreaWithValidation.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    readOnly: false,
};

export default TextAreaWithValidation;
