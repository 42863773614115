import * as React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

//components
import Panel from '../../../_commonComponents/Panel';
import RadioField from "./RadioField";

/**
 * Симптомы ОРВИ форма
 */
const SubjectForm001 = ({isValueEmpty, readOnly, labelObject}) => {
    const symptArray = [
        {
            title: 'Общие симптомы',
            fields: ['qs1', 'qs2', 'qs3', 'qs4', 'qs5', 'qs6']
        },
        {
            title: 'Симптомы со стороны носа',
            fields: ['qs7', 'qs8', 'qs9']
        },
        {
            title: 'Симптомы со стороны горла',
            fields: ['qs10', 'qs11']
        },
        {
            title: 'Симптомы со стороны грудной клетки',
            fields: ['qs12', 'qs13']
        }
    ];

    return (
        symptArray.map(item => (
            <Panel
                key={item.title}
                className={classnames("subjectSymp__block", readOnly ? "subjectSymp__block_padding" : "")}
                title={item.title}>
                {item.fields.map(item => <RadioField
                    key={item}
                    readOnly={readOnly}
                    label={labelObject[item]}
                    name={item}
                    isValueEmpty={isValueEmpty}/>)}
            </Panel>
        )
        )
    )
};

SubjectForm001.propTypes = {
    isValueEmpty: propTypes.func.isRequired,
    readOnly: propTypes.bool.isRequired,
    labelObject: propTypes.object.isRequired
};

export default SubjectForm001;
