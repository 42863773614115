import * as React from 'react';
import {PureComponent} from 'react';
import {Container, Button, ButtonToolbar} from 'reactstrap';
import renderInput from '../../_commonComponents/form/InputWithValidation';
import renderTextArea from '../../_commonComponents/form/TextAreaInputWitValidation';
import Modal from '../../_commonComponents/Modal';
import {DATE_MASK, TIME_MASK} from "../../../config/constants";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import IconButton from "../../_commonComponents/buttons/IconButton";

//redux
import {reduxForm, Field} from 'redux-form';


const MOBILE = 992;

/**
 * SubWorseningForm
 */
class SubWorseningForm extends PureComponent {
    state = {
        isMobile: false,
        isPopupOpen: false
    };

    componentDidMount() {
        this.isMobile();
        window.addEventListener('resize', this.isMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isMobile);
    }

    isMobile = () => {
        const isMobile = window.innerWidth < MOBILE;

        this.setState({
            isMobile
        })
    };

    togglePopup = () => {
        const {
            pristine,
            redirect
        } = this.props;

        if (pristine) {
            redirect()
        } else {
            this.setState(prebState => ({
                isPopupOpen: !prebState.isPopupOpen
            }))
        }
    };

    /**
     * dataValidation
     * @param value {Object}
     * @return string
     */
    dataValidation = (value) => {
        const {
            start,
            end,
            startText,
            endText
        } = this.props;

        if (!/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g.test(value)) {
            return 'Введите корректную дату';
        }

        const arr = value && value.split('.');
        value = arr && `${arr[2]}-${arr[1]}-${arr[0]}`;

        return (+new Date(value) < +new Date(start) || +new Date(value) > +new Date(end)) ? `Дата не может быть меньше ${startText} и больше ${endText}` : undefined
    };

    timeValidation = (values) => {
        if (values && !/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(values)) {
            return 'Укажите время в 24-ом формате';
        } else if (values && values.length < 5) {
            return 'Укажите время в 24-ом формате';
        }
    };

    render() {
        const {
            redirect,
            onSubmit,
            handleSubmit,
            pristine,
            submitting
        } = this.props;

        const {
            isMobile,
            isPopupOpen
        } = this.state;

        return (
            <Container>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className={"d-flex flex-column flex-lg-row"}>
                        <div className="d-flex flex-shrink-0 justify-content-between">
                            <Field
                                name={"aedate"}
                                component={renderInput}
                                mask={DATE_MASK}
                                validate={[this.dataValidation]}
                                label={"Дата"}
                                placeholder={"дд.мм.гггг"}
                                style={{maxWidth: isMobile ? '50%' : 97}}
                                className={"mr-4"}
                                type="tel"
                            />
                            <Field
                                name={"aetime"}
                                component={renderInput}
                                mask={TIME_MASK}
                                label={"Время"}
                                validate={[this.timeValidation]}
                                right
                                placeholder={"__ __:__ __"}
                                style={{maxWidth: isMobile ? '50%' : 130}}
                                icon={<ClockOutlineIcon/>}
                                className={"mr-lg-4"}
                                type="tel"
                            />
                        </div>
                        <Field
                            name={"aedesc"}
                            component={isMobile ? renderTextArea : renderInput}
                            label={"Описание"}
                        />
                    </div>
                    <ButtonToolbar className="d-flex justify-content-end pb-4">
                        <IconButton icon="home" color={"secondary"} onClick={this.togglePopup} className={"mr-3"}/>
                        <Button type={"submit"} color={'primary'} className={"mb-0"} disabled={pristine || submitting}>Подтвердить</Button>
                    </ButtonToolbar>
                </form>
                <Modal
                    color={"danger"}
                    isOpen={isPopupOpen}
                    toggle={this.togglePopup}
                    header
                    onSubmit={redirect}
                    submitText={"Подтвердить"}
                    title={"Вы уверены, что хотите закрыть форму?"}
                    message={"Все несохраненные данные будут потеряны"}
                />
            </Container>
        )
    }
}

export default reduxForm(
    {
        enableReinitialize: true,
        form: 'subject_worsening_form',
        touchOnChange: true,
    })(SubWorseningForm);
