import {PASS_IS_FAILED, PASS_IS_SUCCESS, PASS_IS_LOADING} from "../../actions/profile/resetPassAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    msg: ''
};

export function resetPassReducer(state = initialState, action) {
    switch (action.type) {
        case PASS_IS_LOADING:
            return {...state, isLoading: true, isError: false};
        case PASS_IS_FAILED:
            return {...state, isLoading: false, isError: true, msg: action.payload};
        case PASS_IS_SUCCESS:
            return {...state, isLoading: false, isError: false, data: action.payload};
        default:
            return state
    }
}
