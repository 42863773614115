import React, {PureComponent, Fragment} from 'react';
import AdminMonitorSubConstructor from '../_commonComponents/pageConstructors/subject/adminMonitorSubConstructor/index';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getObjectData} from "../../utils/getObjectData";
import {returnBootstrapColor} from "../../utils/returnBootstrapColor";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSubStatusData} from '../../redux/actions/subject/subjectStatusAction';
import Modal from "../_commonComponents/Modal";

const SECONDARY_COLOR = '#B6B9C9';

class MonitorPage extends PureComponent {
    state = {
        verified: false,
        verifiedRow: null,
        isPopupOpen: false,
        id: null,
        checked: false,
        num: ''
    };

    onTogglePopup = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen
        }))
    };

    onOpenPopup = (e, id, verified, num) => {
        this.setState({
            id,
            verified,
            num
        }, () => {
            this.onTogglePopup();
        })
    };

    onSubmit = async () => {
        const {
            fetchSubStatusData
        } = this.props;

        const data = {
            id: this.state.id,
            verified: !this.state.verified
        };

        await fetchSubStatusData(data);

        this.setState(prevState => ({
            verified: !prevState.verified,
            verifiedRow: this.props.verifiedRow
        }), () => {
            this.onTogglePopup();
        })
    };

    returnIconColor(status) {
        const {
            theme
        } = this.props;

        return status ? returnBootstrapColor('success', theme) : SECONDARY_COLOR;
    }

    renderCell = (info) => {
        const checked = getObjectData(() => info.original.checked);
        const verified = getObjectData(() => info.original.verified);
        const id =  getObjectData(() => info.original.id);
        const num = getObjectData(() => info.original.num);

        return (
            <div className="d-flex justify-content-end">
                <div style={{cursor: 'pointer', fontSize: 24, lineHeight: 1}} className="mr-4">
                    <FontAwesomeIcon
                        color={this.returnIconColor(getObjectData(() => info.original.checked))}
                        icon={"user-md"}
                    />
                </div>
                <div onClick={(e) => checked && this.onOpenPopup(e, id, verified, num)} style={{cursor: checked ? 'pointer':'not-allowed', fontSize: 24, lineHeight: 1}}>
                    <FontAwesomeIcon
                        color={this.returnIconColor(getObjectData(() => info.original.verified))}
                        icon={"check-circle"}
                    />
                </div>
            </div>
        )
    };

    render() {
        const {
            verified,
            verifiedRow,
            isPopupOpen,
            num
        } = this.state;

        return (
            <Fragment>
                <AdminMonitorSubConstructor
                    isResetVisible={false}
                    renderCell={this.renderCell}
                    status={verified}
                    statusRow={verifiedRow}
                    headerClassName={"d-flex d-xl-none"}
                />
                <Modal
                    onSubmit={this.onSubmit}
                    submitText={'Подтверждаю'}
                    title={'Верификация данных дневника пациента'}
                    message={!verified ? `Пожалуйста, подтвердите, что данные дневника пациента ${num} проверены` : `Пожалуйста, подтвердите, что статус верификации пациента ${num} необходимо отменить`}
                    toggle={this.onTogglePopup}
                    isOpen={isPopupOpen}
                    color={"primary"}
                    colored={false}
                    header
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (store) => ({
        verified: getObjectData(() => store.status.data.data.verified),
        verifiedRow: getObjectData(() => store.status.data.data),
        theme: store.settings.theme
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubStatusData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MonitorPage);
