import React, {PureComponent} from 'react';
import propTypes from 'prop-types';

//utils
import {returnBootstrapColor} from "../../../../../utils/returnBootstrapColor";

//constants
import {GET} from "../../../../../config/constants";

//components
import SubjectPageConstructor from "../subjectPageConstructor/index";
import renderSelect from "../../../form/MultiSelect";
import renderSelectWithRadio from "../../../form/SelectWithRadion";

//redux
import {connect} from 'react-redux';
import {getObjectData} from "../../../../../utils/getObjectData";
import {bindActionCreators} from "redux";
import {fetchClsData} from "../../../../../redux/actions/cls/clsAction";

const MOBILE = 1024;
const TABLET = 1200;

const SECONDARY_COLOR = returnBootstrapColor('secondary');

class AdminMonitorSubConstructor extends PureComponent {
    static propTypes = {
        isResetVisible: propTypes.bool,
        status: propTypes.bool,
        history: propTypes.object,
        headerClassName: propTypes.string,
        renderCell: propTypes.func.isRequired,
        statusRow: propTypes.object,
        headerTotal: propTypes.number
    };

    static defaultProps = {
        status: false,
        headerClassName: '',
        isResetVisible: true,
        headerTotal: null
    };

    state = {
        centerOption: null,
        isTablet: false,
        isMobile: false,
        isLoading: true
    };

    componentDidMount() {
        this.isTablet();
        this.fetchCenterData();

        window.addEventListener('resize', this.isTablet);
        window.addEventListener('orientationchange', this.isTablet);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.isTablet);
        window.removeEventListener('orientationchange', this.isTablet);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isMobile !== this.state.isMobile) {
            this.fetchCenterData();
        }
    }

    fetchCenterData = async () => {
        const {
            fetchClsData,
        } = this.props;

        const {
            isMobile,
        } = this.state;

        await fetchClsData(GET, [{name:"path", value:"CENTERS"}], {}, true, null, null, null);
        const centerOption = this.props.center
            && this.props.center.map(item => ({
                value: item.name,
                label: isMobile ? item.name : `${item.name} ${item.title}`
            })
            );

        this.setState({
            centerOption,
            isLoading: false
        })
    };

    isTablet = () => {
        const isMobile = window.innerWidth < MOBILE;
        const isTablet = window.innerWidth < TABLET;

        this.setState({
            isTablet,
            isMobile
        })
    };

    get successColor() {
        const {
            theme
        } = this.props;

        return returnBootstrapColor('success', theme);
    }

    get columns() {
        const {
            renderCell,
        } = this.props;

        const {
            centerOption,
            isTablet,
            isMobile
        } = this.state;

        return (
            [
                {
                    Header: '',
                    accessor: isTablet ? 'center' : 'center_name',
                    id: 'center',
                    placeholder: 'Центр',
                    filterable: false,
                    sortable: false,
                    style:{ whiteSpace: 'unset', minWidth: 74},
                    component: renderSelect,
                    isDefaultFilterRender: true,
                    compare: 'LIKE',
                    options: centerOption
                },
                {
                    Header: '',
                    accessor: 'num',
                    id: 'num',
                    placeholder: isMobile ? '№' : 'Пациент',
                    filterable: false,
                    sortable: false,
                    compare: '=',
                    width: isTablet ? 110 : 120,
                    style: {textAlign:  'center', minWidth: isTablet ? 110 : 120},
                    maxWidth: isTablet ? 110 : 120,
                    minWidth: isTablet ? 110 : 120
                },
                {
                    Header: '',
                    accessor: 'action',
                    id: 'status',
                    filterable: false,
                    sortable: false,
                    Cell: renderCell,
                    placeholder: 'Статус',
                    style: {minWidth: isTablet ? 74 : 100},
                    component: renderSelectWithRadio,
                    options: [
                        {label: 'Все', title: 'Все', value: '', id: '1'},
                        {label: <span style={{color: SECONDARY_COLOR}}>B/М</span>, value: [{name: 'checked', value: false}, {name:'verified', value: false}], id: '2'},
                        {label: <span style={{color: this.successColor}}>B/М</span>,  value: [{name: 'checked', value: true}, {name:'verified', value: true}], id: '3'},
                        {label: <span><span style={{color: this.successColor}}>B</span>/<span style={{color: SECONDARY_COLOR}}>M</span></span>, value: [{name: 'checked', value: true}, {name:'verified', value: false}], id: '4'},
                        {label: <span style={{color: this.successColor}}>B</span>, value: {name: 'checked', value: true}, id: '5'},
                    ],
                    width: isTablet ? 74 : 100,
                    minWidth: 0,
                    maxWidth: isTablet ? 74 : 100,
                }
            ]
        )
    };

    render() {
        const {
            isResetVisible,
            status,
            history,
            headerClassName,
            syncAction,
            syncIcon,
            headerTotal
        } = this.props;

        const {
            isTablet,
            isLoading
        } = this.state;

        return (
            !isLoading && <SubjectPageConstructor
                isResetVisible={isResetVisible}
                columns={this.columns.filter(item => Object.keys(item).length !== 0)}
                status={status}
                history={history}
                isTablet={isTablet}
                headerClassName={headerClassName}
                syncAction={syncAction}
                syncIcon={syncIcon}
                headerTotal={headerTotal}
            />
        )
    }
}

const mapStateToProps = (store) => ({
    center: getObjectData(() => store.cls.data.data),
    theme: store.settings.theme
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchClsData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminMonitorSubConstructor);
