import * as React from 'react';
import classnames from 'classnames';
import propTypes from "prop-types";

//components
import Panel from '../../../_commonComponents/Panel';
import RadioField from "./RadioField";

/**
 * Симптомы гриппа, форма
 */
const SubjectForm002 = ({isValueEmpty, readOnly, labelObject}) => {
    const symptArray = [
        {
            title: 'Общие симптомы',
            fields: ['qs1', 'qs2', 'qs3', 'qs4']
        },
        {
            title: 'Респираторные',
            fields: ['qs5', 'qs6', 'qs7']
        }
    ];

    return (
        symptArray.map(item => (
                <Panel
                    key={item.title}
                    className={classnames("subjectSymp__block", readOnly ? "subjectSymp__block_padding" : "")}
                    title={item.title}>
                    {item.fields.map(item => <RadioField
                        key={item}
                        readOnly={readOnly}
                        label={`${labelObject[item]}: `}
                        name={item}
                        style={{'minWidth': 180}}
                        isValueEmpty={isValueEmpty}/>)}
                </Panel>
            )
        )
    )
};

SubjectForm002.propTypes = {
    isValueEmpty: propTypes.func.isRequired,
    readOnly: propTypes.bool.isRequired,
    labelObject: propTypes.object.isRequired
};


export default SubjectForm002;
