import "cross-fetch/polyfill";

import {getObjectData} from "../../../utils/getObjectData";
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import { LIMIT, USERS_INV_OBJECT} from "../../../config/constants";

export const USERS_ADMIN_INV_L = 'USERS_ADMIN_INV_L'; //loading
export const USERS_ADMIN_INV_F = 'USERS_ADMIN_INV_F'; //error
export const USERS_ADMIN_INV_S = 'USERS_ADMIN_INV_S'; //success
export const USERS_ADMIN_INV_E = 'USERS_ADMIN_INV_E'; //edited

function dataIsLoading() {
    return {
        type: USERS_ADMIN_INV_L
    }
}

function dataIsFailed(message) {
    return {
        type: USERS_ADMIN_INV_F,
        payload: message
    }
}

function dataIsLoadedSuccess(data) {
    return {
        type: USERS_ADMIN_INV_S,
        payload: data
    }
}

function dataIsEdited(data) {
    return {
        type: USERS_ADMIN_INV_E,
        payload: data
    }
}

export function fetchUsersInvAdminData(
    action,
    filter,
    data,
    isInitial,
    order,
    offset,
)
{
    return (dispatch) => {
        let initialKey = localStorage.token;
        const URL = UrlCreator();
        const requestData = formDataCreator(action, USERS_INV_OBJECT, data, filter, initialKey, LIMIT, offset, order);

        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then(response => {
            if(response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json()
        }).then(data => {
            // eslint-disable-next-line
            if(data.status != 0){
                dispatch(dataIsFailed(getObjectData(() => data.msg)));
                return;
            }

            isInitial
                ? dispatch(dataIsLoadedSuccess(data))
                : dispatch(dataIsEdited(getObjectData(() => data.data)))
        }).catch(e => console.error(e))
    }
}
