import React, {PureComponent} from 'react';
import {Card, CardBody} from "reactstrap";
import propTypes from 'prop-types';
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchAdminSubjectData} from "../../../../../../redux/actions/admin/adminSubjectAction";
import {setSelectedEventRow} from "../../../../../../redux/actions/subject/subjectActionRowAction";
import {fetchAntipyreticData} from "../../../../../../redux/actions/subjectSymp/subjectAntipyreticAction";
import {fetchWorseningData} from "../../../../../../redux/actions/subjectSymp/subjectWorseningAction";

//utils
import {getObjectData} from "../../../../../../utils/getObjectData";
import {returnBootstrapColor} from "../../../../../../utils/returnBootstrapColor";

//constants
import {ANTIPYRETIC_OBJECT, EVENTS_STATUS_OBJECT, GET, WORSENING_OBJECT} from "../../../../../../config/constants";
const TYPE = 'events';

class EventsTable extends PureComponent {
    static propTypes = {
        isEventsVisible: propTypes.bool.isRequired,
        openSymptForm: propTypes.func.isRequired,
        openAntipyreticTable: propTypes.func.isRequired,
        openWorseningTable: propTypes.func.isRequired,
        isTablet: propTypes.bool.isRequired,
        isRefreshTable: propTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isAntipyreticButtonSelected: false,
            isWorseningButtonSelected: false
        }
    }

    componentDidMount() {
        this.setInitialData();
        this.setAntipyreticData();
        this.setWorseningData();
    }

    componentDidUpdate(prevProps) {
        if (getObjectData(() => prevProps.selectedRow.id) !== getObjectData(() => this.props.selectedRow.id)) {
            this.setInitialData();
            this.setAntipyreticData();
            this.setWorseningData();
        }
        if (prevProps.isTablet !== this.props.isTablet) {
            this.resetTable();
        }

        if (prevProps.isRefreshTable !== this.props.isRefreshTable) {
            this.resetTable();
        }
    }

    resetTable() {
        this.setState({
            isAntipyreticButtonSelected: false,
            isWorseningButtonSelected: false
        }, () => {
            this.props.setSelectedEventRow(this.defaultSelectedData)
        })
    }

    get defaultSelectedData() {
        return getObjectData(() => this.props.data[0])
    }

    get subjectId() {
        const {
            selectedRow
        } = this.props;

        return getObjectData(() => selectedRow.id)
    }

    setInitialData = async () => {
        const {
            fetchAdminSubjectData,
            setSelectedEventRow,
        } = this.props;

        const {
            isAntipyreticButtonSelected,
            isWorseningButtonSelected
        } = this.state;

        if (!this.subjectId) {
            return;
        }

        await fetchAdminSubjectData(null, EVENTS_STATUS_OBJECT, [{name: "sub", value: this.subjectId}], TYPE);
        setSelectedEventRow(
            (!isWorseningButtonSelected && !isAntipyreticButtonSelected) ? this.defaultSelectedData : null
        );
    };

    setAntipyreticData = () => {
        const {
            fetchAntipyreticData,
        } = this.props;

        if (!this.subjectId) {
            return;
        }

        const filter = [{name: "sub", value: this.subjectId}];

        fetchAntipyreticData(GET, {}, filter, true, ANTIPYRETIC_OBJECT);
    };

    setWorseningData = () => {
        const {
            fetchWorseningData,
        } = this.props;

        if (!this.subjectId) {
            return;
        }

        const filter = [{name: "sub", value: this.subjectId}];
        fetchWorseningData(GET, {}, filter, true, WORSENING_OBJECT)
    };

    onChangeRow = (selectedRow) => {
        const {
            openSymptForm,
            setSelectedEventRow
        } = this.props;

        this.setState({
            isAntipyreticButtonSelected: false,
            isWorseningButtonSelected: false
        }, () => {
            openSymptForm();
            setSelectedEventRow(selectedRow);
        });
    };

    /**
     * setEventIcon
     * @param color {string}
     * @param prefix
     * @param icon {string}
     * @return {*}
     */
    setEventIcon = (color, prefix, icon) => {
        return <FontAwesomeIcon color={color} icon={prefix ? [prefix, icon] : icon}/>
    };

    onAntipyreticButtonClick = () => {
        const {
            openAntipyreticTable,
            setSelectedEventRow
        } = this.props;

        this.setState({
            isAntipyreticButtonSelected: true,
            isWorseningButtonSelected: false
        }, () => {
            openAntipyreticTable();
            setSelectedEventRow(null);
        });
    };

    onWorseningButtonClick = () => {
        const {
            openWorseningTable,
            setSelectedEventRow
        } = this.props;

        this.setState({
            isWorseningButtonSelected: true,
            isAntipyreticButtonSelected: false
        }, () => {
            openWorseningTable();
            setSelectedEventRow(null);
        })
    };

    static get iconPrefix() {
        return {
            'times-circle': 'far',
            'check-circle': 'far',
            'file-alt': 'far',
            'smile': null
        }
    }

    render() {
        const {
            isEventsVisible,
            eventSelectedRow,
            theme,
            data,
            antipyreticData,
            worseningData
        } = this.props;

        const {
            isAntipyreticButtonSelected,
            isWorseningButtonSelected
        } = this.state;

        return (
            isEventsVisible
            && <div className="subAdminTable__wrapper">
                <Card className={"h-100"}>
                    <CardBody>
                        {data && data.length > 0 && <div className={"subject__row"}>
                            <div className="subject__column subject__column_first subject__column_bold">День</div>
                            <div className="subject__column subject__column_bold">
                                Утро
                            </div>
                            <div className="subject__column subject__column_bold">
                                Вечер
                            </div>
                        </div>}
                        {data && data.map((item) =>
                            <div
                                key={item.id}
                                className={classnames(
                                    "subject__row subject__row_hover",
                                    +item.daynum % 2 !== 0 ? "subject__row_odd" : "",
                                    item.id === getObjectData(() => eventSelectedRow.id) ? "subject__row_selected" : "",
                                )}
                                onClick={() => this.onChangeRow(item)}>
                                <div
                                    className={classnames("subject__column subject__column_first", item.marked ? "subject__column_blue" : "")}>{item.day}</div>
                                <div className="subject__column subject__column_button">
                                    {this.setEventIcon(item.morning_status_color, EventsTable.iconPrefix[item.morning_status_ico], item.morning_status_ico)}
                                </div>
                                <div className="subject__column subject__column_button">
                                    {this.setEventIcon(item.evening_status_color, EventsTable.iconPrefix[item.evening_status_ico], item.evening_status_ico)}
                                </div>
                            </div>
                        )
                        }
                        {data && data.length > 0
                        && <div
                            className={classnames("subject__row subject__row_hover subAdmin__button", isAntipyreticButtonSelected ? "subject__row_selected" : "")}
                            onClick={this.onAntipyreticButtonClick}
                        >
                            <FontAwesomeIcon
                                color={antipyreticData && antipyreticData.length > 0
                                    ? returnBootstrapColor('success', theme)
                                    : returnBootstrapColor('secondary', theme)}
                                icon="temperature-high"/>
                            <span>
                                Жаропонижающее
                            </span>
                        </div>
                        }
                        {data && data.length > 0
                        && <div
                            className={classnames("subject__row  subject__row_hover subject__row_odd subAdmin__button", isWorseningButtonSelected ? "subject__row_selected" : "")}
                            onClick={this.onWorseningButtonClick}
                        >
                            <FontAwesomeIcon
                                color={worseningData && worseningData.length > 0 ? returnBootstrapColor('success', theme) : returnBootstrapColor('secondary', theme)}
                                icon={["far", "frown"]}/>
                            <span>
                                Ухудшение состояния
                            </span>
                        </div>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    data: getObjectData(() => store.adminSubject.eventData.data) || [],
    selectedRow: store.subTableSelectedRow.selectedRow,
    eventSelectedRow: store.subTableSelectedRow.eventSelectedRow,
    theme: store.settings.theme,
    antipyreticData: getObjectData(() => store.antipyreticData.data.data) || [],
    worseningData: getObjectData(() => store.worseningData.data.data) || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAdminSubjectData,
    setSelectedEventRow,
    fetchWorseningData,
    fetchAntipyreticData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsTable);
