import {
    SUBJECT_WORSENING_F,
    SUBJECT_WORSENING_S,
    SUBJECT_WORSENING_L,
    SUBJECT_WORSENING_E
} from "../../actions/subjectSymp/subjectWorseningAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    formData: null,
};

export function subjectWorseningReduce(state = initialState, action) {
    switch (action.type) {
        case SUBJECT_WORSENING_L:
            return {...state, isLoading: true, isError: false};
        case SUBJECT_WORSENING_F:
            return {...state, isLoading: false, isError: true};
        case SUBJECT_WORSENING_S:
            return {...state, isLoading: false, isError: false, data: action.payload};
        case SUBJECT_WORSENING_E:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
