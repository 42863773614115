import 'cross-fetch/polyfill';
import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {GET, CHAT_OBJECT, ADD} from '../../../config/constants';
import {getObjectData} from "../../../utils/getObjectData";

export const DATA_IS_SUCCESS = 'DATA_IS_SUCCESS';
export const DATA_IS_ADDED = 'DATA_IS_ADDED';
export const DATA_IS_LOADING = 'DATA_IS_LOADING';
export const DATA_IS_FAILED = 'DATA_IS_FAILED';

function dataIsLoading() {
    return {
        type: DATA_IS_LOADING
    }
}

function dataIsFailed() {
    return {
        type: DATA_IS_FAILED
    }
}

function dataIsSuccess(data, total) {
    return {
        type: DATA_IS_SUCCESS,
        payload: {
            data,
            total
        }
    }
}

function dataIsAdded(data) {
    return {
        type: DATA_IS_ADDED,
        payload: data
    }
}

export function fetchChatData(action = GET, data = {}, filter = [], object = CHAT_OBJECT, limit = null, offset = null, order = null) {
    const URL = UrlCreator();
    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(action, object, data, filter, initialKey, limit, offset, order);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                return;
            }

            action !== ADD
                ? dispatch(dataIsSuccess(getObjectData(() => data.data), getObjectData(() => data.total)))
                : dispatch(dataIsAdded(getObjectData(() => data.data)))
        })
    }
}
