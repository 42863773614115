import * as React from 'react';
import classnames from 'classnames';

/**
 * SubWorseningTable
 * @param data {Array}
 * @constructor
 */
const SubWorseningTable = ({data}) => (
    <div className={"sympTable"}>
        <div className={"sympTable__row sympTable__row_header"}>
            <div className={"sympTable__column sympTable__column_first"}/>
            <div className={"sympTable__column sympTable__column_date sympTable__column_header"}>Дата</div>
            <div className={"sympTable__column sympTable__column_time sympTable__column_header"}>Время</div>
            <div className={"sympTable__column sympTable__column_header"}>Описание</div>
        </div>
        {data && data.map((item, index) =>
            <div key={item.id} className={classnames("sympTable__row", (index%2 === 0) ? "sympTable__row_even" : "")}>
                <div className={"sympTable__column sympTable__column_first"}>{index + 1}</div>
                <div className={"sympTable__column sympTable__column_date"}><span>Дата</span>{item.aedate}</div>
                <div className={"sympTable__column sympTable__column_time"}><span>Время</span>{item.aetime}</div>
                <div className={"sympTable__column"}><span>Описание</span>{item.aedesc}</div>
            </div>
        )}
    </div>
);

export default SubWorseningTable;
