import * as React from 'react';
import Modal from "../../_commonComponents/ModalForm";
import {Field} from "redux-form";
import renderInput from "../../_commonComponents/form/InputWithValidation";
import {PHONE_MASK} from "../../../config/constants";
import {required, email} from "../../../utils/formValidation";

/**
 * EditUserForm
 * @param isOpen {boolean}
 * @param toggle {function}
 * @param onSubmit {function}
 * @param form {string}
 * @param initialValues {Object}
 * @constructor
 */
const EditUserForm = ({isOpen, toggle, onSubmit, form, initialValues}) => {

    return (
    <Modal
        toggle={toggle}
        isOpen={isOpen}
        title={`${initialValues && initialValues.name}`}
        form={form}
        header
        color={"primary"}
        onSubmit={onSubmit}
        initialValues={initialValues}
    >
        <div className="form__from-fields-wrapper">
            <div className="pr-2 flex-grow-1">
                <Field
                    component={renderInput}
                    name={'id'}
                    type="text"
                    label={'Sys Id'}
                    readOnly
                    disabled
                />
            </div>
            <div className={"flex-grow-1"}>
                <Field
                    component={renderInput}
                    name={'vers'}
                    type="text"
                    label={'Vers'}
                    readOnly
                    disabled
                />
            </div>
        </div>
        <Field
            component={renderInput}
            name={'name'}
            type="text"
            label={'Имя'}
        />
        <Field
            component={renderInput}
            name={'title'}
            type="text"
            validate={[required]}
            label={'ФИО'}
        />
        <Field
            component={renderInput}
            name={'phone'}
            type="text"
            validate={[required]}
            label={'Номер телефона'}
            mask={PHONE_MASK}
        />
        <Field
            component={renderInput}
            name={'email'}
            type="text"
            label={'Электронная почта'}
            validate={[email]}
        />
    </Modal>
);
};

export default EditUserForm;
