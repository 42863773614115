import * as React from 'react';
import {PureComponent} from 'react';
import {Container,Card, CardBody} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

//components
import Loading from '../_commonComponents/Loading';

//utils
import {getObjectData} from '../../utils/getObjectData';
import {fetchSubjectData} from '../../redux/actions/subject/subjectAction';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//constants
import {SUBJECT_SYMP_PATH} from '../../config/constants';

const ACTIVE = 'ACTIVE';
const HEAL ='HEAL';
const COMPLETE = 'COMPLETE';

/**
 * ГРАФИК ЗАПОЛЕНЕНИЯ ДНЕВНИКА '/subject/home' домашняя страница пациента
 */
class SubjectComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            daynum: null,
            daypart: null,
            status: null
        };
    }

    static setIconButton(icon, title = '', color = 'gray', prefix = null, callback = () => {}) {
        return (
            <button className={classnames("btn subject__button", `subject__button_${color}`)} onClick={callback}>
                <FontAwesomeIcon icon={prefix ? [prefix, icon] : icon}/>
                <span>{title}</span>
            </button>
        )
    }

    componentDidMount() {
        this.getInitialData();
    }

    setEventIcon = (color, prefix, icon) => {
        return  <FontAwesomeIcon color={color} icon={prefix ? [prefix, icon] : icon}/>
    };

    onClick = (daynum, daypart, status, id) => {
        if (status === COMPLETE || status === ACTIVE || status === HEAL) {
            daypart && daynum && this.props.history.push(`${SUBJECT_SYMP_PATH}/${daynum}/${daypart}/${id}`)
        }
    };

    isActive = (status) => {
        return status === ACTIVE
    };

    getInitialData = async () => {
        const {
            fetchSubjectData
        } = this.props;

        await fetchSubjectData();
        const {
            data
        } = this.props;

        const activeObj = data && data.find(item => this.isActive(item.morning_status) || this.isActive(item.evening_status));
        const activeObjMorningStatus = getObjectData(() => activeObj.morning_status);
        const activeObjectEveningStatus = getObjectData(() => activeObj.morning_status);

        const morningStatus = this.isActive(activeObjMorningStatus) ? activeObjMorningStatus : null;
        const eveningStatus =  this.isActive(activeObjectEveningStatus) ? activeObjectEveningStatus : null;

        const morningStatusValue = morningStatus ? 1 : null;
        const eveningStatusValue = eveningStatus ? 2 : null;

        this.setState({
            daynum: getObjectData(() => activeObj.daynum),
            daypart: morningStatusValue|| eveningStatusValue,
            status: morningStatus || eveningStatus
        })
    };

    redirect = (url) => {
        const {
            history
        } = this.props;

        history.push(url);
    };

    static get iconPrefix() {
        return {
            'times-circle': 'far',
            'check-circle': 'far',
            'file-alt': 'far',
            'smile': null
        }
    }

    render() {
        const {
            data,
            title,
            isLoading
        } = this.props;

        const {
            daynum,
            daypart,
            status
        } = this.state;

        return (
                <Container className="pt-lg-2 subject">
                    {isLoading && <Loading/>}
                    <Card className={"pb-0 subject__block subject__block_width"}>
                        <CardBody className={"subject__container subject__container_gray"}>
                            {SubjectComponent.setIconButton("book", title, daypart && daynum ? "primary" : "disabled", null, () => this.onClick(daynum, daypart, status, null))}
                            {SubjectComponent.setIconButton("temperature-high", "Жаропонижающее", "primary", null, () => this.redirect('/subject/antipyretic'))}
                            {SubjectComponent.setIconButton("frown", "Ухудшение состояния", "primary", "far", () => this.redirect('/subject/worsening'))}
                            {SubjectComponent.setIconButton("hands-helping", "Нужна помощь", 'gray', null, () => this.redirect('/subject/help_chat'))}
                        </CardBody>
                    </Card>
                    <Card className={"pb-0 subject__block"}>
                        <CardBody className={"subject__container"}>
                            <h4 className={"subject__title"}>ГРАФИК ЗАПОЛНЕНИЯ ДНЕВНИКА</h4>
                            <div className="subject__table">
                                <div className="subject__row">
                                    <div className="subject__column subject__column_first subject__column_bold">
                                        День
                                    </div>
                                    <div className="subject__column subject__column_bold">
                                        Утро <br/>
                                        (06:00 - 12:00)
                                    </div>
                                    <div className="subject__column subject__column_bold">
                                        Вечер <br/>
                                        (18:00 - 00:00)
                                    </div>
                                </div>
                                {data && data.map((item) =>
                                    <div key={item.id} className={classnames("subject__row", item.marked ? 'subject__row_marked': '')}>
                                        <div className="subject__column subject__column_first">{item.day}</div>
                                        <div className="subject__column subject__column_button" onClick={() => this.onClick(item.daynum, 1, item.morning_status, item.morning_id)}>
                                            {this.setEventIcon(item.morning_status_color, SubjectComponent.iconPrefix[item.morning_status_ico], item.morning_status_ico)}
                                        </div>
                                        <div className="subject__column subject__column_button" onClick={() => this.onClick(item.daynum, 2, item.evening_status, item.evening_id)}>
                                            {this.setEventIcon(item.evening_status_color, SubjectComponent.iconPrefix[item.evening_status_ico], item.evening_status_ico)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    isLoading: store.subEvents.isLoading,
    data: getObjectData(() => store.subEvents.data.data),
    skey: store.profile.key,
    title: store.settings.formTitle
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubjectData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubjectComponent);
