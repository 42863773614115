import * as React from 'react';
import { PureComponent} from 'react';
import { SketchPicker } from 'react-color';

class ColorPicker extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPickerOpen: false,
            color: this.props.color || "#70bbfd",
            active: false,
        }
    }

    onToggle = () => {
        this.setState(prevState => ({
            isPickerOpen: !prevState.isPickerOpen
        }))
    };

    handleChange = (color) => {
        this.props.onChange(color.hex);
        this.setState({ color: color.hex});
    };

    render() {
        const {isPickerOpen} = this.state;

        return (
            <div className="color-picker">
                <button
                    className={`color-picker__button${this.state.active ? ' active' : ''}`}
                    onClick={this.onToggle}
                    id={'color'}
                    type={"button"}
                    key={this.props.value}
                >
                    <p className="color-picker__color">{this.state.color}</p>
                    <div className="color-picker__color-view" style={{backgroundColor: this.state.color}}/>
                </button>
                {isPickerOpen
                && <div className="color-picker">
                    <div className="color-picker__wrapper">
                        <SketchPicker
                            color={this.state.color}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                }
            </div>
        )
    }
}

const renderColorPickerField = props => (
    <div className="form__form-group-input-wrap">
        <ColorPicker
            {...props.input}
            color={props.color}
        />
        {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
    </div>
);


export default renderColorPickerField;
