import {
    SUBJECT_ANTIPYRETIC_F,
    SUBJECT_ANTIPYRETIC_S,
    SUBJECT_ANTIPYRETIC_L,
    SUBJECT_ANTIPYRETIC_E,
} from "../../actions/subjectSymp/subjectAntipyreticAction";

const initialState = {
    isLoading: false,
    isError: false,
    data: null,
    formData: null,
};

export function subjectAntipyreticReducer(state = initialState, action) {
    switch (action.type) {
        case SUBJECT_ANTIPYRETIC_L:
            return {...state, isLoading: true, isError: false};
        case SUBJECT_ANTIPYRETIC_F:
            return {...state, isLoading: false, isError: true};
        case SUBJECT_ANTIPYRETIC_S:
            return {...state, isLoading: false, isError: false, data: action.payload};
        case SUBJECT_ANTIPYRETIC_E:
            return {...state, isLoading: false, isError: false, formData: action.payload};
        default:
            return state
    }
}
