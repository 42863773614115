import "cross-fetch/polyfill";

import {UrlCreator, formDataCreator} from "../../../utils/requestCreator";
import {RESET_PASS_OBJECT} from "../../../config/constants";
import ShowNotification from "../../../utils/showNotification";

export const PASS_IS_LOADING = "PASS_IS_LOADING";
export const PASS_IS_FAILED = "PASS_IS_FAILED";
export const PASS_IS_SUCCESS= "PASS_IS_SUCCESS";

function dataIsLoading() {
    return {
        type: PASS_IS_LOADING
    }
}

function dataIsFailed() {
    return {
        type: PASS_IS_FAILED,
    }
}

function dataIsSuccess(data) {
    return {
        type: PASS_IS_SUCCESS,
        payload: data
    }
}

export function fetchResetPassData(data={}) {
    const URL = UrlCreator();
    const notification = Object.create(ShowNotification);

    return (dispatch) => {
        let initialKey = localStorage.token;
        const requestData = formDataCreator(null, RESET_PASS_OBJECT, data, [], initialKey, null, null,null);
        dispatch(dataIsLoading());
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if (response.status !== 200) {
                dispatch(dataIsFailed());
                throw Error(response.statusText)
            }

            dispatch(dataIsLoading());
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                dispatch(dataIsFailed());
                notification.show(data.msg);
                return;
            }

            dispatch(dataIsSuccess(data));
            notification.show('Новый пароль отправлен на указанный номер', 'Успешно', 'success');
        }).catch((e) => console.error(e))
    }
}
